import React, { useMemo } from "react";
import { Detail } from "carynhealth-component-library";
import { styled } from "@mui/system";
import { Grid } from "@mui/material";

const ExpenseLimitsStyle = styled("section")(({ theme }) => {
  return {
    ".MuiListItem-root:hover": {
      background: "#fff !important",
      color: "initial !important",
    },
  };
});

const ExpenseLimits: React.FC<{ data: any }> = (props) => {
  const eligibileServiceData = useMemo(() => {
    const { expenseLimits } = props.data;
    let ExpenseInColumn =
      expenseLimits?.map((service: any) => ({
        title: service.idcardField,
        subTitle: service.fieldValue,
      })) || [];
    return [
      ExpenseInColumn.slice(0, 4),
      ExpenseInColumn.slice(4, 9),
      ExpenseInColumn.slice(9, ExpenseInColumn.length),
    ];
  }, [props]);

  return (
    <ExpenseLimitsStyle id="pi-ExpenseLimits">
      <Grid container spacing={2}>
        {eligibileServiceData?.map((expenseDetails: any, index) => (
          <Grid item xs={12} md={3.5}>
            <Detail list={expenseDetails} />
          </Grid>
        ))}
      </Grid>
    </ExpenseLimitsStyle>
  );
};

export default ExpenseLimits;
