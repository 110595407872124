import React, { useEffect } from "react";
import { StorageHelpers, UpdatesCard } from "carynhealth-component-library";
import ContactInfoImg from "../../../assests/images/contact-info.svg";
import config from "../../../config/constants/config";
import { Components } from "./components";
import { getAgentInfo } from "../../ProgramInformation/handlers";
import {
  getDocumentsContactInfo,
  getNetworkName,
  setContactandCardID,
} from "./handler";
import { useTheme, useMediaQuery, styled } from "@mui/material";
import { useLocation } from "react-router-dom";
import routePaths from "../../../config/constants/routePaths";
import { getIdCardDetails } from "../../../config/routes/handlers";

const brand = config.APP_NAME;
const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

const StyleWrapper = styled("div")(() => {
  return {
    ".contact_info": {
      height: "63vh",
      overflow: "scroll",
      ".contactinfo_label": {
        fontSize: "4.3vw",
      },
      ".contactinfo_Value": {
        fontSize: "4.6vw",
        whiteSpace:'break-spaces'
      },
    },
  };
});

const ContactInformationWrapper = () => {
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const [contactInfo, setContactInfo] = React.useState<any>({
    agentInfo: {},
    contactInfo: null,
    planId: null,
    nwName: null,
    cardIdDetails: null,
  });

  const fetchIntial = async () => {
    try {
      const contactInfo = await getDocumentsContactInfo();
      setContactInfo((prevState: any) => ({ ...prevState, contactInfo }));

      if (!contactInfo?.contactCardData?.enable) return;
      // Get ID card Details
      const { apiResponse: cardApiRes } = await getIdCardDetails(
        StorageHelpers.getLocalStorage("userMail") || ""
      );
      setContactInfo((prevState: any) => ({
        ...prevState,
        planId: cardApiRes.memberIdCardList[0].planId,
      }));

      const cardIdDetails = setContactandCardID(cardApiRes);
      setContactInfo((prevState: any) => ({ ...prevState, cardIdDetails }));
      // Get N/w name

      const nwName: any = await getNetworkName(
        cardApiRes.memberIdCardList[0].planId
      );
      StorageHelpers.setLocalStorage("NETWORK_NAME", nwName?.provider);
      setContactInfo((prevState: any) => ({ ...prevState, nwName }));

      // Get Agent Info
      const agentInfo = await getAgentInfo(
        StorageHelpers.getLocalStorage("sourceid") || ""
      );
      setContactInfo((prevState: any) => ({ ...prevState, agentInfo }));
    } catch (error) {}
  };

  useEffect(() => {
    fetchIntial();
  }, []);
  const ContactInfo = Components[brand as keyof typeof Components] as React.FC;

  return (
    <>
      {showText && location.pathname === routes.contact ? (
        <StyleWrapper>
          {contactInfo.contactInfo?.contactCardData?.enable &&
            ContactInfo({ contactInfo })}
        </StyleWrapper>
      ) : (
        <UpdatesCard
          image={ContactInfoImg}
          title="Contact Information"
          Component={() =>
            contactInfo.contactInfo?.contactCardData?.enable &&
            ContactInfo({ contactInfo })
          }
        />
      )}
    </>
  );
};

export default ContactInformationWrapper;
