import React, { useEffect, useState } from "react";
import StyleWrapper from "./Style";
import config from "../../config/constants/config";
import welcomeImage from "../../assests/images/uhf/welcome-back.svg";
import AskCarynLogo from "../../assests/images/uhf/Ask Caryn Logo.png";
import Close from "@mui/icons-material/Close";
import messages from "../../config/constants/messages";
import AIinputView from "./View/AIinputView";
import MobileViewAI from "./View/MobileViewAI";
const isNetwell = config.APP_NAME === config.BRAND_NETWELL;

const aiAssistant = () => {
  return (
    <StyleWrapper>
      <div className="webDisplay">
        <div className="web_Chat_container">
          <div
            style={{ maxHeight: "100%", width: 760 }}
            id="chatbody"
            className="chatbody"
          >
            <div>
              <div className="web_top_usernameChat">
                {localStorage.getItem("subscriberName")}!
              </div>
            </div>
            <div style={{ marginBottom: 20 }}>
              <img src={AskCarynLogo} className="betaLogo" />
            </div>

            <div>
              <AIinputView />
            </div>
          </div>
        </div>
      </div>
      <div className="mobileDisplay whiteBackground">
        <MobileViewAI />
      </div>
    </StyleWrapper>
  );
};

export default aiAssistant;
