import React, { useMemo } from "react";
import { Detail } from "carynhealth-component-library";
import { styled } from "@mui/system";

const EligibleStyle = styled("section")(({ theme }) => {
  return {
    ".MuiListItem-root:hover": {
      background: "#fff !important",
      color: "initial !important",
    },
  };
});

const EligibleServices: React.FC<{ data: any }> = (props) => {
  const eligibileServiceData = useMemo(() => {
    const { eligibleServices } = props.data;
    return eligibleServices
      ?.filter((service: any) => service.fieldValue != "NA")
      .map((service: any) => ({
        title: service.idcardField,
        subTitle: service.fieldValue,
      }));
  }, [props]);

  return (
    <EligibleStyle id="pi-eligible">
      <Detail list={eligibileServiceData} />
    </EligibleStyle>
  );
};

export default EligibleServices;
