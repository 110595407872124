import { apiService, StorageHelpers } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import messages from "../../../config/constants/messages";

export const getDocumentsContactInfo = async () => {
  let id;
  if (process.env.REACT_APP_ENV_SETUP == "dev") {
    id = "5446";
  } else {
    id = "4357";
  }
  try {
    const request = {
      URL:
        config.URLS.baseV7API + config.URLS.memberPortal + id ||
        StorageHelpers.getLocalStorage("CLIENT_ID") ||
        "",
      brand: config.BRAND_IP_AEC,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const contactCardData = apiResponse.response.find(
      (obj: any) => obj.cardtitle === "ContactInformation"
    );
    return {
      contactCardData: contactCardData,
    };
  } catch (error) {
    console.error("getDocumentsContactInfo Error:: ", error);
  }
};

export const getNetworkName = async (plainid: string) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.memberNetwork + plainid,
      brand: config.BRAND_UHF,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    
    StorageHelpers.setLocalStorage(
      "NETWORK_NAME",
      apiResponse?.provider_network
    );
    return {
      network: ["HLC", "THS"].includes(apiResponse.provider_network)
        ? "PHCS"
        : apiResponse.provider_network,
      provider: apiResponse.provider_network,
    };
  } catch (error) {}
};

export const setContactandCardID = (data: any) => {
  const requiredData = { contactNumber: null, cardId: null };
  data.memberIdCardList[0].planInfo.map((data: any, index: number) => {
    if (data.idcardField == "contact number") {
      requiredData.contactNumber = data.fieldValue;
      return;
    }
    if (data.idcardField == "card id") {
      requiredData.cardId = data.fieldValue;
      return;
    }
  });
  return requiredData;
};

export const getSendNeedsToContactAddress = () => {
  const client_id_locale = localStorage.getItem("CLIENT_ID");
  const network = localStorage.getItem("NETWORK_NAME");
  switch (network) {
    case "PHCS":
      return client_id_locale === "5541" || client_id_locale === "4377"
        ? "PO Box 21082 Eagan, MN 55121"
        : "P.O. Box 211223, Eagan, MN 55121";
    case "Smartshare":
      return "P.O. Box 211223, Eagan, MN 55121";
    case "smartshare25":
      return "4555 Mansell Road, Suite 300 Alpharetta, GA 30022";
    case "smartshare50":
      return "4555 Mansell Road, Suite 300 Alpharetta, GA 30022";
    case "healthyLife":
      return client_id_locale === "5541" || client_id_locale === "4377"
        ? "PO Box 21082 Eagan, MN 55121"
        : "P.O. Box 211223, Eagan, MN 55121";

    case "AFMC":
      return "Arizona Foundation, PO Box 2909, Phoenix, AZ 85062-2909";
    case "HLC":
      return "4555 Mansell Road, Suite 300 Alpharetta, GA 30022";
    // return (client_id_locale === "5558" || client_id_locale==="4386") ?
    // 'P.O. Box 211223, Eagan, MN 55121':
    //        '4555 Mansell Road, Suite 300 Alpharetta, GA 30022'
    default:
      break;
  }
};

export const getContactDataIP = async (planId:any,version:string) =>{
  let URL: string =
  config.URLS.baseAPI + config.URLS.getContactInfoData + planId + '/' + version
try {
  const request = {
    URL: URL,
    brand: config.APP_NAME,
  };
  const { data: apiResponse } = await apiService.default.get(request);
  return {
    apiResponse,
    errorMessage: messages.NO_NOTIFICATIONS,
  };
} catch (error: any) {
  console.log("error", error);

  return Promise.reject({
    errorMessage: messages.NO_NOTIFICATIONS,
  });
}
}