import { styled } from "@mui/system";

export const ComponentWrapper = styled("div")(({ theme }) => {
  return {
    ".username": {
      color: "#5f2161 !important",
      fontFamily: "Roboto !important",
      fontSize: "1em",
      fontWeight: "500 !important",
      fontStretch: "normal",
      fontStyle: "normal",
      letterSpacing: "normal",
      marginTop: "10px",
    },
    ".QuesProgressBar": {
      width: "100%",
      height: "0.6rem",
      marginTop: 24,
      marginBottom: 34,
      maxWidth: "100%",
      flexGrow: 1,
      position: "unset",
      padding: 0,
      ".MuiLinearProgress-root": {
        height: "0.6rem",
        backgroundColor: "#f2f2f2",
        width: "100%",
        ".css-5xe99f-MuiLinearProgress-bar1": {
          // backgroundColor:'#27ae60', // sucess
          //  backgroundColor: '#420045'
        },
      },
    },
    ".custom-subtitle": {
      fontFamily: '"Roboto"',
      fontSize: ".875rem",
      lineHeight: "16px",
      fontWeight: 500,
      marginBottom: "18px",
    },
    ".datePicker": {
      width: "100%",
      ".MuiInputBase-root": {
        border: "none",
        borderRadius: "0 !important",
        marginRight: 0,
        backgroundColor: "rgba(0, 0, 0, 0.06)",
      },
      ".MuiInputAdornment-root": {
        ".MuiButtonBase-root": {
          backgroundColor: "transparent ",
          marginRight: 0,
        },
      },
    },
    ".SelectGender": {
      ".MuiFormControl-root": {
        width: "100%",
        ".MuiInputBase-root": {
          borderRadius: "0 !important",
          backgroundColor: "rgba(0, 0, 0, 0.06)",
        },
      },
    },
    ".EmailInput": {
      marginTop: "0rem",
      ".MuiFormControl-root": {
        width: "100%",
        ".MuiInputBase-root": {
          borderRadius: "0 !important",
          backgroundColor: "rgba(0, 0, 0, 0.06)",
        },
      },
    },
    ".description": {
      fontFamily: "Roboto",
      fontWeight: "400!important",
      color: "rgba(0,0,0,.87)",
      fontSize: ".9375rem",
      textAlign: "justify",
      lineHeight: 1.5,
    },
    ".form-label": {
      fontFamily: "Roboto",
      fontWeight: "400 !important",
      fontSize: "0.875rem",
      color: "rgba(0, 0, 0, 0.87)",
    },
    ".Mui-checked,.MuiButtonBase-root": {
      color: "#533278 !important",
      backgroundColor: "transparent !important",
    },
    ".Mui-focused": {
      color: "#000000 !important",
    },
    ".Mui-disabled": {
      color: "#808080",
    },
    ".MuiFormLabel-root": {
      fontSize: 14,
    },
    ".MuiInputBase-input": {
      width: "100% !important",
      color: "#000000",
      "-webkit-text-fill-color": "#000000",
    },
    ".multilineInput": {
      ".MuiFilledInput-root": {
        height: "auto",
      },
      marginBottom: 20,
    },
    ".PhoneNumberInput": {
      marginBottom: 20,
    },
    ".healthCheckbox": {
      backgroundColor: "#f8f8f8",
      float: "left",
      maxHeight: "132px",
      overflowX: "hidden",
      overflowY: "auto",
      padding: "0px 9px",
    },
    ".PrivacyPolicyDiv": {
      cursor: "pointer",
      fontWeight: 400,
      fontFamily: "Roboto",
      ".PrivacyPolicy": {
        color: "rgb(83, 50, 120)",
        cursor: "pointer",
        fontWeight: 600,
        borderBottom: "1px solid rgb(83, 50, 120)",
      },
    },
    label: {
      color: "#333",
      zIndex: 0,
    },
    ".PrivacyDesc": {
      fontSize: "1em",
    },
    ".alcoholDiv": {
      marginBottom: 10,
    },
  };
});
