import config from "../constants/config";

const brand = config.APP_NAME;

const routePaths = Object.freeze({
  UHF: {
    authenticatedDefault: "/dashboard",
    default: "/",
    login: "/",
    dashboard: "/dashboard",
    myNotifications: "/Notification",
    documents: "/DocumentsScreen",
    contact: "/ContactScreen",
    announcements: "/AnnouncementNotification",
    curamLife:
      "https://www.universalhealthfellowship.org/curamlife-care-coordination-app/",
    telemed: "https://members.myflexcare.com/login/member",
    pharma: "https://fliptrx.com/",
    programInformation: "/ProgramInformation",
    myNeeds: "/MyNeeds",
    myTransaction: "/Transaction",
    findAProvider:
      "https://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=netwell.network",
    healthQuestionary: "/Medical",
    faq: "https://www.universalhealthfellowship.org/FAQs/",
    hippa: "",
    hipaaAuth: "",
    uhfCuram: "/MobileCuramLifeCardNotification",
    ChangePaymentScreen: "/ChangePaymentScreen",
    MembershipCard: "/MembershipCard",
    MyNeeds: "MyNeeds",
    HealthToolCard: "/HealthToolCard",
    aiChat: "/AIchat",
    partnershipDocuments:''
  },
  NETWELL: {
    authenticatedDefault: "/dashboard",
    default: "/",
    login: "/",
    dashboard: "/dashboard",
    myNotifications: "/Notification",
    documents: "/DocumentsScreen",
    contact: "/ContactScreen",
    announcements: "/AnnouncementNotification",
    curamLife: "",
    pharma: "",
    telemed: "",
    programInformation: "/ProgramInformation",
    myNeeds: "/MyNeeds",
    myTransaction: "/Transaction",
    findAProvider:
      "http://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=universalhealthshare",
    healthQuestionary: "/Medical",
    faq: "",
    hippa: "/Hipaa",
    hipaaAuth: "/HipaaAuthorization",
    uhfCuram: "",
    ChangePaymentScreen: "/ChangePaymentScreen",
    MembershipCard: "/MembershipCard",
    MyNeeds: "MyNeeds",
    HealthToolCard: "/MembershipCard",
    aiChat: "/AIchat",
    partnershipDocuments:''
  },
  INNOVATION: {
    authenticatedDefault: "/dashboard",
    default: "/",
    login: "/",
    dashboard: "/dashboard",
    myNotifications: "/Notification",
    documents: "/DocumentsScreen",
    contact: "/ContactScreen",
    announcements: "/AnnouncementNotification",
    curamLife: "https://www.cixhealth.com/",
    pharma: "",
    telemed: "https://www.teladoc.com/",
    programInformation: "/ProgramInformation",
    myNeeds: "",
    myTransaction: "/Transaction",
    findAProvider: "",
    healthQuestionary: "/Medical",
    faq: "",
    hippa: "",
    hipaaAuth: "",
    uhfCuram: "",
    ChangePaymentScreen: "",
    MembershipCard: "/MembershipCard",
    MyNeeds: "",
    SingleCareCard: "/SingleCareCard",
    HealthToolCard: "/SingleCareCard",
    DentalCard: "/DentalCard",
    aiChat: "",
    partnershipDocuments:"/PartnershipDocuments"
  },
});

export default routePaths;

export const getRoutePaths = routePaths[brand as keyof typeof routePaths];
