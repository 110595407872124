import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    ".change_Payment_Method": {
      height: "22px",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: ".15px",
      textAlign: "left",
      color: "#162242",
      textTransform: "capitalize",
    },
    ".description-text": {
      fontFamily: "Roboto",
      fontSize: "14px",
      fontWeight: "400",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.14",
      letterSpacing: "normal",
      textAlign: "left",
      color: "rgba(0,0,0,.87)",
    },
  };
});
