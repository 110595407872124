import PrgrmInfo from "../../components/ProgramInformation/";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import titles from "../../config/constants/titles";

const tabs = {
  NETWELL: [
    { label: titles.summary, id: "summary", style: { width: "20%" } },
    { label: titles.visitFees, id: "visit-fees", style: { width: "20%" } },
    {
      label: titles.sharingLimits,
      id: "sharing-limits",
      style: { width: "20%" },
    },
    {
      label: titles.enrolledMembers,
      id: "enrolled-members",
      style: { width: "20%" },
    },
    {
      label: titles.discountPrograms,
      id: "discount-programs",
      style: { width: "20%" },
    },
  ],
  INNOVATION: [
    { label: titles.summary, id: "summary", style: { width: "25%" } },
    {
      label: titles.Services,
      id: "services",
      style: { width: "25%" },
    },
    // {
    //   label: titles.expenseLimits,
    //   id: "expense-limits",
    //   style: { width: "20%" },
    // },
    { label: titles.addOns, id: "add-ons", style: { width: "25%" } },
    {
      label: titles.enrolledMembers,
      id: "enrolled-members",
      style: { width: "25%" },
    },
  ],
  UHF: [
    { label: titles.summary, id: "summary", style: { width: "20%" } },
    {
      label: titles.consultationFees,
      id: "consultation-fees",
      style: { width: "20%" },
    },
    {
      label: titles.expenseLimits,
      id: "expense-limits",
      style: { width: "20%" },
    },
    { label: titles.addOns, id: "add-ons", style: { width: "20%" } },
    {
      label: titles.enrolledMembers,
      id: "enrolled-members",
      style: { width: "20%" },
    },
  ],
};

const footerText = {
  NETWELL: messages.netwell.NEEDHELP_MSG,
  UHF: messages.uhf.NEEDHELP_MSG,
  INNOVATION: "",
};

export const ProductTileInfo = {
  title: titles.ProgramInformation,
  tabs: tabs[config.APP_NAME as keyof typeof tabs],
  Component: PrgrmInfo,
  footerText: footerText[config.APP_NAME as keyof typeof tabs],
  showChatIcon: config.APP_NAME !== "INNOVATION",
};
