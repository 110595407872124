import React, { useEffect, useState, useCallback, SyntheticEvent } from "react";
import {
  InteractiveList,
  StorageHelpers,
  UpdatesCard,
} from "carynhealth-component-library";
import {
  getDocumentsDataByID,
} from "./handlers";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import config from "../../../config/constants/config";
import UHFDocuments from "../../../assests/images/uhf/documents.svg";
import NetwellDocuments from "../../../assests/images/netwell/documents.svg";
import InnovativeDocuments from "../../../assests/images/innovative/documents-icon.svg";
import titles from "../../../config/constants/titles";
import { Grid, styled, useMediaQuery, useTheme } from "@mui/material";
import routePaths from "../../../config/constants/routePaths";
import { useLocation } from "react-router-dom";
import { getIdCardDetails } from "../../../config/routes/handlers";

const brand = config.APP_NAME;
const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

const StyleWrapper = styled("div")(() => {
  return {
    ".list-wrapper": {
      height: "52vh",
      overflow: "scroll",
      li: {
        ".only-description": {
          fontSize: "4.1vw",
          padding: "7px",
        },
      },
    },
    ".INNOVATION .only-description": {
      padding: "7px",
    },
    ".card_errorMessage": {
      display: "flex",
      justifyContent: "center",
      paddingTop: 130,
    },
  };
});
const Documents = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<any>([]);
  const planId: any = getLocalStorage("planId");
  const [error, setError] = useState("");
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();

  const getDocumentsData = async () => {
    setLoading(true);
    const memberSSN = StorageHelpers.getLocalStorage("memberSSN");
    if (memberSSN !== null && memberSSN !== "") {
      getDocumentsDataByMemberSSN(memberSSN);
    } else {
      await getIdCardDetails().then((res) => {
        if (res) getDocumentsDataByMemberSSN(res.MEMBER_SSN);
      });
    }
    setLoading(false);
  };

  const getDocumentsDataByMemberSSN = async (memberSSN: any) => {
    await getDocumentsDataByID(planId, memberSSN)
      .then((res) => {
        // Base64-encoded string
        const base64String = res.apiResponse?.response; // Replace with your Base64 string

        // Decode Base64
        const decodedString = atob(base64String);

        // Parse the JSON back into an array
        const decodedArray = JSON.parse(decodedString);

        console.log(decodedArray);

        setData(decodedArray);
        setError(res.errorMessage);
        if (
          sessionStorage.getItem("USER_ACTIVE_SESSION") &&
          getLocalStorage("planId") &&
          !sessionStorage.getItem("stop")
        ) {
          sessionStorage.setItem("stop", "true");
          window.location.reload();
        }
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    getDocumentsData();
  }, []);

  const openLink = useCallback((event: SyntheticEvent, docInfo: any) => {
    docInfo.document && window.open(docInfo.document);
  }, []);

  return (
    <>
      {showText && location.pathname === routes.documents ? (
        <StyleWrapper>
          {data.length > 0 ? (
            <InteractiveList onClick={openLink} list={data} />
          ) : (
            <span className="card_errorMessage">{error}</span>
          )}
        </StyleWrapper>
      ) : (
        <UpdatesCard
          image={
            brand === config.BRAND_NETWELL
              ? NetwellDocuments
              : brand === config.BRAND_UHF
              ? UHFDocuments
              : InnovativeDocuments
          }
          loading={loading}
          title={titles.PartnershipDocuments}
          Component={() => (
            <>
              {data.length > 0 ? (
                <InteractiveList onClick={openLink} list={data} />
              ) : (
                <p className="card_errorMessage">{error}</p>
              )}
            </>
          )}
        />
      )}
    </>
  );
};

export default Documents;
