import React, { useMemo } from "react";
import { Detail } from "carynhealth-component-library";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

const VisitFeesStyle = styled("section")(({ theme }) => {
  return {
    ".MuiListItem-root:hover": {
      background: "#fff !important",
      color: "initial !important",
    },
  };
});

const VisitFees: React.FC<{ data: any }> = (props) => {
  const vistiFeeData = useMemo(() => {
    const { visitFee } = props.data;
    const data =
      visitFee?.map((service: any) => ({
        title: service.programname,
        subTitle: service.amount,
      })) || [];
    return [data.slice(0, 4), data.slice(4, data.length)];
  }, [props]);

  return (
    <VisitFeesStyle id="pi-visitFee">
      <Grid container spacing={2}>
        {vistiFeeData?.map((vistiFee: any, index) => (
          <Grid item xs={12} md={2}>
            <Detail list={vistiFee} key={`vistiFee${index}`} />
          </Grid>
        ))}
      </Grid>
    </VisitFeesStyle>
  );
};

export default VisitFees;
