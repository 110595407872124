import React, { useCallback, useEffect, useState } from "react";
import { styled } from "@mui/system";
import { CardTile } from "carynhealth-component-library";
import {
  getProductTiles,
  encryptMemberId,
  getUserEmail,
  getRxSimpleShareData,
} from "./handlers";
import { useNavigate } from "react-router-dom";
import { Modal, IconButton, useTheme, useMediaQuery } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { isTablet, isMobile } from "react-device-detect";
import { getPlainIData } from "../../RightTopMemberTabs/handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import config from "../../../config/constants/config";
import { StorageHelpers } from "carynhealth-component-library";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
const isInnovative = config.APP_NAME === config.BRAND_INNOVATION;

const ProductTilesStyleWrapper = styled("div")(({ theme }) => {
  return {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "flex-start",
    img: {
      width: isNetwell ? "auto !important" : "4vw !important",
      height: "4vw !important",
    },
    ".label-wrapper": {
      background: "red",
      fontWeight: 400,
    },
    ".cursor-hand-hover:hover .label-warapper span": {
      color: "#fff",
    },
    [theme.breakpoints.down(768)]: {
      ".cardTitle": {
        letterSpacing: ".9px !important",
      },
    },
  };
});

const Div = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "none",
  };
});

const ModalID = styled(Modal)(({ theme }) => {
  return {
    display: "flex",
  };
});
const Iframe = styled("iframe")(({ theme }) => {
  return {
    width: process.env.REACT_APP_WIDTH,
    height: process.env.REACT_APP_HEIGHT,
    border: "none",
    margin: "auto",
  };
});
const Div1 = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    background: "#f1f1f1",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: process.env.REACT_APP_WIDTH,
  };
});

const ProductTiles = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [modalIdUrl, setModalIdUrl] = useState("");
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  const [data, setData] = useState<any>({
    tiles: [],
    errorMessage: "",
  });
  const planId: any = getLocalStorage("planId");
  const fetchTilesData = async (isDental: Boolean) => {
    if (loading) return false;
    try {
      const data = await getProductTiles();
      let sortedArr = data;
      const planID: any = getLocalStorage("planId");
      // if (getPlainIData(planID) && !isNetwell) {
      //   sortedArr.tiles[0].list[1].label = "Advanced Health Tools";
      // }
      console.log("isDental: ", isDental);

      if (isDental) {
        let obj = {
          label: "Dental Card",
          to: "",
        };
        if (sortedArr?.tiles[0]?.list.length !== 3) {
          sortedArr?.tiles[0]?.list.push(obj);
        }
      } else {
        sortedArr = data;
        if (sortedArr?.tiles[0]?.list.length === 3) {
          sortedArr?.tiles[0]?.list.pop();
        }
      }
      console.log("sortedArr: ", sortedArr);

      setData({ ...sortedArr });

      setLoading(false);
    } catch (error: any) {
      setData({ tiles: [], ...(error || {}) });
      setLoading(false);
    }
  };

  const checkIfDental = () => {
    getRxSimpleShareData().then((res) => {
      let isDental = false;
      if (res.addOns) {
        isDental = res.addOns?.some((entry: any) =>
          entry.addon.includes("Dental") && entry.status === "AC"
        );
        if (isDental) {
          fetchTilesData(true);
        }
      }
    });
  };

  const innovativeFindAProvider = () => {
    const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";

    if (NETWORK_NAME.toLowerCase().includes("ranger"))
      return "https://providerlocator.firsthealth.com/2003";
    if (NETWORK_NAME.toLowerCase().includes("value"))
      return "https://www.multiplan.com/webcenter/portal/ProviderSearch";
    if (NETWORK_NAME.toLowerCase().includes("dental"))
      return "http://www.aetna.com/dentaladministrators";
    return "";
  };

  const findProvider = (data: any) => {
    if (config.APP_NAME == config.BRAND_INNOVATION) {
      let url = innovativeFindAProvider();
      return url;
    }
    return data.href;
  };

  const clickHandler = useCallback((event: any, data: any) => {
    if (data.hasOwnProperty("href") && data.href !== null) {
      return window.open(findProvider(data));
    }
    data?.to && navigate(data.to);
    switch (data.label) {
      case "Membership Card":
        showIDCard();
        break;
      case "Health Tools":
        showHealthToolCard();
        break;
      case "Advanced Health Tools":
        showHealthToolCard();
        break;
      case "SingleCare Card":
        showSingleCareCard();
        break;
      case "Dental Card":
        showDentalIDCard();
        break;
      default:
    }
  }, []);

  const showSingleCareCard = async () => {
    if (isMobile === true || isTablet === true) navigate("/SingleCareCard");
    else {
      let token = localStorage.getItem("bearerToken");
      var response = await encryptMemberId(null);
      setModalIdUrl(
        process.env.REACT_APP_SINGLECARE_IDCARD_URL +
          `?query=${encodeURIComponent(response)}&token=${token}`
      );
    }
  };

  const showIDCard = async () => {
    const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";

    if (isMobile === true || isTablet === true) {
      if (NETWORK_NAME.toLowerCase().includes("dental"))
        navigate("/MembershipCard");
      else navigate("/DentalCard");
    } else {
      let token = localStorage.getItem("bearerToken");
      var response = await encryptMemberId(null);
      if (NETWORK_NAME.toLowerCase().includes("dental")) {
        setModalIdUrl(
          process.env.REACT_APP_DentalCARE_IDCARD_URL +
            `?query=${encodeURIComponent(response)}&token=${token}`
        );
      } else {
        setModalIdUrl(
          process.env.REACT_APP_IDCARD_URL +
            `?query=${encodeURIComponent(response)}&token=${token}`
        );
      }
    }
  };
  const showDentalIDCard = async () => {
    if (isMobile === true || isTablet === true) navigate("/DentalCard");
    else {
      let token = localStorage.getItem("bearerToken");
      var response = await encryptMemberId(null);
      setModalIdUrl(
        process.env.REACT_APP_DentalCARE_IDCARD_URL +
          `?query=${encodeURIComponent(response)}&token=${token}`
      );
    }
  };
  const showHealthToolCard = async () => {
    if (isMobile === true || isTablet === true) navigate("/HealthToolCard");
    else {
      let token = localStorage.getItem("bearerToken");
      var response = await encryptMemberId(null);
      setModalIdUrl(
        process.env.REACT_APP_HEALTHTOOL_URL +
          `?query=${encodeURIComponent(response)}&token=${token}`
      );
    }
  };

  async function setFlexBasis() {
    let isPlaid = await getPlainIData(planId);
    if (!isPlaid && !isNetwell && !showText && !isInnovative) {
      const element: any = document.getElementById("product-tiles");
      if (element?.style) element.style.flexBasis = "60%";
    }
  }
  useEffect(() => {
    checkIfDental()
    fetchTilesData(false);
    getUserEmail();
    setFlexBasis();
  }, []);

  return (
    <>
      <ProductTilesStyleWrapper id="product-tiles">
        {data.tiles.map((tile: any) => (
          <CardTile key={tile.title} {...tile} onClick={clickHandler} />
        ))}
      </ProductTilesStyleWrapper>

      <ModalID open={modalIdUrl !== ""}>
        <Div>
          <Div1>
            <IconButton
              aria-label="close"
              onClick={() => setModalIdUrl("")}
              style={{
                color: "black",
                width: "30px",
                height: "30px",
              }}
            >
              {" "}
              <CloseIcon />
            </IconButton>
          </Div1>
          <Iframe src={modalIdUrl} />
        </Div>
      </ModalID>
    </>
  );
};

export default ProductTiles;
