import { StorageHelpers } from "carynhealth-component-library";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

export const tilesInfo = {
  UHF: {
    MembershipId: {
      to: "",
      description: "ID Cards",
      image: require("../../../assests/images/uhf/membership-card.svg").default,
      imgAlt: "ID Cards",
      title: "ID Cards",
      list: [
        {
          label: "Membership Card",
          to: "",
        },
        {
          label: "Health Tools",
        },
      ],
    },
    ProgramInformation: {
      to: "/ProgramInformation",
      description: "",
      image: require("../../../assests/images/uhf/program-info.svg").default,
      imgAlt: "Program Information",
      title: "Program Information",
      list: [],
    },
    MyNeeds: {
      to: "/MyNeeds",
      description: "",
      image: require("../../../assests/images/uhf/my-needs.svg").default,
      imgAlt: "My Needs",
      title: "My Needs",
      list: [],
    },
    FindProvider: {
      to: "",
      description: "",
      image: require("../../../assests/images/find-a-provider.svg").default,
      imgAlt: "Find a Provider",
      title: "Find a Provider",
      list: [],
      href: "http://findprovider.universalhealthfellowship.org/",
    },
    HealthQuestionnaire: {
      to: "/Medical",
      description: "",
      image: require("../../../assests/images/health-questionare.svg").default,
      imgAlt: "Health Questionnaire",
      title: "Health Questionnaire",
      list: [],
    },
    FAQs: {
      to: "/Medical",
      description: "",
      image: require("../../../assests/images/faq.svg").default,
      imgAlt: "FAQs",
      title: "FAQs",
      list: [],
      href: "https://www.universalhealthfellowship.org/FAQs/",
    },
    MyTransaction: {
      to: "/Transaction",
      description: "",
      image: require("../../../assests/images/my-transactions.svg").default,
      imgAlt: "My Transactions",
      title: "My Transactions",
      list: [],
    },
  },
  NETWELL: {
    MembershipId: {
      to: "",
      description: "ID Cards",
      image: require("../../../assests/images/netwell/membership.png"),
      imgAlt: "ID Cards",
      title: "ID Cards",
      list: [
        {
          label: "Membership Card",
          to: "",
        },
      ],
    },
    ProgramInformation: {
      to: "/ProgramInformation",
      description: "",
      image: require("../../../assests/images/netwell/program-info.svg")
        .default,
      imgAlt: "Program Information",
      title: "Program Information",
      list: [],
    },
    MyNeeds: {
      to: "/MyNeeds",
      description: "",
      image: require("../../../assests/images/netwell/my-needs.svg").default,
      imgAlt: "Share Requests",
      title: "Share Requests",
      list: [],
    },
    MyTransaction: {
      to: "/Transaction",
      description: "",
      image: require("../../../assests/images/my-transactions.svg").default,
      imgAlt: "My Transactions",
      title: "My Transactions",
      list: [],
    },
    FindProvider: {
      to: "",
      description: "",
      image: require("../../../assests/images/netwell/find-a-provider.svg")
        .default,
      imgAlt: "Find a Provider",
      title: "Find a Provider",
      list: [],
      href: "https://www.multiplan.com/webcenter/portal/ProviderSearch?ProviderSearchConfig=ClientSite&SiteUrlSuffix=netwell.network",
    },
    Hipaa: {
      to: "/Hipaa",
      description: "",
      image: require("../../../assests/images/netwell/hippa.svg").default,
      imgAlt: "HIPAA Privacy Authorization",
      title: "HIPAA Privacy Authorization",
      list: [],
    },
  },
  INNOVATION: {
    MembershipId: {
      to: "",
      description: "ID Cards",
      image: require("../../../assests/images/innovative/ID_card_icon.svg")
        .default,
      imgAlt: "ID Wallet",
      title: "ID Wallet",
      list: [
        {
          label: "Membership Card",
          to: "",
        },
        {
          label: "SingleCare Card",
          to: "",
        },
      ],
    },
    ProgramInformation: {
      to: "/ProgramInformation",
      description: "",
      image: require("../../../assests/images/innovative/program-info-icon.svg")
        .default,
      imgAlt: "Program Information",
      title: "Program Information",
      list: [],
    },
    // MyNeeds: {
    //   to: "/MyNeeds",
    //   description: "",
    //   image: require("../../../assests/images/innovative/my-needs.svg").default,
    //   imgAlt: "Share Requests",
    //   title: "Share Requests",
    //   list: [],
    // },
    MyTransaction: {
      to: "/Transaction",
      description: "",
      image: require("../../../assests/images/innovative/my-transactions.svg")
        .default,
      imgAlt: "My Transactions",
      title: "My Transactions",
      list: [],
    },
    FindProvider: {
      to: "",
      description: "",
      image:
        require("../../../assests/images/innovative/find-a-provider-icon.svg")
          .default,
      imgAlt: "Find a Provider",
      title: "Find a Provider",
      list: [],
      href: "",
    },
  },
};
