import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    width: "100%",
    ".MuiTextField-root": {
      width: "100%",
      ".MuiInputLabel-filled ": {
        ".Mui-error": {
          color: "#d32f2f !important",
        },
      },
      ".MuiSelect-select": {
        width: "100%",
      },
    },

    ".MuiInputBase-root": {
      borderRadius: "4px 4px 0 0 !important",
      width: "100%",
    },
    "& .MuiInputBase-input": {
      font: "inherit",
      width: 270,
      borderRadius: "0 !important",
      height: "0.1876em",
      margin: 0,
      display: "block",
      // padding: "14px 5px 7px",
      minWidth: 0,
      background: "none",
      boxSizing: "content-box",
      animationName: "mui-auto-fill-cancel",
      letterSpacing: "inherit",
      animationDuration: "10ms",
    },

    "& .MuiFilledInput-root": {
      backgroundColor: "rgba(0, 0, 0, 0.06)",
      color: "#19191d",
      fontSize: "16px",
      lineHeight: "24px",
      height: 56,
      WebkitHeight: "90px",
      borderColor: "#533278",

      "&:hover": {
        // backgroundColor: "#f4f4f4",
        color: "#533278",
      },
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottom: "2px solid #533278",
      },
      "&.MuiFilledInput-underline:after": {
        borderBottom: "2px solid #533278",
      },
      "&.MuiFilledInput-underline.Mui-error:after": {
        borderBottom: "2px solid #f44336",
      },
    },
    "&.MuiInputBase-formControl": {
      height: "33px",
    },
    "& .Mui-focused": {},
    "& .MuiInputLabel-filled": {
      wordBreak: "normal",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "98%",
      fontSize: "15px",
      overflow: "hidden",
      paddingBottom: "11px",
    },

    "& label.Mui-focused": {
      color: "#533278",
      wordBreak: "normal",
      whiteSpace: "nowrap",
      textOverflow: "ellipsis",
      width: "auto",
      overflow: "hidden",
    },
    "& label.Mui-error": {
      color: "#d32f2f",
    },
    "& p.MuiFormHelperText-contained": {
      margin: 0,
      fontSize: "12px",
      marginTop: "6px",
    },
  };
});
