import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AccordianTransaction,
  CardWrapper,
} from "carynhealth-component-library";
import { ProductTileInfo, TransactionAccordian } from "./data";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material";
import StyleWrapper from "./Style";
import config from "../../config/constants/config";
import AccordianTransactionTable from "./Mobile/AccordianTransaction";

const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
const isUHF = config.APP_NAME === config.BRAND_UHF;

declare global {
  interface Window {
    pureChat: any;
  }
}

const StyleBackWrapper = styled(Link)(() => {
  return {
    color: isNetwell
      ? "var(--NETWELL-btn-color)"
      : isUHF
      ? "var(--UHF-theme-color)"
      : "#458586",
    textDecoration: "none",
    fontFamily: "Lato",
    fontWeight: 600,
    fontSize: 20,
    letterSpacing: 1.14,
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    svg: {
      marginRight: 5,
    },
  };
});

const TransactionStyle = styled("section")(({ theme }) => {
  return {
    "&#program_information": {
      maxWidth: "90%",
      margin: "0px auto",
      paddingTop: 10,
      paddingBottom: 50,
      ".MuiTabs-flexContainer": {
        justifyContent: "space-around",
      },
      ".MuiCardContent-root .MuiButtonBase-root": {
        minWidth: "13%",
        background: "#fff !important",
        color: "#162242",
      },
      ".MuiTabs-indicator": {
        backgroundColor: "#162242",
      },
      ".MuiTabs-scroller": {
        borderBottom: "1px solid #999999",
      },
    },
  };
});

const MyTransaction = () => {
  return (
    <StyleWrapper>
      <div className="webDisplay">
        <TransactionStyle id="program_information">
          <StyleBackWrapper to="/dashboard" className="new_back_page">
            <ArrowBackIcon />
            BACK
          </StyleBackWrapper>
          <CardWrapper {...ProductTileInfo} openChatMethod={window.pureChat} />
        </TransactionStyle>
      </div>
      <div className="mobileDisplay">
        <AccordianTransactionTable />
      </div>
    </StyleWrapper>
  );
};

export default MyTransaction;
