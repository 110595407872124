// Libraries
import React, { Suspense, useEffect, useContext, useState } from "react";

// Internal Components
import DashboardHeader from "../../components/Dashboard/Header";
import MyNotifications from "../../components/Dashboard/MyNotification";
import Documents from "../../components/Dashboard/Documents";
import ContactInformation from "../../components/Dashboard/Contact-Information";
import ProductTiles from "../../components/Dashboard/ProductTile";
import config from "../../config/constants/config";
import { DashboardStyleWrapper } from "./style/dashboardStyle";
import { HealthinfoPopup } from "../../components/Dashboard/HealthinfoPopup";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getPlainIData } from "../../components/RightTopMemberTabs/handler";
import { AuthContext } from "../../context/AuthenticateContext";
import PartnershipDocuments from "../../components/Dashboard/PartnershipDocuments";
const UHFCURAM = React.lazy(
  () => import("../../components/Dashboard/UHFCURAM")
);
const Announcements = React.lazy(
  () => import("../../components/Dashboard/Announcements")
);
const brand = config.APP_NAME;

const Dashboard = () => {
  const auth = useContext(AuthContext);
  const [RenderComponents, setRenderComponents] = useState([]);

  useEffect(() => {
    getBigCards();
  }, [auth.authenticatedInfo.isAuthenticated]);

  async function getBigCards() {
    let sortedComponant: any = [
      MyNotifications,
      Documents,
      Announcements,
      ContactInformation,
      ProductTiles,
    ].filter(Boolean);
    const planId: any = getLocalStorage("planId");
    let isPlaid = await getPlainIData(planId);

    if (!isPlaid && brand == config.BRAND_UHF) {
      sortedComponant = [
        MyNotifications,
        Documents,
        UHFCURAM,
        ContactInformation,
        Announcements,
        ProductTiles,
      ].filter(Boolean);
    }
    if (brand == config.BRAND_INNOVATION) {
      sortedComponant = [Documents,PartnershipDocuments, ContactInformation, ProductTiles].filter(
        Boolean
      );
    }
    setRenderComponents(sortedComponant);
  }
  return (
    <DashboardStyleWrapper id="dashboard">
      <HealthinfoPopup />
      <DashboardHeader />
      <div id="dashboard-notifications" className={`notifications-${brand}`}>
        {RenderComponents.map((Component: any, index: number) => (
          <Suspense key={index} fallback={<div>loading...</div>}>
            <Component />
          </Suspense>
        ))}
      </div>
    </DashboardStyleWrapper>
  );
};

export default Dashboard;
