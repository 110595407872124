import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    ".mobileDisplay": {
      [theme.breakpoints.down(768)]: {
        minHeight: "90vh",
      },
      [theme.breakpoints.down(426)]: {
        "#panel1d-header": {
          marginTop: "10vh !important",
        },
      },
      "#panel1d-header": {
        marginTop: "5.5vh",
      },
      "#panel2d-header": {
        marginTop: "-3px",
      },
    },
    ".change_Payment_Method": {
      height: "22px",
      fontFamily: "Roboto",
      fontSize: "18px",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1",
      letterSpacing: ".15px",
      textAlign: "left",
      color: "#162242",
    },
    ".Last_4_digt_mobile": {
      width: "305px",
      borderRadius: "4px",
      border: "solid 1px #c6cacc",
      float: "inline-end",

      margin: "auto",
      backgroundColor: "#f1f1f1",
    },
    ".ButtonBGMobile": {
      width: "145px",
      height: "40px",
      padding: "0 0.2px 0 0",
      borderRadius: "30px",
      border: "solid 2px #ffffff",
      background: "#41b5c2",
      color: "#ffffff",
      fontSize: "18px",
      fontWeight: 600,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1,
      letterSpacing: "1.44px",
      textAlign: "center",
    },
    ".Bottom_Blue": {
      position: "fixed",
      right: "0",
      bottom: "7%",
      left: "0",
      zIndex: "1050",
      paddingTop: "9px",
      paddingBottom: "10px",
      paddingLeft: "2px",
      backgroundColor: "#41b5c2",
    },
    ".td_style_mobile": {
      width: "194px",
      height: "32px",
      margin: "0 2px 0 0",
      fontFamily: "Roboto",
      fontSize: "16px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.5",
      letterSpacing: "0.44px",
      textAlign: "left",
      color: "#19191d",
    },
    ".td_style1_mobile": {
      width: "149px",
      height: "32px",
      margin: "0 2px 0 0",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "0.4px",
      textAlign: "left",
      color: "#787885",
      padding: "8px",
    },
    ".ButtonBG_Disable": {
      width: "150px !important",
      height: "40px !important",
      margin: "0 20px 0 0 !important",
      padding: "0 0.2px 0 0 !important",
      borderRadius: "30px !important",
      border: "solid 2px #94d2da !important",
      backgroundColor: "#94d2da !important",
      color: "#ffffff !important",
      fontWeight: "600 !important",
      fontStretch: "normal !important",
      fontStyle: "normal !important",
      lineHeight: "1 !important",
      letterSpacing: "1.44px !important",
      textAlign: "center !important",
    },
    ".ButtonBG": {
      width: "150px !important",
      height: "40px !important",
      margin: "0 20px 0 0 !important",
      padding: "0 0.2px 0 0 !important",
      borderRadius: "30px !important",
      border: "solid 2px #41b5c2 !important",
      backgroundColor: "#41b5c2 !important",
      color: "#ffffff !important",
      fontWeight: "600 !important",
      fontStretch: "normal !important",
      fontStyle: "normal !important",
      lineHeight: "1 !important",
      letterSpacing: "1.44px !important",
      textAlign: "center !important",
      fontFamily: "Oswald",
    },
    ".web_status_button": {
      pointerEvents: "none",
      cursor: "default",
      color: "#ffffff",
      height: "16px",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "0.4px",
      textAlign: "left",
      padding: "5px 10px",
      borderRadius: "4px",
    },
    ".data_not_found": {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      fontSize: "18px",
      color: "black",
      height: "auto",
    },
    ".Footer_error_msg": {
      color: "red",
      fontSize: "16px",
      fontWeight: "500",
      marginRight: "34% !important",
    },
    ".Change-Payment-Method": {
      height: "22px",
      fontSize: "18px",
      fontWeight: "500",
      fontStyle: "Roboto",
      fontStretch: "normal",

      lineHeight: "1",
      letterSpacing: "0.15px",
      textAlign: "left",
      color: "#5f2161",
    },
    ".description_text": {
      paddingTop: "10px",
      margin: "0px 28px 17px 25px",
      fontSize: "14px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "Roboto",
      lineHeight: "1.14",
      letterSpacing: "normal",
      textAlign: "justify",
      color: "rgba(0, 0, 0, 0.87)",
    },
    ".paymentBlock": {
      marginTop: "-103px",
      marginLeft: "2px",
      marginBottom: "-9px",
      // height: "560px",
      marginRight: "-28px",
      background: "#fcfcfc",
      padding: "21px 19px 20px 16px",
    },
    ".noneeds_msg_display": {
      color: "black",
      fontWeight: 500,
      fontSize: "1.25rem",
      marginTop: "15vh",
    },
    ".Last_4_digit": {
      borderRadius: "4px",
      border: "solid 1px #c6cacc",
      float: "right",
      marginBottom: "4px",
      marginTop: "-49px",
      backgroundColor: "#f1f1f1",
    },
    ".td_style1": {
      width: "149px",
      height: "32px",
      margin: "0 2px 0 0",
      fontFamily: "Roboto",
      fontSize: "12px",
      fontWeight: "normal",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.33",
      letterSpacing: "0.4px",
      textAlign: "left",
      color: "#787885",
      paddingLeft: "7px",
      paddingTop: "5px",
      paddingBottom: "5px",
    },
  };
});
