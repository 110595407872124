import React from "react";
import config from "../../config/constants/config";
import routePaths from "../../config/constants/routePaths";
import titles from "../../config/constants/titles";
import { getPlainIData } from "../RightTopMemberTabs/handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { Dialog, InteractiveList } from "carynhealth-component-library";
import { DocumentList } from "../LeftDrawerPopups/DocumentList";
import ContactInformationWrapper from "../Dashboard/Contact-Information";
import UHFCURAM from "../Dashboard/UHFCURAM";
import Documents from "../Dashboard/Documents";
import Notifications from "../Notifications";
import { IDCard } from "../LeftDrawerPopups/IDCardPopup";
import { useMediaQuery, useTheme } from "@mui/material";
import PartnershipDocuments from "../Dashboard/PartnershipDocuments";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];
const planID: any = getLocalStorage("planId");

const DocumentComp = (
  <DocumentList title="Documents" open={true} Component={Documents} />
);
const PatnerDocumentComp = (
  <DocumentList title="Partnership Documents" open={true} Component={PartnershipDocuments} />
);
const ContactInfoComp = (
  <DocumentList
    title="Contact"
    open={true}
    Component={ContactInformationWrapper}
  />
);
const IDCardComp = <IDCard open={true} />;
const UHFCuramComp = (
  <DocumentList title="UHF_Curam" open={true} Component={UHFCURAM} />
);

const headerData: any = {
  UHF: {
    user: "",
    Version: "Version 4.1.0",
    style: {
      height: 80,
      ".headerMain": {
        ".header_middle-section": {
          img: {
            marginLeft: 10,
            objectFit: "contain",
          },
        },
      },
    },
    middleSectionStyle: {
      paddingLeft: 2,
      objectFit: "contain",
    },
    rightSectionIconList: {
      rightCallBack: (data: any) => {
        console.log(data);
      },
      Iconlist: [
        {
          alt: "Notification",
          image: require("../../assests/images/uhf/notification-bell.png"),
          Component: Notifications,
          badge: 4,
        },
      ],
    },
    listOfSideBarStyle: {
      // backgroundColor: "white",
      color: "black",
      listItemText: {
        fontSize: 16,
        fontWeight: "500",
      },
      StyledList: {
        "&& .Mui-selected, && .Mui-selected:hover": {
          backgroundColor: "#e5e7ea !important",
          borderRight: "5px solid #162242",
          "&, & .MuiListItemIcon-root": {
            color: "black",
          },
        },
        "& .MuiListItemButton-root:hover": {
          backgroundColor: "#e5e7ea !important",
          borderRight: "5px solid #162242",
          "&, & .MuiListItemIcon-root": {
            color: "black",
          },
        },
      },
    },
    logoImage: require("../../assests/images/uhf/logo.svg").default,
    sideBarData: {
      sideBarCallBack: (data: any) => {
        console.log(data);
      },
      logoImage: require("../../assests/images/uhf/uhf-logo.svg").default,
      selectedItem: {
        selectedIndex: 0,
        pageName: "Dashboard",
      },
      listOfSideBar: [
        {
          icon: require("../../assests/images/heart-icon.svg").default,
          text: titles.Dashboard,
          index: 0,
          navigate: {
            path: routes.dashboard,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/notification_bell.svg")
            .default,
          text: titles.MyNotifications,
          index: 1,
          navigate: {
            path: routes.myNotifications,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/notices.svg").default,
          text: "Announcements & Notices",
          index: 2,
          navigate: {
            path: routes.announcements,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/documents.svg").default,
          text: "Documents",
          index: 3,
          component: React.cloneElement(DocumentComp),
          navigate: {
            path: routes.documents,
            newTab: false,
          },
        },
        {
          icon: require("../../assests/images/uhf/curam-life.svg").default,
          text: "CuramLife",
          index: 4,
          navigate: {
            path: routes.curamLife,
            newTab: true,
          },
          displayType: "planId",
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/pharma_benefits_icon_active.svg")
            .default,
          text: "Pharma",
          index: 5,
          navigate: {
            path: routes.pharma,
            newTab: true,
          },
          displayType: "byPharma",
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/telemed.svg").default,
          text: "Telemed",
          index: 6,
          navigate: {
            path: routes.telemed,
            newTab: true,
          },
          displayType: "planId",
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/membership-card.svg").default,
          text: "Membership Card",
          index: 7,
          component: React.cloneElement(IDCardComp),
          navigate: {},
        },
        {
          icon: require("../../assests/images/uhf/program-info.svg").default,
          text: "Program Information",
          index: 8,
          navigate: {
            path: routes.programInformation,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/curam-life.svg").default,
          text: "UHF / Curam Wellness Center™",
          index: 9,
          component: React.cloneElement(UHFCuramComp),
          navigate: {
            path: routes.uhfCuram,
            newTab: false,
          },
          displayType: "planId",
        },
        {
          icon: require("../../assests/images/uhf/my-needs.svg").default,
          text: "My Needs",
          index: 10,
          navigate: {
            path: routes.myNeeds,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/my-transactions.svg").default,
          text: "My Transactions",
          index: 11,
          navigate: {
            path: routes.myTransaction,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/find-a-provider.svg").default,
          text: "Find a Provider",
          index: 12,
          navigate: {
            path: routes.findAProvider,
            newTab: true,
          },
          displayType: "planId",
          component: null,
        },
        {
          icon: require("../../assests/images/health-questionare.svg").default,
          text: "Health Questionnaire",
          index: 13,
          navigate: {
            path: routes.healthQuestionary,
            newTab: false,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/uhf/faq.svg").default,
          text: "FAQs",
          index: 14,
          navigate: {
            path: routes.faq,
            newTab: true,
          },
          component: null,
        },
        {
          icon: require("../../assests/images/contact-info.svg").default,
          text: "Contact Information",
          index: 15,
          component: React.cloneElement(ContactInfoComp),
          navigate: {
            path: routes.contact,
            newTab: false,
          },
        },
        {
          icon: require("../../assests/images/logout-icon.svg").default,
          text: "Sign Out",
          index: 0,
          navigate: {
            path: routes.login,
            newTab: true,
          },
          component: null,
        },
      ],
    },
  },
  NETWELL: {
    user: "",
    Version: "Version 1.0",
    style: {
      height: 70,
      ".headerMain": {
        ".header_middle-section": {
          img: {
            marginLeft: 10,
            objectFit: "contain",
          },
        },
      },
    },
    rightSectionIconList: {
      rightCallBack: (data: any) => {
        console.log(data);
      },
      Iconlist: [
        {
          alt: "Notification",
          image: require("../../assests/images/netwell/notification-bell.png"),
          Component: Notifications,
        },
        {
          alt: "Logout",
          image: require("../../assests/images/logout-icon.svg").default,
        },
      ],
    },
    listOfSideBarStyle: {
      // backgroundColor: "white",
      color: "black",
      listItemText: {
        fontSize: 16,
        fontWeight: 500,
      },
      StyledList: {
        "&& .Mui-selected, && .Mui-selected:hover": {
          backgroundColor: " #e5e7ea",
          borderRight: "5px solid #162242",
          "&, & .MuiListItemIcon-root": {
            color: "black",
          },
        },
        "& .MuiListItemButton-root:hover": {
          backgroundColor: "#e5e7ea",
          borderRight: "5px solid #162242",
          "&, & .MuiListItemIcon-root": {
            color: "black",
          },
        },
      },
    },
    logoImage: require("../../assests/images/netwell/logo.png").default,
    sideBarData: {
      sideBarCallBack: (data: any) => {
        console.log(data);
      },
      selectedItem: {
        selectedIndex: 0,
        pageName: "Dashboard",
      },
      listOfSideBar: [
        {
          icon: require("../../assests/images/heart-icon.svg").default,
          text: titles.Dashboard,
          navigate: {
            path: routes.dashboard,
            newTab: false,
          },
          component: null,
          index: 0,
        },
        {
          icon: require("../../assests/images/notification_bell.svg").default,
          text: "My Notifications",
          navigate: {
            path: routes.myNotifications,
            newTab: false,
          },
          component: null,
          index: 1,
        },
        {
          icon: require("../../assests/images/notices.svg").default,
          text: "Announcements & Notices",
          navigate: {
            path: routes.announcements,
            newTab: false,
          },
          component: null,
          index: 2,
        },
        {
          icon: require("../../assests/images/netwell/documents.svg").default,
          text: "Documents",
          component: React.cloneElement(DocumentComp),
          navigate: {},
          index: 3,
        },
        {
          icon: require("../../assests/images/netwell/membership.png"),
          text: "Membership Card",
          component: React.cloneElement(IDCardComp),
          navigate: {},
          index: 4,
        },
        {
          icon: require("../../assests/images/netwell/program-info.svg")
            .default,
          text: "Program Information",
          navigate: {
            path: routes.programInformation,
            newTab: false,
          },
          component: null,
          index: 5,
        },
        {
          icon: require("../../assests/images/netwell/my-needs.svg").default,
          text: "Share Requests",
          component: null,
          navigate: {
            path: routes.myNeeds,
            newTab: false,
          },
          index: 6,
        },
        {
          icon: require("../../assests/images/my-transactions.svg").default,
          text: "My Transactions",
          navigate: {
            path: routes.myTransaction,
            newTab: false,
          },
          component: null,
          index: 7,
        },
        {
          icon: require("../../assests/images/find-a-provider.svg").default,
          text: "Find a Provider",
          navigate: {
            path: routes.findAProvider,
            newTab: true,
          },
          component: null,
          index: 8,
        },
        {
          icon: require("../../assests/images/contact-info.svg").default,
          text: "Contact Information",
          component: React.cloneElement(ContactInfoComp),
          navigate: {},
          index: 9,
        },
        {
          icon: require("../../assests/images/netwell/hippa.svg").default,
          text: "HIPAA Privacy Authorization",
          component: null,
          navigate: {
            path: routes.hippa,
            newTab: false,
          },
          index: 10,
        },
        {
          icon: require("../../assests/images/logout-icon.svg").default,
          text: "Sign Out",
          navigate: {
            path: routes.login,
            newTab: false,
          },
          component: null,
          index: 0,
        },
      ],
    },
  },
  INNOVATION: {
    user: "",
    Version: "Version 0.1",
    style: {
      height: 80,
      ".headerMain": {
        ".header_middle-section": {
          img: {
            marginLeft: 10,
            objectFit: "contain",
          },
        },
      },
    },
    rightSectionIconList: {
      rightCallBack: (data: any) => {
        console.log(data);
      },
      Iconlist: [
        // {
        //   alt: "Notification",
        //   image: require("../../assests/images/netwell/notification-bell.png"),
        //   Component: Notifications,
        // },
        // {
        //   alt: "Profile",
        //   image: require("../../assests/images/innovative/profile-icon.png"),
        // },
      ],
    },
    listOfSideBarStyle: {
      // backgroundColor: "white",
      color: "black",
      listItemText: {
        fontSize: 16,
        fontWeight: 500,
      },
      StyledList: {
        "&& .Mui-selected, && .Mui-selected:hover": {
          backgroundColor: " #e5e7ea",
          borderRight: "5px solid #162242",
          "&, & .MuiListItemIcon-root": {
            color: "black",
          },
        },
        "& .MuiListItemButton-root:hover": {
          backgroundColor: "#e5e7ea",
          borderRight: "5px solid #162242",
          "&, & .MuiListItemIcon-root": {
            color: "black",
          },
        },
      },
    },
    logoImageStyle: { },
    logoImage: require("../../assests/images/innovative/IP_AEC_logo.png"),
    sideBarData: {
      logoImage: require("../../assests/images/innovative/IP_AEC_logo.png"),
      sideBarCallBack: (data: any) => {
        console.log(data);
      },
      selectedItem: {
        selectedIndex: 0,
        pageName: "Dashboard",
      },
      listOfSideBar: [
        {
          icon: require("../../assests/images/heart-icon.svg").default,
          text: titles.Dashboard,
          navigate: {
            path: routes.dashboard,
            newTab: false,
          },
          component: null,
          index: 0,
        },
        // {
        //   icon: require("../../assests/images/innovative/my-notifications.svg").default,
        //   text: "My Notifications",
        //   navigate: {
        //     path: routes.myNotifications,
        //     newTab: false,
        //   },
        //   component: null,
        //   index: 1,
        // },
        // {
        //   icon: require("../../assests/images/innovative/notices-icon.svg").default,
        //   text: "Announcements & Notices",
        //   navigate: {
        //     path: routes.announcements,
        //     newTab: false,
        //   },
        //   component: null,
        //   index: 2,
        // },
        {
          icon: require("../../assests/images/innovative/documents-icon.svg")
            .default,
          text: "Documents",
          component: React.cloneElement(DocumentComp),
          navigate: {
            path: routes.documents,
            newTab: false,
          },
          index: 1,
        },
        {
          icon: require("../../assests/images/innovative/documents-icon.svg")
            .default,
          text: "Partnership Documents",
          component: React.cloneElement(PatnerDocumentComp),
          navigate: {
            path: routes.partnershipDocuments,
            newTab: false,
          },
          index: 1,
        },
        {
          icon: require("../../assests/images/innovative/ID_card_icon.svg")
            .default,
          text: "Membership Card",
          component: React.cloneElement(IDCardComp),
          navigate: {},
          index: 2,
        },
        {
          icon: require("../../assests/images/innovative/program-info-icon.svg")
            .default,
          text: "Program Information",
          navigate: {
            path: routes.programInformation,
            newTab: false,
          },
          component: null,
          index: 3,
        },
        // {
        //   icon: require("../../assests/images/innovative/my-needs.svg").default,
        //   text: "Share Requests",
        //   component: null,
        //   navigate: {
        //     path: routes.myNeeds,
        //     newTab: false,
        //   },
        //   index: 6,
        // },
        {
          icon: require("../../assests/images/my-transactions.svg").default,
          text: "My Transactions",
          navigate: {
            path: routes.myTransaction,
            newTab: false,
          },
          component: null,
          index: 4,
        },
        {
          icon: require("../../assests/images/innovative/cix.webp"),
          text: "Cix Health",
          navigate: {
            path: routes.curamLife,
            newTab: true,
          },
          component: null,
          displayType: "planId",
          index: 5,
        },
        {
          icon: require("../../assests/images/innovative/teladoc.png"),
          text: "Teladoc",
          navigate: {
            path: routes.telemed,
            newTab: true,
          },
          displayType: "planId",
          component: null,
          index: 6,
        },
        {
          icon: require("../../assests/images/find-a-provider.svg").default,
          text: "Find a Provider",
          navigate: {
            path: routes.findAProvider,
            newTab: true,
          },
          component: null,
          index: 7,
        },
        {
          icon: require("../../assests/images/contact-info.svg").default,
          text: "Contact Information",
          component: React.cloneElement(ContactInfoComp),
          navigate: {
            path: routes.contact,
            newTab: false,
          },
          index: 8,
        },
        {
          icon: require("../../assests/images/logout-icon.svg").default,
          text: "Sign Out",
          navigate: {
            path: routes.login,
            newTab: false,
          },
          component: null,
          index: 0,
        },
      ],
    },
  },
};

export default headerData;
