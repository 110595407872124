import { useState } from "react";
import { Dialog, IconButton, styled, Modal } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useNavigate } from "react-router-dom";
import {} from "@mui/material";
import { encryptMemberId } from "../Dashboard/ProductTile/handlers";
import { isTablet, isMobile } from "react-device-detect";
import { StorageHelpers } from "carynhealth-component-library";


const ModalID = styled(Modal)(({ theme }) => {
  return {
    display: "flex",
  };
});
const Iframe = styled("iframe")(({ theme }) => {
  return {
    width: process.env.REACT_APP_WIDTH,
    height: process.env.REACT_APP_HEIGHT,
    border: "none",
    margin: "auto",
  };
});
const Div1 = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    background: "#f1f1f1",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: process.env.REACT_APP_WIDTH,
  };
});
const Div = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "none",
  };
});
export const IDCard = (props: any) => {
  const [open, setOpen] = useState(props?.open || true);
  const handleClose = () => {
    props?.onClick();
    setOpen(false);
  };
  return <IDCardPopup open={open} setOpen={handleClose} />;
};
const IDCardPopup = (props: { open: boolean; setOpen: any }) => {
  const [open, setOpen] = useState(props.open);
  const [modalIdUrl, setModalIdUrl] = useState("");
  const navigate = useNavigate();
  React.useEffect(() => {
    if (props.open) showIDCard();
    else setModalIdUrl("");
  }, [props.open]);
  const showIDCard = async () => {
const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";
    if (isMobile === true || isTablet === true) {
      if (NETWORK_NAME.toLowerCase().includes("dental"))
        navigate("/MembershipCard");
      else navigate("/DentalCard");
    } else {
      let token = localStorage.getItem("bearerToken");
      var response = await encryptMemberId(null);
      if (NETWORK_NAME.toLowerCase().includes("dental")) {
        setModalIdUrl(
          process.env.REACT_APP_DentalCARE_IDCARD_URL +
            `?query=${encodeURIComponent(response)}&token=${token}`
        );
      } else {
        setModalIdUrl(
          process.env.REACT_APP_IDCARD_URL +
            `?query=${encodeURIComponent(response)}&token=${token}`
        );
      }
    }
  };

  return (
    <ModalID open={modalIdUrl !== ""}>
      <Div>
        <Div1>
          <IconButton
            aria-label="close"
            onClick={() => props.setOpen()}
            style={{
              color: "black",
              width: "30px",
              height: "30px",
            }}
          >
            {" "}
            <CloseIcon />
          </IconButton>
        </Div1>
        <Iframe src={modalIdUrl} />
      </Div>
    </ModalID>
  );
};
