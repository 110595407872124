import React, { useEffect, useState } from "react";
import {
  EnrolledMembers,
  useCommonLoader,
} from "carynhealth-component-library";
import { styled } from "@mui/system";
import config from "../../../config/constants/config";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";

const EnrolledMemberStyle = styled("section")(({ theme }) => {
  return {
    margin: "20px 0",
    ".MuiTableCell-root": {
      border: "none",
      padding: "7px",
      fontSize: "1rem",
      color: "#000",
    },
    ".MuiTableRow-root": {
      verticalAlign: "baseline",
    },
    ".MuiTable-root": {
      width: "55%",
    },
    "&.INNOVATION-copay": {
      ".MuiTable-root th:nth-child(1)": {
        width: "35%",
      },
      ".MuiTable-root th:nth-child(2)": {
        width: "20%",
      },
      ".MuiTable-root th:nth-child(3)": {
        width: "23%",
      },
      ".MuiTableRow-head th": {
        color: "#454d58",
        paddingBottom: 0,
        fontSize: 14,
        textAlign: "left",
        fontFamily: "Roboto",
        fontWeight: "normal",
        borderBottom: "none",
        zIndex: 0,
        letterSpacing: 1.12,
      },
    },
    // ".MuiListItem-root:hover": {
    //   background: "#fff !important",
    //   color: "initial !important",
    // },
    // "&.UHF-Discount": {
    //   ".MuiTableBody-root td:nth-child(2)": {
    //     color: "rgb(95, 33, 97)",
    //   },

    //   ".Mui-disabled": {
    //     color: "#ccc !important",
    //     borderColor: "#ccc !important",
    //   },
    // },
    // "&.INNOVATION-Discount": {
    //   ".update-household": {
    //     display: "none",
    //   },
    // },
  };
});

const headings = [
  { id: "typeOfService", label: "Type of Service" },
  { id: "DaysPerplanYear", label: "Days per plan year" },
  { id: "MaximumAmount", label: "Maximum amount per plan year" },
  { id: "copay", label: "Copay" },
];

const Services: React.FC<{
  data: any;
}> = (props) => {
  const { setLoading, unSetLoading } = useCommonLoader();
  const [popup, setPopup] = useState(false);
  const [data, setData] = useState(props.data.services);

  useEffect(() => {
    setData(props.data.services);
  }, []);
  return (
    <EnrolledMemberStyle className={`${config.APP_NAME}-copay`}>
      <TableContainer
        className={`${config.APP_NAME}-copay`}
        sx={{ maxHeight: 440 }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {headings.map((column) => (
                <TableCell sx={{ fontWeight: 500 }} key={column.id}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row: any, index: number) => {
              return (
                <TableRow hover tabIndex={-1} key={index}>
                  <TableCell>{row.programname}</TableCell>
                  <TableCell>{row.daysPerPlan}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.coPay}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </EnrolledMemberStyle>
  );
};

export default Services;
