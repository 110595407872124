import { apiService } from "carynhealth-component-library";
import { useContext } from "react";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import { AuthContext } from "../../context/AuthenticateContext";
export interface EnrolledMemberData {
  data: [];
}
export const getHealthQuestionData = async (
  endpoint: string,
  method: "post" | "get",
  body?: object
) => {
  try {
    const request = {
      URL: config.URLS.baseAPI + endpoint,
      brand: config.BRAND_IP_AEC,
      data: body || {},
    };
    const { data: apiResponse } = await apiService.default[method](request);

    return {
      apiResponse,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
