import { Grid } from "@mui/material";
import React, { Component, useEffect, useState } from "react";

export const TextSignature = (props: any) => {
  let [stateStyle, setStyle] = useState({
    fontSize: 50,
  });
  const TextSignContainer = React.useRef<HTMLDivElement>(null);
  const signInnerText = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    let backgroundWidth = TextSignContainer?.current?.offsetWidth as number;
    let textWidth = signInnerText?.current?.offsetWidth as number;
    let index = 0;

    while (textWidth > backgroundWidth && index < 10) {
      setStyle({ fontSize: stateStyle.fontSize - 7 });
      index += 1;
      textWidth = signInnerText?.current?.offsetWidth as number;
    }
  }, [props.text]);

  return (
    <Grid sm={12} md={12} style={{ width: "100%", height: "100%" }}>
      <div className="myStyle">
        <div id="signTextContent" ref={TextSignContainer} className="state">
          <span style={stateStyle} ref={signInnerText}>
            {props.text}
          </span>
        </div>
      </div>
    </Grid>
  );
};

export default TextSignature;
