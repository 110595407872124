import { styled } from "@mui/system";
import config from "../../../config/constants/config";

export const StyleWrapper = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  fontStretch: "normal",
  fontStyle: "normal",
  letterSpacing: "normal",
  textAlign: "left",
  lineHeight: " .9",
  ".netwell-label_container": {
    zIndex: 0,
    display: "flex",
  },
  ".sub-div_container": {
    margin: config.APP_NAME === config.BRAND_INNOVATION ? "36px 0" : "36px",
  },
  ".welcome-text": {
    fontSize: 30,
    fontWeight: 400,
    color: "#7e7e7e",
  },
  ".netwell-username": {
    fontSize: 50,
    fontWeight: 500,
    color: " #000",
  },
  "&.UHF": {
    marginBottom: "42vh",
  },
  ".uhf-label_container": {
    zIndex: 0,
    position: "absolute",
    display: "flex",
  },

  ".welcome_image": {
    marginLeft: "-18vw",
    width: "93vw",
    height: "57vh",
    maxWidth: "100%",
    marginTop: "-10vh",
    zIndex: 0,
  },
  ".welcome-label_container": {
    alignSelf: "center",
    marginLeft: "-30vw",
    zIndex: 0,
  },
  ".welcome_back": {
    marginBottom: " -1.5vw",
    maxWidth: "120%",
    width: "15vw",
  },
  ".uhf-username": {
    textTransform: "uppercase",
    width: " 35vw",
    height: "70px",
    fontSize: "4vw",
    fontWeight: 700,
    fontStretch: "normal",
    fontStyle: "normal",
    lineHeight: 1.1,
    fontFamily: "Anton,sans-serif",
    letterSpacing: "normal",
    color: "#000",
    position: "relative",
    zIndex: "-1",
  },
  ".innovative-label_container": {
    ".welcome-text": {
      color: "#458586",
    },
    ".innovative-username": {
      color: "#0e234d",
      fontSize: 50,
      fontVariant: "all-petite-caps",
      fontWeight: 800,
      fontFamily: "Lato",
    },
  },
  [theme.breakpoints.down(1024)]: {
    display: "none",
  },
}));
