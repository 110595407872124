import Dashboard from "../../pages/Dashboard";
import Login from "../../pages/Login";
import HealthQuestionnaire from "../../UI/HealthQuestionnaire";
import HippaCard from "../../UI/HippaCard";
import ProgramInformationWrapper from "../../UI/ProgramInformation";
import config from "../constants/config";
import enableFeatures from "../constants/enable-features";
import routePaths from "../constants/routePaths";
import MyTransaction from "../../UI/MyTransaction";
import { HipaaAuth } from "../../UI/HippaCard/hippaAuth";
import ChangePaymentScreen from "../../UI/MyTransaction/Mobile/View/ChangePaymentScreen";
import IDcard from "../../UI/IDcards";
import MyNeeds from "../../pages/MyNeeds";
import MyNotifications from "../../components/Dashboard/MyNotification";
import Announcements from "../../components/Dashboard/Announcements";
import aiAssistant from "../../UI/AiAssistance";
import Documents from "../../components/Dashboard/Documents";
import ContactInformationWrapper from "../../components/Dashboard/Contact-Information";
import UHFCURAM from "../../components/Dashboard/UHFCURAM";
import PartnershipDocuments from "../../components/Dashboard/PartnershipDocuments";

const brand = config.APP_NAME;
const routePath = routePaths[brand as keyof typeof routePaths];
const features = enableFeatures[brand as keyof typeof enableFeatures];

console.log(routePath, "routepath");

const routeConfig = [
  {
    path: routePath.default,
    component: Login,
    enable: true,
    authRequired: false,
  },
  {
    path: routePath.MyNeeds,
    component: MyNeeds,
    enable: true,
    authRequired: true,
  },
  // {
  //   path: routePath.dashboard,
  //   component: Dashboard,
  //   enable: features.dashboard.page,
  //   authRequired: false,
  // },
  {
    path: routePath.partnershipDocuments,
    component: PartnershipDocuments,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.programInformation,
    component: ProgramInformationWrapper,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.myTransaction,
    component: MyTransaction,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.MembershipCard,
    component: IDcard,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.HealthToolCard,
    component: IDcard,
    enable: true,
    authRequired: true,
  },
  {
    path: "/DentalCard",
    component: IDcard,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.dashboard,
    component: Dashboard,
    enable: features.dashboard.page,
    authRequired: true,
  },
  {
    path: routePath.hippa,
    component: HippaCard,
    enable: features.hippa.page,
    authRequired: true,
  },
  {
    path: routePath.aiChat,
    component: aiAssistant,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.healthQuestionary,
    component: HealthQuestionnaire,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.hipaaAuth,
    component: HipaaAuth,
    enable: true,
    authRequired: false,
    throughEmail: true,
  },
  {
    path: routePath.myNotifications,
    component: MyNotifications,
    enable: true,
    authRequired: true,
    throughEmail: false,
  },
  {
    path: routePath.announcements,
    component: Announcements,
    enable: true,
    authRequired: true,
    throughEmail: false,
  },
  {
    path: routePath.ChangePaymentScreen,
    component: ChangePaymentScreen,
    enable: true,
    authRequired: false,
    throughEmail: true,
  },
  {
    path: routePath.documents,
    component: Documents,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.contact,
    component: ContactInformationWrapper,
    enable: true,
    authRequired: true,
  },
  {
    path: routePath.uhfCuram,
    component: UHFCURAM,
    enable: true,
    authRequired: true,
  },
  {
    path: "*",
    component: Login,
    enable: features.login,
    authRequired: false,
  },
].filter((data) => data.enable);

export default routeConfig;
