import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";

export const buttonProps = {
  QuesStep: [
    {
      label: "BACK",
      size: "large",
      variant: "text",
      type: "button",
      className: "BACK",
    } as CustomButtonProps,
    {
      label: "NEXT",
      size: "large",
      variant: "text",
      type: "button",
      disabled: false,
      className: "NEXT",
    } as CustomButtonProps,
    {
      label: "Finish Later",
      size: "large",
      variant: "text",
      type: "button",
      disabled: false,
      className: "FinishLater",
    } as CustomButtonProps,
  ],
  ViewStep: [
    {
      label: "BACK",
      size: "large",
      variant: "text",
      type: "button",
      className: "BACK",
    } as CustomButtonProps,
    {
      label: "NEXT",
      size: "large",
      variant: "text",
      type: "button",
      disabled: false,
      className: "NEXT",
    } as CustomButtonProps,
  ],
  DataStep: [
    {
      label: "BACK",
      size: "large",
      variant: "text",
      type: "button",
      className: "BackToDashboard",
    } as CustomButtonProps,
  ],
};
