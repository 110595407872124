import React from "react";
import { styled } from "@mui/system";
import { ContactUHF } from "./components/ContactUHF";
import AgentDetails from "./components/AgentInfo";
import titles from "../../../config/constants/titles";
import { ContactInnovative } from "./components/ContactInnovative";

const StyleWrapper = styled("div")(({ theme }) => ({
  padding: 16,
  fontWeight: 400,
  lineHeight: 1.2,
  letterSpacing: 0.15,
  ".contactinfo_label": {
    fontSize: ".8vw",
    marginTop: 10,
    color: "#4f4f4f",
    fontFamily: "Roboto",
  },
  ".contactinfo_Value": {
    fontSize: "1vw",
    fontFamily: "Roboto",
    fontWeight: 500,
    whiteSpace: "pre",
  },
  "&.NETWELL .contactinfo_Value": {
    color: "#4782c4",
  },
  "&.UHF .contactinfo_Value": {
    color: "#33ab8f",
    whiteSpace:'break-spaces'
  },
  [theme.breakpoints.down(770)]: {
    ".contactinfo_label": {
      fontSize: "3.4vw",
    },
    ".contactinfo_Value": {
      fontSize: "3vw",
      whiteSpace:'break-spaces'
    },
  },
}));

export const Components = {
  INNOVATION: ({ contactInfo }: any) => {
    return (
      <StyleWrapper className="contact_info UHF">
        <ContactInnovative />
      </StyleWrapper>
    );
  },
  UHF: ({ contactInfo }: any) => {
    return (
      <StyleWrapper className="contact_info UHF">
        <ContactUHF contactInfo={{ ...contactInfo }} />
      </StyleWrapper>
    );
  },
  NETWELL: ({ contactInfo }: any) => {
    return (
      <StyleWrapper className="contact_info NETWELL">
        {contactInfo?.contactNumber ? (
          <>
            <div className="contactinfo_label">{titles.PRE_NOTIFY_SERVICE}</div>
            <div className="contactinfo_value">(866) NETWELL (638-9355)</div>
          </>
        ) : null}
        <div className="contactinfo_label">
          Send Medical Sharing Requests to:
        </div>
        <div className="contactinfo_Value">
          netWell
          <br />
          PO Box 14267
          <br />
          Reading, PA 19612-4267
        </div>
        <div className="contactinfo_label">netWell Customer Service:</div>
        <div className="contactinfo_Value">
          {/* (866) NETWELL (638-9355) */}
          {/* <br /> */}
          Members: 855-739-6500
          <br />
          Providers(For Eligibility): 866-638-9355
          <br />
          PreAuthorization: 866-638-9355
          <br />
        </div>
        <div className="contactinfo_label">Services:</div>
        <div className="contactinfo_Value">
          Telemedicine: 833-794-3863
          <br />
          Telecounseling: 833-794-3863
          <br />
          Pharmacy: 844-693-5579
          <br />
        </div>
        <div className="contactinfo_label">Discounts:</div>
        <div className="contactinfo_Value">
          Diabetes: 800-210-9222
          <br />
          Labs: 800-908-0000
          <br />
          Vitamins: 800-824-4491
          <br />
          TruHearing®: 855-825-4706
          <br />
          Coast to Coast Vision™: 888-868-6199
          <br />
          Dental: 888-868-6199
          <br />
        </div>
        <AgentDetails agentInfo={contactInfo.agentInfo} />
      </StyleWrapper>
    );
  },
};
