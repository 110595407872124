import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import React, { useContext } from "react";
import config from "../../../config/constants/config";
import { AuthContext } from "../../../context/AuthenticateContext";
import { Components } from "./component";

const brand = config.APP_NAME;

export const HealthinfoPopup = () => {
  const ContactInfo = Components[brand as keyof typeof Components];
  const subscriberIdSource = getLocalStorage("sourceid");
  return <ContactInfo id={subscriberIdSource} />;
};
