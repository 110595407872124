import WelcomeImg from "../../../assests/images/uhf/welcome-image.svg";
import WelcomeBack from "../../../assests/images/uhf/welcomeBack.svg";

import { StyleWrapper } from "./StyleWapper";

export const Components = {
  NETWELL: (props: any) => {
    return (
      <StyleWrapper>
        <div className="netwell-label_container">
          <div className="sub-div_container">
            <div className="welcome-text">Welcome</div>
            <div className="netwell-username">{props.username} !</div>
          </div>
        </div>
      </StyleWrapper>
    );
  },
  INNOVATION: (props: any) => {
    return (
      <StyleWrapper>
        <div className="innovative-label_container">
          <div className="sub-div_container">
            <div className="welcome-text">Welcome back</div>
            <div className="innovative-username">
              {props.username
                ? props.username
                : localStorage.getItem("subscriberName")}{" "}
              !
            </div>
          </div>
        </div>
      </StyleWrapper>
    );
  },
  UHF: (props: any) => {
    return (
      <StyleWrapper className="UHF">
        <div className="uhf-label_container">
          <img className="welcome_image" src={WelcomeImg} />
          <div className="welcome-label_container">
            <img className="welcome_back" src={WelcomeBack} />
            <div className="uhf-username">{props.username} !</div>
          </div>
        </div>
      </StyleWrapper>
    );
  },
};
