import { setLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import Style from "./style";
import Text from "../../config/constants/messages";

export const PopupContant = ({ close }: any) => {
  console.log(close);
  function setClose() {
    setLocalStorage("popupShow1", false);
    close();
  }
  return (
    <Style>
      <div className="popupContant">
        <div className="web_checkoutboldtext">{Text.uhf.checkoutboldtext}</div>
        <div
          className="web_tooltip_2nd_text"
          dangerouslySetInnerHTML={{
            __html: Text.uhf.tooltipText,
          }}
        />
        <button className="yellow_popup_caption_button" onClick={setClose}>
          CLOSE
        </button>
      </div>
    </Style>
  );
};
