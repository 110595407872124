import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    // UHF
    paddingBottom: 15,
    "&.login-UHF": {
      backgroundColor: "white",
      "#banner": {
        position: "relative",
        maxWidth: 1017,
        overflow: "hidden",
        margin: "0px auto",
        ".banner_hero-banner": {
          height: 400,
          marginTop: 15,
        },
        ".banner_brand-logo": {
          width: "auto",
          position: "absolute",
          right: 0,
          height: 68,
        },
      },
    },

    "&.login-NETWELL": {
      backgroundColor: "white",
      "#banner": {
        overflow: "hidden",
        ".banner_hero-banner": {
          height: "45vh",
          objectPosition: "top",
          objectFit: "cover",
        },
        ".banner_brand-logo": {
          margin: "47px 10px 19px 61px",
          width: "13%",
        },
      },
    },
    "&.login-INNOVATION": {
      backgroundColor: "white",
      "#banner": {
        overflow: "hidden",
        ".banner_hero-banner": {
          objectPosition: "top",
          objectFit: "cover",
        },
        ".banner_brand-logo": {
          margin: "48px 10px 49px 61px",
          width: "20%",
        },
      },
    },

    // Responsive

    // [theme.breakpoints.down(768)]: {

    // },

    [theme.breakpoints.down(768)]: {
      // UHF
      "&.login-UHF": {
        "#banner": {
          paddingTop: 60,
          ".banner_brand-logo": {
            position: "relative !important",
            margin: "0px auto",
            width: "65%",
            height: 74,
          },
          ".banner_hero-banner": {
            marginTop: 10,
            padding: 15,
            height: "auto !important",
          },
        },
      },
      // NETWELL
      "&.login-NETWELL": {
        "#banner": {
          paddingTop: 60,
          ".banner_brand-logo": {
            margin: "10px auto",
            width: "50%",
          },
        },
      },
      "&.login-INNOVATION": {
        "#banner": {
          paddingTop: 60,
          ".banner_brand-logo": {
            margin: "10px auto",
            width: "50%",
          },
          ".banner_hero-banner": {
            width: "100vw",
            height: "50vh",
            backgroundSize: "cover",
            backgroundPosition: "center",
          },
        },
      },
    },
  };
});
