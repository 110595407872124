export const headCells = [
  { id: "arrow", numeric: true, disablePadding: false, label: "" },
  {
    id: "transactionId",
    numeric: true,
    disablePadding: false,
    label: "Transaction ID",
  },
  {
    id: "createdDate",
    numeric: true,
    disablePadding: false,
    label: "Transaction Date",
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Payment Method",
  },
  {
    id: "transactionAmount",
    numeric: true,
    disablePadding: false,
    label: "Total Amount",
  },
  {
    id: "authorizationMessage",
    numeric: true,
    disablePadding: false,
    label: " Status",
  },
];
