import { apiService } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import UHFDocuments from "../../../assests/images/uhf/documents.svg";
import NetwellDocuments from "../../../assests/images/netwell/documents.svg";
import InnovativeDocuments from "../../../assests/images/innovative/documents-icon.svg";
import { DetailsType } from "./interface";
import titles from "../../../config/constants/titles";
import messages from "../../../config/constants/messages";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

const brand = config.APP_NAME;

export const getWelcomeBookletLink = async (type: string) => {
  try {
    const mail = localStorage.getItem("userMail");
    const request = {
      URL: `${config.URLS.baseAPI}${config.URLS.dashboardPlanInfo}`,
      data: { email: mail, type },
    };
    const response = await apiService.default.post(request);
    const providerLink = response.data[0].fieldValue;
    return providerLink;
  } catch (error: any) {
    return error;
  }
};

export const getDocumentsDataByID = async (planId: any, memberSSN: any) => {
  let URL: string =
    config.URLS.baseAPI + config.URLS.getPartnerDocumentByPlanId  + planId;
  const sourceid = getLocalStorage('sourceid');
  if (config.APP_NAME === config.BRAND_INNOVATION)
    URL =
      config.URLS.baseAPI +
      config.URLS.getPartnerDocumentByPlanId  +
      planId +
      "/" +
      memberSSN + "/" + sourceid;
  try {
    const request = {
      URL: URL,
      brand: config.BRAND_IP_AEC,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    return {
      title: titles.Documents,
      image:
        brand === config.BRAND_NETWELL
          ? NetwellDocuments
          : brand === config.BRAND_UHF
          ? UHFDocuments
          : InnovativeDocuments,
      isUHF: brand === config.BRAND_UHF,
      apiResponse,
      errorMessage: messages.NO_DOCUMENTS,
    };
  } catch (error: any) {
    console.log("error", error);
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject({
      errorMessage: messages.NO_DOCUMENTS,
    });
  }
};
