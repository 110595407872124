import React, { useMemo } from "react";
import { Detail } from "carynhealth-component-library";
import { styled } from "@mui/system";
import config from "../../../config/constants/config";

const DiscountPrgrmStyle = styled("section")(({ theme }) => {
  return {
    ".pi-visitFee_includes": {
      fontFamily: "Roboto",
      fontSize: 14,
      fontWeight: 400,
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "normal",
      textAlign: "left",
      color: "#454d58",
      margin: "20px 0 2px 15px",
      letterSpacing: 1.12,
    },
    ".MuiListItem-root": {
      fontFamily: "Roboto",
      paddingBottom: 0,
      paddingTop: 0,
    },
    ".MuiListItem-root:hover": {
      background: "#fff !important",
      color: "initial !important",
    },
  };
});

const DiscountPrgrm: React.FC<{ data: any }> = (props) => {
  const discountPrgrmData = useMemo(() => {
    const { discountPrograms } = props.data;
    return (
      discountPrograms?.map((service: any) => ({
        title: "",
        subTitle: service.discountPrograms,
      })) || []
    );
  }, [props]);

  return (
    <DiscountPrgrmStyle id="pi-visitFee">
      <div className="pi-visitFee_includes">Includes:</div>
      <Detail list={discountPrgrmData} />
    </DiscountPrgrmStyle>
  );
};

export default DiscountPrgrm;
