import config from "../../../config/constants/config";
import AnnouncementNotice from "../../../assests/images/notices.svg";
import { apiService } from "carynhealth-component-library";
import { GetNotifcationsAPIType, NotificationsList } from "./interfaces";
import messages from "../../../config/constants/messages";
import NoticeIcon from "../../../assests/images/notice-icon.svg";
import AnnouncementIcon from "../../../assests/images/notification_announcement_icon.svg";
import AnnouncementNoticeInnovative from "../../../assests/images/innovative/notices-icon.svg";

export const data = {
  UHF: {
    image: AnnouncementNotice,
  },
  NETWELL: {
    image: AnnouncementNotice,
  },
  INNOVATION: {
    image: AnnouncementNoticeInnovative,
  },
};

export const getNotifcationsData = data[config.APP_NAME as keyof typeof data];

export const getNotifcationsAPI = async () => {
  try {
    const request = {
      URL: config.URLS.baseTwillo + config.URLS.getAnnouncemetDetails,
      brand: config.BRAND_IP_AEC,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    const { notificationDetails, count } = apiResponse.response;

    return {
      notificationsList: getNoticesAnnouncements(notificationDetails),
      count,
      errorMessage: "",
    };
  } catch (error: any) {
    console.log(error);
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

const notifyImages = {
  NOTICE: NoticeIcon,
  ANNOUNCEMENT: AnnouncementIcon,
};

function getNoticesAnnouncements(
  data: GetNotifcationsAPIType[]
): Array<NotificationsList> {
  return data
    .sort((a, b) => a.id - b.id)
    .slice(0, 5)
    .map((list) => {
      return {
        image: notifyImages[list.type as keyof typeof notifyImages],
        label: list.type,
        labelRight: dateformat(list.createdDate, "medium"),
        description: list.title,
        message: list.message,
        urlPath: list.urlPath,
        type: list.type,
        date: dateformat(list.createdDate, "long"),
      };
    });
}

const formatDate = new Intl.DateTimeFormat("en-us", {
  dateStyle: "short",
});

const formatDateMonth = new Intl.DateTimeFormat("en-US", {
  month: "numeric",
  day: "2-digit",
});

export function dateformat(
  date: string,
  type: "medium" | "full" | "long" | "short"
) {
  if (!date) return "";

  const enrollmentDate = new Date(date);

  const formatDateMonth = new Intl.DateTimeFormat("en-US", { dateStyle: type });
  return formatDateMonth.format(enrollmentDate);
}

function publishDateformat(date: string) {
  const enrollmentDate = new Date(date);
  return formatDate.format(enrollmentDate);
}
