import React, { useContext } from "react";
import config from "../../../config/constants/config";
import { AuthContext } from "../../../context/AuthenticateContext";
import { Components } from "./component";

const brand = config.APP_NAME;

const DashboardHeader = () => {
  const ContactInfo = Components[brand as keyof typeof Components];
  const auth = useContext(AuthContext);
  return <ContactInfo username={auth.authenticatedInfo.name} />;
};

export default DashboardHeader;
