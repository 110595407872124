import React from "react";
import {
  MyReimbursement,
  MyShareRequests,
} from "carynhealth-component-library";
import config from "../../config/constants/config";

const MyNeedsTabs = (props: any) => {
  return props.tabInfo.id !== "my-share-requests" ? (
    <MyReimbursement onCallback={props.setRef} />
  ) : (
    <MyShareRequests
      brand={(config.APP_NAME || "netwell") as "netwell" | "uhf"}
      mode="prod"
    />
  );
};

export default MyNeedsTabs;
