import React, { useCallback, useContext } from "react";
import { HeaderTabsComponent } from "carynhealth-component-library";
import title from "../../../config/constants/titles";
import Style from "../style";
import Text from "../../../config/constants/messages";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import { RightDrawer } from "../RightDrawer";
import { getPlainIData, getRxSimpleShareData } from "../handler";
import { AuthContext } from "../../../context/AuthenticateContext";
import config from "../../../config/constants/config";
import { PopupContant } from "../PopupContant";
import routePaths from "../../../config/constants/routePaths";
import { useLocation } from "react-router-dom";
import { getIdCardDetails } from "../../../config/routes/handlers";
import { StorageHelpers } from "carynhealth-component-library";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

export const HeaderTab = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const [yellowPopup, setYellowPopup] = React.useState(false);
  let [memberSSN, setMemberSSN] = React.useState("");
  const [planId, setPlanId] = React.useState("");
  const [pharmaShow, setPharmaShow] = React.useState(true);

  React.useEffect(() => {
    setPopupValue();
  }, [auth.authenticatedInfo.isAuthenticated]);

  const setPopupValue = async () => {
    const email: string | any = getLocalStorage("userMail");
    try {
      let planId = StorageHelpers.getLocalStorage("planId") || "";
      let memberSSN = StorageHelpers.getLocalStorage("memberSSN") || "";
      if (!planId || !memberSSN) {
        if(email)
        await getIdCardDetails(email).then((res) => {
          planId = res.apiResponse.memberIdCardList[0].planId;
          memberSSN = res.MEMBER_SSN;
          setLocalStorage("planId", planId);
          setLocalStorage("memberSSN", memberSSN);
          setPlanId(planId);
        });
      }
      setPlanId(planId);
      if (!getLocalStorage("popupShow1")) {
        if (getPlainIData(planId)) {
          setLocalStorage("popupShow1", false);
        } else {
          setLocalStorage("popupShow1", true);
        }
      }
      setYellowPopup(getLocalStorage("popupShow1") == "true" ? true : false);
      // getSimpleShareData(memberSSN);
      setMemberSSN(memberSSN);
    } catch (error) {
      console.log("error");
    }
  };
  const getSimpleShareData = (memberSSN: string) => {
    let data = {
      memberSSN: memberSSN,
      type: "family",
    };
    if(memberSSN)
    getRxSimpleShareData(data).then((res) => {
      if (res.apiResponse && Array.isArray(res.apiResponse)) {
        res.apiResponse.map((value) => {
          if (value.addon === "UHS RxSimpleShare" && value.status === "AC") {
            setPharmaShow(true);
          }
        });
      }
    });
  };
  if (
    (!auth.authenticatedInfo.isAuthenticated &&
      sessionStorage.getItem("USER_ACTIVE_SESSION") !== "true") ||
    config.APP_NAME == config.BRAND_NETWELL ||
    location.pathname !== routes.dashboard
  )
    return null;
  return (
    <HeaderTabsComponent
      data={
        config.APP_NAME === config.BRAND_INNOVATION
          ? [
              {
                text: title.memberServices,
              },
              {
                text: title.memberApps,
                Component: (
                  <RightDrawer
                    pharmaShow={pharmaShow}
                    planID={planId}
                    headerTaps={true}
                  />
                ),
              },
            ]
          : [
              {
                text: title.memberServices,
              },
              {
                text: title.memberApps,
                Component: (
                  <RightDrawer
                    pharmaShow={pharmaShow}
                    planID={planId}
                    headerTaps={true}
                  />
                ),
                PopupComponent:
                  location.pathname === routes.dashboard && yellowPopup
                    ? PopupContant
                    : null,
              },
            ]
      }
      callBack={(data) => console.log(data)}
    />
  );
};
