import React, { useCallback, useContext, useMemo } from "react";
import { Authentication } from "carynhealth-component-library";
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { AuthenticationType } from "carynhealth-component-library/dist/cjs/components/Authentication/interface";
import { authenticationProps } from "./data";
import StyleWrapper from "./style";
import config from "../../config/constants/config";
import { getRoutePaths } from "../../config/constants/routePaths";
import { AuthContext, AuthInfoType } from "../../context/AuthenticateContext";
import { getEnrolledMemberDataAPI } from "../../UI/HippaCard/handlers";
import { getIdCardDetails } from "../../config/routes/handlers";

const brand = config.APP_NAME;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const auth = useContext(AuthContext);

  const authenticate = useCallback(
    (authenticateInfo: { authenticatedUser: AuthInfoType }) => {
      auth.updateAuthenticateInfo(
        { ...authenticateInfo?.authenticatedUser, isAuthenticated: true } ||
          auth.authenticatedInfo
      );
      idcardData(authenticateInfo?.authenticatedUser?.email)
    },
    []
  );

  const idcardData = (email: string) => {
    getIdCardDetails(email).then(async (data: any) => {
      let authInfo = {
        ...auth.authenticatedInfo,
        email: email,
        isAuthenticated: true,
        throughEmail: true,
      };
      localStorage.setItem("planId", data.apiResponse.memberIdCardList[0].planId)
      await getEnrolledMemberDataAPI(data.apiResponse.memberIdCardList[0].memberId)
      .then((res: any) => {
        authInfo = {
          ...authInfo,
          name:`${res.apiResponse[0]?.prefix || ''} ${res.apiResponse[0].firstName || ''} ${res.apiResponse[0]?.middleName || ''} ${res.apiResponse[0].lastName || ''} ${res.apiResponse[0]?.suffix || ''}`.trim().replace(/\s+/g, ' ')
        }
      })
      .catch((error) => {
        console.log(error);
      });   
      auth.updateAuthenticateInfo(authInfo || auth.authenticatedInfo);
      localStorage.setItem(
        "subscriberName",
        authInfo.name
      );
      navigate({
        pathname: getRoutePaths.dashboard,
      });
    });
  };

  const authenticateDetails = useMemo(
    () => authenticationProps({ isSmallScreen }),
    [isSmallScreen]
  );

  return (
    <StyleWrapper className={`login-${brand}`}>
      <Authentication
        {...(authenticateDetails as AuthenticationType)}
        authCallback={authenticate}
      />
    </StyleWrapper>
  );
};

export default Login;
