import React, { useMemo } from "react";
import { Detail } from "carynhealth-component-library";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/system";

const ShareLimitsStyle = styled("section")(({ theme }) => {
  return {
    ".MuiListItem-root:hover": {
      background: "#fff !important",
      color: "initial !important",
    },
  };
});

const ShareLimits: React.FC<{ data: any }> = (props) => {
  const shareLimitsData = useMemo(() => {
    const { sharingLimit } = props.data;
    let sharingInfo =
      sharingLimit?.map((service: any) => ({
        title: service.programname,
        subTitle: service.amount,
      })) || [];
    return [
      sharingInfo.slice(0, 2),
      sharingInfo.slice(2, 7),
      sharingInfo.slice(7, sharingInfo.length),
    ];
  }, [props]);
  return (
    <ShareLimitsStyle id="pi-visitFee">
      <Grid container spacing={2}>
        {shareLimitsData?.map((sharingDetails: any, index) => (
          <Grid item xs={12} md={3}>
            <Detail list={sharingDetails} key={`sharingLimit${index}`} />
          </Grid>
        ))}
      </Grid>
    </ShareLimitsStyle>
  );
};

export default ShareLimits;
