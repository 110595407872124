import React, { useEffect } from "react";
import { getPlainIData, getRxSimpleShareData } from "./handler";
import { getOs } from "./utility";
import styled from "@mui/system/styled";
import config from "../../config/constants/config";
import { StorageHelpers } from "carynhealth-component-library";

interface RightDrawerProps {
  pharmaShow: boolean;
  planID: string;
  headerTaps?: boolean;
}
const RightDrawerWapper = styled("div")(({ theme }) => {
  return {
    ".rightdrawer": {
      display: "flex",
      alignItems: "center",
      flexWrap: "wrap",
      paddingTop: "5vw",
      padding: "0px 40px",
    },
    ".rightdrawer_card": {
      width: "100px",
      flexDirection: "column",
      justifyContent: "center",
      marginRight: 30,
      marginTop: 40,
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      textAlign: "center !important",
    },
    ".rightdrawer_card_name": {
      fontSize: "16px",
      fontWeight: 500,
      marginTop: "1vw",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: 1.13,
      letterSpacing: 0.5,
      color: "#333",
    },
    ".memberapps_row_container": {
      display: "flex",
      justifyContent: "left",
      alignItems: "center",
    },
    ".memberapps_card_container": {
      margin: "4vw",
      width: "30vw",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
    },

    ".memberapps_apps_img": {
      width: "18vw !important",
      height: "18vw",
    },
    ".rightdrawer_card_img": {
      height: "84px",
      width: "84px",
    },
    ".memberapps_card_name": {
      fontSize: "3.4vw",
      fontWeight: "500",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.13",
      letterSpacing: ".5px",
      color: "#333",
      marginTop: "2vw !important",
    },
  };
});

export const RightDrawer = (props: RightDrawerProps) => {
  let [link, setLink] = React.useState(
    "https://members.myflexcare.com/login/member"
  );
  const [pharmaShow, setPharmaShow] = React.useState(props.pharmaShow);
  let [fliptLink, setFliptLink] = React.useState("");
  const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";

  useEffect(() => {
    getSimpleShareData();
  }, []);

  const getSimpleShareData = () => {
    var os = getOs();
    console.log("==========RIGHT DRAWER IS====", os);
    if (config.APP_NAME === config.BRAND_UHF) {
      switch (os) {
        case "Mac OS":
          setLink("https://members.myflexcare.com/login/member");
          setFliptLink("https://apps.apple.com/us/app/flipt/id1329040340");
          break;
        case "iOS":
          setLink("https://apps.apple.com/us/app/mdlive/id839671393");
          setFliptLink("https://apps.apple.com/us/app/flipt/id1329040340");
          break;
        case "Windows":
          setLink("https://members.myflexcare.com/login/member");
          setFliptLink("https://fliptrx.com/");
          break;
        case "Android":
          setLink(
            "https://play.google.com/store/apps/details?id=com.mdlive.mobile"
          );
          setFliptLink(
            "https://play.google.com/store/apps/details?id=com.gwlabs.flipt&hl=en_US&gl=US"
          );
          break;
        case "Linux":
          setLink("https://members.myflexcare.com/login/member");
          setFliptLink("https://fliptrx.com/");
          break;
        default:
          setLink("https://members.myflexcare.com/login/member");
          setFliptLink("https://fliptrx.com/");
      }
    }
  };

  const opentelemed = () => {
    window.open(link);
  };
  const openFlipt = () => {
    window.open(fliptLink);
  };
  const openCuramLifeCare = () => {
    var os = getOs();
    if (os == "Mac OS" || os == "Windows" || os == "Linux")
      window.open(
        "https://www.universalhealthfellowship.org/curamlife-care-coordination-app/"
      );
    else if (os == "iOS")
      window.open("https://apps.apple.com/us/app/curamlife/id1609101662");
    else if (os == "Android")
      window.open(
        "https://play.google.com/store/apps/details?id=com.curamlife"
      );
  };
  const openCixHealth = () => {
    var os = getOs();
    if (os == "iOS") {
      window.open("https://apps.apple.com/us/app/cix-health/id1477684066");
    } else if (os == "Android") {
      window.open(
        "https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.cixhealth.app"
      );
    } else {
      window.open("https://www.cixhealth.com/");
    }
  };
  const openTeledoc = () => {
    var os = getOs();
    if (os == "iOS") {
      window.open(
        "https://itunes.apple.com/app/apple-store/id656872607?pt=1032497&ct=corporateSite&mt=8"
      );
    } else if (os == "Android") {
      window.open(
        "https://play.google.com/store/apps/details?id=com.teladoc.members&referrer=utm_source%3Dgoogle%26utm_medium%3DCorporateSite%26utm_term%3DFooter"
      );
    } else {
      window.open("https://www.teladoc.com/");
    }
  };
  return (
    <RightDrawerWapper>
      {config.APP_NAME === config.BRAND_UHF ? (
        <div
          className={
            props.headerTaps ? "memberapps_row_container" : "rightdrawer "
          }
        >
          {!getPlainIData(props.planID) && (
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_container"
                  : "rightdrawer_card text-center"
              }
              onClick={() => openCuramLifeCare()}
            >
              <div>
                <img
                  src={
                    require("../../assests/images/uhf/curam-life.svg").default
                  }
                  className={
                    props.headerTaps
                      ? "memberapps_apps_img"
                      : "rightdrawer_card_img"
                  }
                  style={{ width: "90px" }}
                />
              </div>
              <div
                className={
                  props.headerTaps
                    ? "memberapps_card_name"
                    : "rightdrawer_card_name"
                }
                style={{}}
              >
                CuramLife
              </div>
            </div>
          )}
          {pharmaShow && (
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_container"
                  : "rightdrawer_card text-center"
              }
              onClick={() => openFlipt()}
            >
              <div>
                <img
                  src={
                    require("../../assests/images/uhf/pharma_benefits_icon_active.svg")
                      .default
                  }
                  className={
                    props.headerTaps
                      ? "memberapps_apps_img"
                      : "rightdrawer_card_img"
                  }
                />
              </div>
              <div
                className={
                  props.headerTaps
                    ? "memberapps_card_name"
                    : "rightdrawer_card_name"
                }
                style={{ marginTop: "4px !important" }}
              >
                Pharma
              </div>
            </div>
          )}
          {!getPlainIData(props.planID) && (
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_container"
                  : "rightdrawer_card text-center"
              }
              onClick={() => opentelemed()}
            >
              <div>
                <img
                  src={require("../../assests/images/uhf/telemed.svg").default}
                  className={
                    props.headerTaps
                      ? "memberapps_apps_img"
                      : "rightdrawer_card_img"
                  }
                />
              </div>
              <div
                className={
                  props.headerTaps
                    ? "memberapps_card_name"
                    : "rightdrawer_card_name"
                }
              >
                Telemed
              </div>
            </div>
          )}
        </div>
      ) : (
        <div
          className={
            props.headerTaps ? "memberapps_row_container" : "rightdrawer "
          }
        >
          <div
            className={
              props.headerTaps
                ? "memberapps_card_container"
                : "rightdrawer_card text-center"
            }
            onClick={() => openCixHealth()}
          >
            <div>
              <img
                src={require("../../assests/images/innovative/cix.webp")}
                className={
                  props.headerTaps
                    ? "memberapps_apps_img"
                    : "rightdrawer_card_img"
                }
                style={{ width: "90px" }}
              />
            </div>
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_name"
                  : "rightdrawer_card_name"
              }
              style={{}}
            >
              Cix Health
            </div>
          </div>
        
            <div
              className={
                props.headerTaps
                  ? "memberapps_card_container"
                  : "rightdrawer_card text-center"
              }
              onClick={() => openTeledoc()}
            >
              <div>
                <img
                  src={require("../../assests/images/innovative/teladoc.png")}
                  className={
                    props.headerTaps
                      ? "memberapps_apps_img"
                      : "rightdrawer_card_img"
                  }
                  style={{ width: "90px" }}
                />
              </div>
              <div
                className={
                  props.headerTaps
                    ? "memberapps_card_name"
                    : "rightdrawer_card_name"
                }
                style={{}}
              >
                Teledoc
              </div>
            </div>
          
        </div>
      )}
    </RightDrawerWapper>
  );
};
