import {
  Drawer,
  Toolbar,
  Box,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import config from "../../config/constants/config";
import { AuthContext } from "../../context/AuthenticateContext";
import data from "./../../components/Header/data";
import { StorageHelpers } from "carynhealth-component-library";
import { getOs } from "../../components/RightTopMemberTabs/utility";
import { useLocation, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getNetworkName } from "../../components/Dashboard/Contact-Information/handler";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getMenuData } from "../../components/Header/Sidebar/handler";

const drawerWidth = 260;
const headerData = { ...data[config.APP_NAME as keyof typeof data] };
const isIP = config.APP_NAME === config.BRAND_INNOVATION;

const HeaderWrapper = styled("header")(({ theme }) => {
  return {
    "&>.MuiButtonBase-root": {
      paddingLeft: 7,
      paddingBottom: 7,
      backgroundColor: "#fff",
    },
    ".username : hover": {
      backgroundColor: "#fff !important",
    },
  };
});

export const WebSideBar = () => {
  const auth = useContext(AuthContext);
  let [data, setdata] = React.useState([]);
  const [selectedIndex, setSidebarIndex] = React.useState<number>();
  const [sideBarObj, setSideBarObj] = React.useState<any>(null);
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.up("md"));
  const planID: any = getLocalStorage("planId");
  const clientId: any = getLocalStorage("CLIENT_ID");

  useEffect(() => {
    setSidebarData();
    displayMenuListData();
  }, [auth.authenticatedInfo.isAuthenticated, location.pathname]);

  const displayMenuListData = async () => {
    let sortedArray = headerData.sideBarData.listOfSideBar;
    const nwName: any = await getNetworkName(planID);
    
    sortedArray = await getMenuData({
      planId: planID,
      networkName: "ip",
      clientId: clientId,
      DataArray: headerData.sideBarData.listOfSideBar,
    });
    // if (isIP && nwName.provider.toLowerCase().includes("elite")) {
    //   sortedArray = sortedArray.filter((x: any) => x.displayType !== "elite");
    // }
    headerData.sideBarData.listOfSideBar = sortedArray;
    setdata(sortedArray);
  };

  const setSidebarData = () => {
    let drawerIndex = headerData?.sideBarData?.listOfSideBar?.find(
      (x: any) => x.navigate.path == location.pathname
    );
    var index = headerData?.sideBarData?.listOfSideBar?.findIndex(
      (x: any) => x.navigate.path == location.pathname
    );
    if (
      drawerIndex &&
      drawerIndex.component == null &&
      headerData.sideBarData.selectedItem.selectedIndex != drawerIndex?.index
    ) {
      headerData.sideBarData.selectedItem.selectedIndex = drawerIndex?.index;
    }
    setSidebarIndex(index);
  };

  const sidebarCallback = useCallback((data: any, index: number) => {
    const object: any = headerData.sideBarData.listOfSideBar[index];
    setSidebarIndex(-1);
    if (object.text === "Sign Out") {
      setSidebarIndex(index);
      setSideBarObj({});
      return logout();
    } else if (object?.navigate?.newTab) {
      setSidebarIndex(0);
      setSideBarObj({});
      return window.open(findProvider(object?.navigate?.path, object?.text));
    } else if (object?.component) {
      setSideBarObj({ ...object });
      setSidebarIndex(index);
      console.log(object?.component);
    } else {
      setSidebarIndex(index);
      setSideBarObj({});
      navigate(object?.navigate?.path);
    }
  }, []);

  const logout = useCallback(() => {
    auth.resetAuthenticateInfo();
    window.location.href = "/";
  }, []);

  const findProvider = (path: string, text: string) => {
    if (config.APP_NAME == config.BRAND_INNOVATION) {
      let url = innovativeFindAProvider(text);
      return url;
    }
    return path;
  };

  const innovativeFindAProvider = (text: string) => {
    const NETWORK_NAME = StorageHelpers.getLocalStorage("NETWORK_NAME") || "";
    if (text === "Find a Provider") {
      if (NETWORK_NAME.toLowerCase().includes("ranger"))
        return "https://providerlocator.firsthealth.com/2003";
      if (NETWORK_NAME.toLowerCase().includes("value"))
        return "https://www.multiplan.com/webcenter/portal/ProviderSearch";
        if (NETWORK_NAME.toLowerCase().includes("dental"))
        return "http://www.aetna.com/dentaladministrators";
    } else {
      if (text === "Cix Health") {
        return openCixHealth();
      }
      if (text === "Teladoc") {
        return openTeledoc();
      }
    }
    return "";
  };

  const openCixHealth = () => {
    var os = getOs();
    if (os == "iOS") {
      return "https://apps.apple.com/us/app/cix-health/id1477684066";
    }
    if (os == "Android") {
      return "https://play.google.com/store/apps/details?gl=US&hl=en_US&id=com.cixhealth.app";
    }
    return "https://www.cixhealth.com/";
  };
  const openTeledoc = () => {
    var os = getOs();
    if (os == "iOS") {
      return "https://itunes.apple.com/app/apple-store/id656872607?pt=1032497&ct=corporateSite&mt=8";
    }
    if (os == "Android") {
      return "https://play.google.com/store/apps/details?id=com.teladoc.members&referrer=utm_source%3Dgoogle%26utm_medium%3DCorporateSite%26utm_term%3DFooter";
    }
    return "https://www.teladoc.com/";
  };

  if (sideBarObj?.component) {
    const Comp = sideBarObj.component;
    sideBarObj.component = React.cloneElement(Comp, {
      onClick: () => closePopup(),
    });
  }

  function closePopup() {
    setSideBarObj(null);
    setSidebarData();
  }

  if (
    config.APP_NAME !== config.BRAND_INNOVATION ||
    !auth.authenticatedInfo.isAuthenticated ||
    !showText
  )
    return <></>;
  return (
    <HeaderWrapper>
      <Drawer
        variant="permanent"
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: {
            width: drawerWidth,
            boxSizing: "border-box",
            zIndex: 3,
          },
        }}
      >
        <Toolbar />
        <Box
          sx={{
            overflow: "hidden",
            overflowY: "scroll",
            "&::-webkit-scrollbar": {
              width: 9,
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "darkgrey",
            },
          }}
        >
          <List className="username">
            <ListItem>
              <ListItemIcon style={{ minWidth: "32px" }}>
                <AccountCircleIcon sx={{ color: "black" }} />
              </ListItemIcon>
              <ListItemText
                sx={{
                  ".MuiListItemText-root": {
                    width: "202px",
                  },
                }}
                disableTypography
                primary={`HELLO ${auth.authenticatedInfo.name}`}
              />
            </ListItem>
          </List>
          <List
            sx={{
              "&& .Mui-selected, && .Mui-selected:hover": {
                backgroundColor: "#e5e7ea !important",
                borderRight: "5px solid #162242",
                "&, & .MuiListItemIcon-root": {
                  color: "black",
                },
              },
              "& .MuiListItemButton-root:hover": {
                backgroundColor: "#e5e7ea !important",
                borderRight: "5px solid #162242",
                "&, & .MuiListItemIcon-root": {
                  color: "black",
                },
              },
            }}
          >
            {data.map((item: any, index: number) => (
              <ListItem key={item} disablePadding>
                <ListItemButton
                  selected={selectedIndex === index}
                  onClick={() => sidebarCallback(item, index)}
                >
                  <ListItemIcon>
                    <img src={item.icon} width="22px" alt={item.text} />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
      {sideBarObj?.component && sideBarObj.component}
    </HeaderWrapper>
  );
};
