import { styled } from "@mui/system";
import { Modal } from "@mui/material";
export default styled("div")(({ theme }) => {
  return {
    ".background-full-Chat": {
      backgroundColor: "#fff",
      height: "100%",
      overflow: "hidden",
    },
    whiteBackground: {
      backgroundColor: "#fff",
      height: "100vh",
    },
    ".chatbodyMobile": {
      flexGrow: 1,
      padding: 20,
      overflowY: "scroll",
      height: "88%",
      width: "100%",
      paddingLeft: 6,
      paddingRight: 6,
      paddingTop: 70,
      position: "fixed",
      backgroundColor: "white",
    },
    ".inputBoxChat": {
      bottom: "0",
      padding: "12px 16px",
      margin: "14px 0px 14px 16px",
      borderRadius: 40,
      paddingRight: 20,
      paddingLeft: 20,
      zIndex: 10000,
      position: "fixed",
      width: "83%",
    },
    ".search-icon-mobile": {
      position: "relative",
      right: "-14px",
      top: "27px",
      zIndex: "8",
    },
    ".Bottom-Controls-BG": {
      width: "100vw",
      position: "fixed",
      overflow: "hidden",
      height: "79px",
      zIndex: "10000000000",
      bottom: "-1px",
      backgroundColor: "#e4e4e4",
      flexDirection: "row",
      display: "flex",
    },
    ".web_Chat_container": {
      // maxHeight: "89vh",
      width: "98vw",
      padding: "5px 118px 40vh 40px",
      objectFit: "contain",
    },
    ".chatbody": {
      flexGrow: 1,
      padding: "20px",
      height: "auto",
      overflowY: "scroll",
      marginTop: "20px",
    },
    ".web_welcome_back": {
      marginBottom: "-1.2vw",
      marginLeft: "-24vw",
      maxWidth: "150%",
      width: "15vw",
    },
    ".web_top_usernameChat": {
      textTransform: "uppercase",
      width: "100% !important",
      height: "50px",
      fontFamily: "'Anton', sans-serif",
      fontSize: "4vw",
      fontWeight: "bold",
      fontStretch: "normal",
      fontStyle: "normal",
      lineHeight: "1.1",
      letterSpacing: "normal",
      color: "#000000",
    },
    ".betaLogo": {
      width: "12%",
      marginBottom: "-20px",
    },
    ".dismissable-info": {
      fontFamily: "Roboto",
      textAlign: "left",
      flexGrow: "0",
      padding: "11.8px 41px 17.1px 12px",
      borderRadius: "5px",
      backgroundColor: "#8c827a",
      fontSize: "15px",
      lineHeight: "1.33",
      fontWeight: "400",
      letterSpacing: "normal",
      color: "#f2f2f2",
      position: "relative",
      marginBottom: "10px",
      marginTop: "14px",
    },
  };
});

export const Div = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    top: "50%",
    left: "40%",
    transform: "translate(-50%, -50%)",
    width: 400,
    border: "none",
  };
});

export const ModalID = styled(Modal)(({ theme }) => {
  return {
    display: "flex",
  };
});
export const Iframe = styled("iframe")(({ theme }) => {
  return {
    width: process.env.REACT_APP_WIDTH,
    height: process.env.REACT_APP_HEIGHT,
    border: "none",
    margin: "auto",
  };
});
export const Div1 = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "flex-end",
    background: "#f1f1f1",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: process.env.REACT_APP_WIDTH,
  };
});

export const DivAIKB = styled("div")(({ theme }) => {
  return {
    position: "absolute",
    top: "40%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 750,
    border: "none",
    background: "#fff",
  };
});
export const DivAIKB1 = styled("div")(({ theme }) => {
  return {
    display: "flex",
    justifyContent: "space-between",
    color: "#fff",
    background: "#533178",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    width: 750,
  };
});
