import { Buffer } from "buffer";

export const awsConfig = {
  aws_project_region: process.env.REACT_APP_AWS_REGION,
  aws_cognito_identity_pool_id:
    process.env.REACT_APP_AWS_cognito_identity_pool_id,
  aws_user_pools_id: process.env.REACT_APP_AWS_user_pools_id,
  aws_user_pools_web_client_id:
    process.env.REACT_APP_AWS_user_pools_web_client_id,
  oauth: {},
};

export const parseJwt = (token: string = "") => {
  let jsonPayload: any = Buffer.from(token.split(".")[1], "base64");
  jsonPayload = JSON.parse(jsonPayload);
  localStorage.setItem("userMail", jsonPayload.email);
  localStorage.setItem("subscriberName", jsonPayload.name);
  localStorage.setItem("CONTACT_NUMBER", jsonPayload.phone_number);
  localStorage.setItem("cognitoUsername", jsonPayload["cognito:username"]);
  jsonPayload.isAuthenticated = true;
  return jsonPayload;
};
