import { apiService } from "carynhealth-component-library";
import config from "../../config/constants/config";

export const encryptMemberId = async (encodeString: any) => {
  let obj = {
    memberId: `memberid=${localStorage.getItem(
      "sourceid"
    )}&type=&email=${localStorage.getItem("userMail")}`,
  };
  let request = {
    URL: config.URLS.baseAPI + "v1/csrportal/idencryption",
    data: obj,
    brand: config.BRAND_IP_AEC,
  };
  try {
    var response = await apiService.default.post(request);
    return response.data;
  } catch (err) {
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    console.log("Internal server error.");
  }
};
