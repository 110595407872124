import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    ".hipaaHeader": {
      backgroundColor: "#ffff",
      height: 70,
      position: "fixed",
      width: "100%",
      top: 0,
      zIndex: 5,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: " 0 20px",
      ".hipaa_netwell_logo": {
        width: `20%`,
        marginLeft: 10,
        objectFit: "contain",
      },
    },
    ".progInfoContainer": {
      margin: "0 5.041vw",
      paddingTop: 30,
      paddingBottom: 46,
      ".Back_page_netwell": {
        fontSize: 20,
        color: "#4782c4",
        width: "6.449vw",
        height: 24,
        margin: "0.222vw 0 0 0.371vw",
        fontFamily: "Lato",
        fontWeight: 600,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: 1.44,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        ".MuiTypography-root": {
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
        },
      },
      ".commonWrap": {
        marginTop: "4.32vh",
        backgroundColor: "#ffff",
        padding: "1.4vw",
        borderRadius: "4px 4px 0 0",
        ".progInfoTitle": {
          color: "rgb(22, 34, 66)",
          marginBottom: "2.9vw",
          fontFamily: "Lato",
          fontSize: 42,
          fontWeight: 300,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1,
          letterSpacing: "normal",
          textAlign: "left",
          textTransform: "uppercase",
          marginTop: 0,
        },
        text: {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.5,
          letterSpacing: "normal",
          textAlign: "left",
          color: "rgba(0, 0, 0, 0.87)",
        },
        ".enrolledTableClass": {
          paddingTop: "3%",
          marginRight: "6%",
          minHeight: "50vh",
          marginLeft: 5,
          ".tableContainer": { boxShadow: "none!important" },
          ".MuiPaper-rounded ": {
            borderRadius: 4,
          },
        },
        ".MuiPaper_root": {
          color: "rgba(0, 0, 0, 0.87)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundColor: "#fff",
        },
        ".MuiTableContainer-root": {
          width: "100%",
          overflowX: "auto",
          minHeight: "32vh",
          marginBottom: 20,
          overflowY: "auto !important",
        },
        ".MuiTable-stickyHeader": {
          borderCollapse: "separate",
        },
        ".MuiTableRow-root": {
          borderCollapse: "collapse",
        },
        ".MuiTableCell-root": {
          padding: "2px !important",
          borderBottom: "none",
        },
        ".innerTh": {
          color: "#454d58",
          padding: "15px 8px !important",
          fontSize: 14,
          textAlign: "left",
          fontFamily: "Roboto",
          letterSpacing: 1.12,
          fontWeight: "bold",
        },
        ".TableCell": {
          color: "#000",
          padding: "15px 8px !important",
          fontSize: "16px",
          textAlign: "left",
          fontFamily: "Roboto",
          fontWeight: "400",
          borderBottom: "none",
          letterSpacing: "normal",
        },
        ".statusCell": {
          width: "max-content",
          height: 30,
          borderRadius: 4,
          backgroundColor: "rgb(254, 237, 236)",
          color: "rgb(200, 55, 45)",
          textAlign: "center",
          paddingLeft: 5,
          paddingRight: 5,
          paddingTop: 5,
          fontSize: 12,
          fontWeight: "normal",
        },
        ".programInfoButton": {
          color: "rgb(71, 130, 196)",
          borderColor: "rgb(71, 130, 196)",
          fontSize: 15,
          cursor: "pointer",
          padding: 5,
          fontWeight: 600,
          textAlign: "center",
          backgroundColor: "#fff",
          width: "auto",
          borderRadius: 30,
          border: "2px solid #41b5c2",
          fontFamily: "Lato",
          outline: "none !important",
        },
        ".MuiTableCellclassChid": {
          borderRadius: "4px",
          border: "solid 1px #c6cacc",
        },
        ".pending": {
          backgroundColor: "#feedec",
          color: "#c8372d",
        },
        ".completed": {
          backgroundColor: "#edf6ee",
          color: "#37833b",
        },
        ".inProgress": {
          backgroundColor: "#fef3e7",
          color: "#ce6b00",
        },
        ".textFieldTable": {
          width: "100%",
          color: "#19191d",
          borderRadius: "4px",
          background: "none",
          backgroundColor: "#f1f1f1 !important",
        },
      },
      ".progInfoFooter": {
        borderTop: "none",
        ".leftSectionbuttons": {
          ".MuiButtonBase-root": {
            width: "180px",
            padding: "14px 30px",
            borderRadius: "100px",
            outlineWidth: "0",
            border: "none",
            color: "#fff",
            fontFamily: "Oswald,sans-serif",
            fontSize: "24px",
            backgroundColor: "#4782c4",
          },
          ".Mui-disabled": {
            backgroundColor: "#e3e3e3 !important",
          },
        },
      },
      ".maindiv": {
        marginTop: 20,
        marginBottom: 20,
        ".title": {
          fontWeight: "bold",
          fontFamily: "Roboto,sans-serif",
          color: "rgba(0, 0, 0, 0.87)",
        },
        ".desc": {
          fontWeight: 350,
          marginLeft: 15,
          fontFamily: "Roboto,sans-serif",
          color: "rgba(0, 0, 0, 0.87)",
        },
        ".MuiRadio-root": {
          backgroundColor: "transparent",
          marginLeft: 50,
          color: "#420045",
        },
        ".MuiCheckbox-root": {
          backgroundColor: "transparent",
          marginLeft: 85,
          color: "#420045",
        },
        ".checkBoxDiv": {
          ".MuiFormControlLabel-label": {
            fontFamily: "Roboto, Arial, Helvetica, sans-serif",
            fontSize: "15px !important",
          },
          "checkbox-Text": {
            ".MuiFormControl-root": {
              ".MuiInputBase-root": {
                borderRadius: "none !important",
              },
            },
          },
        },

        ".MuiFormControlLabel-label": {
          fontSize: "13px !important",
        },
        ".displayOr": {
          position: "absolute",
          top: 54,
          fontWeight: 300,
        },
      },
      ".FullNameInput": {
        div: {
          width: "100%",
        },
        " .MuiFilledInput-root": {
          backgroundColor: "rgba(0, 0, 0, 0.09)",
          borderTopLeftRadius: "4px !important",
          borderTopRightRadius: "4px !important",
        },
        ".MuiFormControl-root": {
          width: "100%",
        },
        ".MuiSelect-select,.MuiInputBase-input ": {
          padding: "25px 12px 8px 12px",
        },
      },
      ".signatureInput": {
        ".MuiRadio-root": {
          backgroundColor: "transparent",
          color: "#420045",
        },
        ".MuiFormControlLabel-label": {
          fontSize: "13px !important",
        },
      },
      ".sigCanvas": {
        backgroundColor: "#e1e1e3",
        marginBottom: 10,
        marginTop: 8,
      },
      ".bottomBtn": {
        ".MuiButtonBase-root": {
          height: 40,
          fontSize: 13,
          marginRight: 11,
          fontFamily: "Roboto, Arial, Helvetica, sans-serif",
          color: "#ffffff",
          width: 120,
          boxShadow:
            "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)",
        },
        ".Mui-disabled": {
          backgroundColor: "#e3e3e3 !important",
        },
      },
      ".myStyle": {
        fontFamily: "Kalam",
        width: "100%",
        minHeight: 100,
        height: "max-content",
        background: "#e1e1e3",
        padding: 10,
        marginBottom: 10,
        marginTop: 8,
      },

      ".state": {
        fontSize: 50,
        width: "100%",
        whiteSpace: "nowrap",
        overflow: "hidden",
      },
    },
    [theme.breakpoints.down(475)]: {
      ".progInfoContainer": {
        margin: 0,
        padding: 0,
        ".Back_page_netwell": {
          display: "none",
        },
        ".progInfoTitle": {
          display: "none",
        },
      },
    },
  };
});
