const enableFeatures = Object.freeze({
  UHF: {
    login: true,
    dashboard: {
      page: true,
      "popup-check-out-member-apps": true,
      "member-apps": true,
      "ai-assistant": true,
      "my-notifications-card": true,
      "announcements-notices-card": true,
      "documents-card": true,
      "w ellness-card": true,
      "ID-cards-card": true,
      "program-information-card": true,
      "my-Needs-share-request-card": true,
      "my-transactions-card": true,
      "find-a-provider-card": true,
      "health-questionnaire-card": true,
      faqs: true,
      "contact-information-card": true,
      "hippa-privacy-authorization-card": false,
    },
    hippa: { page: false },
  },
  NETWELL: {
    login: true,
    dashboard: {
      page: true,
      "popup-check-out-member-apps": true,
      "member-apps": false,
      "ai-assistant": false,
      "my-notifications-card": true,
      "announcements-notices-card": true,
      "documents-card": true,
      "w ellness-card": false,
      "ID-cards-card": true,
      "program-information-card": true,
      "my-Needs-share-request-card": true,
      "my-transactions-card": true,
      "find-a-provider-card": true,
      "health-questionnaire-card": false,
      faqs: false,
      "contact-information-card": true,
      "hippa-privacy-authorization-card": true,
    },
    hippa: { page: true },
  },
  INNOVATION: {
    login: true,
    dashboard: {
      page: true,
    },
    hippa: { page: false },
  },
});

export default enableFeatures;
