import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    ".The-UHF-Curam-Wellness-Center-1": {
      lineHeight: 1.29,
      fontWeight: 400,
      letterSpacing: 0.15,
      color: "rgb(79, 79, 79)",
      fontSize: "1vw",
      fontStretch: "normal",
      fontStyle: "normal",
      textAlign: "left",
      fontFamily: "Roboto",
      padding: 16,
      ".text-style-2": {
        fontWeight: 500,
        a: {
          color: "#41b5c2 !important",
          textDecoration: "none !important",
        },
        color: "#41b5c2 !important",
        cursor: "pointer",
        ":hover": {
          color: "#41b5c2 !important",
        },
      },
      ".text-style-1": {
        fontWeight: 600,
        lineHeight: 1.29,
        letterSpacing: 0.15,
        color: "#4f4f4f",
        marginTop: 15,
      },
      ".unfListItem": { marginLeft: "-15px", marginTop: "10px" },
      ".CURAM_sub": {
        color: "black",
        fontWeight: "600",
      },
    },

    [theme.breakpoints.down(770)]: {
      ".The-UHF-Curam-Wellness-Center-1": {
        fontSize: ".875em",
      },
    },
  };
});
