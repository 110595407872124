import React, { useEffect } from "react";
import { Checkbox, Grid } from "@mui/material";
import { useState } from "react";
import messages from "../../config/constants/messages";
import SampleTextField from "../TextField/sampleTextField";
import { ComponentWrapper } from "./style";
import { Dialog } from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import config from "../../config/constants/config";

export const PrivacyPolicy = (props: any) => {
  const [privacyPolicy, setPrivacyPolicy] = useState(
    props.ViewMode ? props?.familyData?.privacyPolicy : false
  );
  const [medicalProvider, setMedicalProvider] = useState(
    props.ViewMode ? props?.familyData?.authorize : false
  );
  const [fullName, setFullName] = useState(
    props.ViewMode ? props?.familyData?.fullName : ""
  );
  const [showPopup, setShowPopup] = useState(false);
  const [popupContant, setPopupContant] = useState("");
  const [popupTitle, setPopupTitle] = useState("");
  const [popupEvent, setPopupEvent] = useState("");

  useEffect(() => {
    props.disableNext(true);
    props.disableFinsh(true, 4);
  }, []);

  useEffect(() => {
    checkForFinish();
  }, [privacyPolicy, medicalProvider, fullName]);

  const handleChange = (event: any) => {
    setPopupEvent(event);
    if (event === "privacyPolicy") {
      setPopupContant(messages.uhf.MODAL_POPUP1.Data);
      setPopupTitle(messages.uhf.MODAL_POPUP1.TITLE);
    } else {
      setPopupContant(messages.uhf.MODAL_POPUP2.Data);
      setPopupTitle(messages.uhf.MODAL_POPUP2.TITLE);
    }
    setShowPopup(true);
  };

  const handleClick = (value: any) => {
    if (value.label === "CANCEL") {
      if (popupEvent === "privacyPolicy") {
        setPrivacyPolicy(false);
        props.familyData.privacyPolicy = false;
      } else {
        setMedicalProvider(false);
        props.familyData.authorize = false;
      }
    } else {
      if (popupEvent === "privacyPolicy") {
        setPrivacyPolicy(true);
        props.familyData.privacyPolicy = true;
      } else {
        setMedicalProvider(true);
        props.familyData.authorize = true;
      }
    }
    setShowPopup(false);
  };

  const textChangeHandler = (value: string) => {
    if (value) {
      setFullName(value);
      props.familyData.fullName = value;
    } else {
      setFullName("");
    }
  };

  const checkForFinish = () => {
    if (privacyPolicy && medicalProvider && fullName) {
      props.disableFinsh(false, 4);
    } else {
      props.disableFinsh(true, 4);
    }
  };

  return (
    <Grid container spacing={2}>
      {showPopup && (
        <Dialog
          brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
          show={true}
          body={() => (
            <div
              dangerouslySetInnerHTML={{
                __html: popupContant,
              }}
            />
          )}
          CloseIcon={true}
          // CloseIconColor={'#fff'}
          title={popupTitle}
          buttons={[
            {
              label: "CANCEL",
              size: "large",
              variant: "text",
              type: "button",
              className: "CANCEL",
            } as CustomButtonProps,
            {
              label: "ACCEPT",
              size: "large",
              variant: "text",
              type: "button",
              className: "ACCEPT",
            } as CustomButtonProps,
          ]}
          titleBackgroundColor={{ backgroundColor: "rgb(51, 175, 176)" }}
          handleCallback={(data: any) => {
            handleClick(data);
          }}
        />
      )}
      <Grid item lg={7}>
        <div className="custom-subtitle">
          {messages.uhf.HealthQuesInstruction}
        </div>
        <Grid container spacing={2}>
          <Grid item display="flex" alignItems="center" xs={12}>
            <Checkbox
              checked={privacyPolicy}
              disabled={props.ViewMode}
              sx={{
                color: "#533278",
              }}
              inputProps={{
                "aria-label": "secondary checkbox",
              }}
              onChange={() => handleChange("privacyPolicy")}
              name="privacyPolicy"
            />
            <div
              className="PrivacyPolicyDiv"
              dangerouslySetInnerHTML={{
                __html: messages.uhf.PrivacyPolicy,
              }}
              style={{ pointerEvents: props.ViewMode ? "none" : "auto" }}
              onClick={() => handleChange("privacyPolicy")}
            />
          </Grid>
          <Grid item display="flex" alignItems="center" xs={12}>
            <Checkbox
              checked={medicalProvider}
              disabled={props.ViewMode}
              sx={{
                color: "#533278",
              }}
              inputProps={{
                "aria-label": "secondary checkbox",
              }}
              onChange={() => handleChange("medicalProvider")}
              name="medicalProvider"
            />
            <div
              className="PrivacyPolicyDiv"
              dangerouslySetInnerHTML={{
                __html: messages.uhf.MedicalProvider,
              }}
              style={{ pointerEvents: props.ViewMode ? "none" : "auto" }}
              onClick={() => handleChange("medicalProvider")}
            />
          </Grid>
          <Grid item display="flex" alignItems="center" xs={12}>
            <SampleTextField
              setChild={(val) => textChangeHandler(val)}
              reqFlag={true}
              label={"Approve submission by typing in your full name"}
              value={fullName}
              disable={props.ViewMode}
              fieldType="fullname"
              errMsg={"Enter valid full name"}
              helperMsg={fullName ? "" : "Full name required"}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={5}>
        <Grid item xs={10}>
          <div className="custom-subtitle" style={{ marginTop: "10px" }} />
          <div
            className="description PrivacyDesc"
            dangerouslySetInnerHTML={{
              __html: messages.uhf.HealthQuesNeedThis,
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
