const messages = Object.freeze({
  uhf: {
    questionaryIncomplete: `Hello! We notice that your health questionnaire is incomplete. Would you like to complete it now?`,
    HealthQuesNeedThis: `Your medical questionnaire is now ready to be submitted. Review the terms and indicate your acceptance of the same.`,
    HealthQuesInstruction: `Review & accept terms`,
    PrivacyPolicy: `*I agree with the <span class='PrivacyPolicy'> privacy policy.</span>`,
    MedicalProvider: `*I authorize <a class='PrivacyPolicy'> Universal Health Fellowship to contact my Medical Provider.</a>`,
    checkoutboldtext: `Check out Member Apps`,
    NEEDHELP_MSG: `Chat with our Health Share Representative \n or call 1 (877)-987-1233`,
    tooltipText: ` Talk to a doctor 24/7 for $0.
    Register <a href=https://members.myflexcare.com/register/member target="_blank"><u>here</u></a> now for FlexCare `,
    uhfCuram_first: `The UHF / Curam Wellness Center can support UHF members and their
    families with easy access to: nutritional and dietary programs,
    financial assistance application support, hospital fee estimate
    support `,
    uhfCuram_secound: ` that helps you customize the best caregiver support for you and your
    family, a digital health assistant app that can help you access your
    essential health data, and access to reduced-rate virtual physical
    therapy sessions. All UHF / Curam Wellness Center services are
    available to UHF members at no additional cost to them. Read more
    about it `,
    uhfCuram_Ques: ` Can we hook you up with the Wellness Center?`,
    unfCuram_Ques_sub: ` Call a CuramLife rep at `,
    unfCuram_AT_CURAM: ` 888-AT-CURAM `,
    unfCuram_AT_CURAM_sub: ` (888-282-8726 – Mon-Fri, 8:30am-5:00pm ET) `,
    unfCuram_Email: `uhfmembers@curamlife.com `,
    unfCuram_call: ` or Select a convenient time where we can call you `,
    MODAL_POPUP1: {
      TITLE: "Privacy Policy",
      Data: `<p><b>Privacy Statement</b></p>
      <p>This Privacy Statement is effective May 1, 2019.</p>
      <p>Universal Health Fellowship understands that when our clients, partners and other individuals provide personal data to us, they place their trust in us. We take this trust seriously and are committed to respecting each individual’s privacy and protecting the personal data we handle.
      This Privacy Statement describes our privacy practices to help you understand what personal data we collect, use, share and transfer and to inform you about the choices you can make regarding your personal data.</p>
      <p>This Privacy Statement applies to the Universal Health Fellowship websites, domains, or applications that link to this Statement. We may supplement this Privacy Statement with a privacy statement for a specific product, service or website. When we do, the more specific statement is applicable.</p>
      <p><b>How We Collect And Use Personal Data</b></p>
      <p>We collect and use personal data that you provide in order to operate our business, provide our products and services, send marketing and other communications, and comply with applicable laws and regulations. In addition to the data you provide to us directly, we may also process personal data about you that we receive from our clients or third parties.</p>
      <p>The types of personal data we process will depend on the purpose, including:</p>
      <p>Provide products and services – contact details (such as name, email, address, company name, phone number and other information necessary) to provide services to our clients and our client’s customers, including providing product support updates;</p>
      <p>Improve our products and services – contact details to conduct quality controls and evaluate the performance of our products and services, including conducting customer satisfaction surveys;</p>
      <p>Conduct due diligence – contact details and publicly available information about financial or reputational status of a client or third party supplier/partner;</p>
      <p>Generate sales and marketing leads – contact details, marketing preferences, publicly available social media information to maintain a client relationship management database and send relevant newsletters, solution updates, event notifications and other marketing communications;</p>
      <p>Manage relationships with our clients, suppliers and partners – contact details and payment information in order to execute contracts, generate invoices and make payments; and</p>
      <p>Respond to inquiries or requests for information – contact details for electronic communication.</p>
      <p>When we receive personal data about you from our clients in order to provide our services, Universal Health Fellowship processes the personal data as instructed by our clients and in accordance with our contractual obligations.</p>
      <p>Our clients are responsible for complying with regulations or laws regarding notice, disclosure, and/or obtaining consent prior to transferring the personal data to us for processing.</p>
      <p><b>How We Protect Your Personal Data</b></p>
      <p>We use reasonable security procedures and technical and organizational measures to protect against accidental or unlawful destruction, loss, disclosure or use of personal data we handle. We limit access to and use of your personal data to authorized persons and trusted third parties who have a reasonable need to know the information in order to perform our services and business operations and who are bound by confidentiality obligations.</p>
      <p><b>How Long We Retain Personal Data</b></p>
     <p>We retain your personal data only for as long as is necessary to fulfill the purpose 
     for which the data was collected from you and in consideration of and compliance with 
     applicable legal or regulatory requirements to maintain the data for legitimate purposes. 
     For example, where required by law for audits or accounting requirements, to enforce our
      agreements or handle disputes. When personal data is no longer needed for the purpose 
      it was collected or processed or to comply with a legal obligation, we securely destroy it.</p>
      <p><b>How We Share Your Personal Data</b></p>
      <p>We do not sell or otherwise disclose personal data about our website visitors or others that interact with 
      Universal Health Fellowship or our products or services, except as described herein.</p>
      <p>We may share your personal data with authorized Universal Health Fellowship personnel with 
      a need to know the information in order to process the personal data for the purpose we collected it. 
      We also share personal data with third parties who are acting on our behalf in order to provide the 
      products or services you request or to support our relationship with you. These third parties are 
      not authorized by us to use or disclose the information except as necessary to perform services 
      on our behalf pursuant to a contractual obligation or to comply with legal requirements. 
      Universal Health Fellowship requires such third parties to comply with applicable data 
      protection and privacy laws and agree to implement and maintain appropriate technical and 
      organizational security measures to safeguard the personal data.</p>
      <p>Our sharing may include:</p>
      <p>With any of our Universal Health Fellowship subsidiaries and trusted third party 
      suppliers/partners in order to perform our services or business operations;</p>
      <p>With our professional advisors and insurers to run our business;</p>
      <p>With legal authorities, government agencies, law enforcement authorities or other 
      government officials when required by applicable laws or regulations or or pursuant to legal process 
      (including for national security purposes);when we believe disclosure is necessary or appropriate to prevent 
      physical harm or financial loss or in connection with an investigation of suspected or actual fraud or illegal 
      activity; or when we believe that disclosure is necessary to protect our rights, protect your safety or the 
      safety of others;</p>
      <p>With appropriate third parties in connection with the sale, transfer or financing of all or part of a 
      Universal Health Fellowship business or its assets, including any such activities associated with a bankruptcy 
      proceeding.</p>
      <p><b>How We Treat Personal Data From Individuals Outside The U.S.</b></p>
      <p>Universal Health Fellowship is a U.S. enterprise based in the U.S. Our products, services, and offerings are 
      only available to parties within the U.S., and may be subject to further geographic or other limitations within 
      the U.S. While we cannot prevent individuals from outside the U.S. from providing contact information to Universal
       Health Fellowshipvia our contact forms, we will not knowingly use information from parties outside the U.S., and 
       will periodically purge any individual contact data known to be from individuals outside the U.S. from our 
       databases.</p>
      <p><b>How We Respect Your Privacy In Marketing</b></p>
      <p>Universal Health Fellowship uses various means of communication and tools to market its products and services. 
      We provide commercial marketing email to persons who consent or where we have a legitimate interest and it is 
      otherwise permissible under applicable law. You may opt-out of our commercial marketing emails by using the 
      “Unsubscribe” or “Opt-out” link in the commercial email or by contacting us at 
      <a href='mailto:info@UniversalHealthFellowship.org.' style="color: rgb(83, 50, 120);text-decoration: underline;
        font-weight: bold;text-decoration: none;">info@UniversalHealthFellowship.org.</a></p>
      <p>We may strive to make your Universal Health Fellowship experience the best it can be by using various tools 
      that provide us with information about how you and other visitors interact with our website so that we can tailor 
      your Universal Health Fellowship experience and improve our web properties. These tools may include Cookies and 
      other automatic data collection tools.</p>
      <p><b>How To Request Access To Your Personal Data</b></p>
      <p>We rely on you to provide accurate, complete and current personal data to us.</p>
      <p>If you need to correct or update the personal data you provided to us, we will respond in a timely manner to 
      all reasonable requests to access, correct or delete your personal data. Requests and questions can be 
      submitted to <a href='mailto:info@UniversalHealthFellowship.org.' style="color: rgb(83, 50, 120);text-decoration: underline;
      font-weight: bold;text-decoration: none;">info@UniversalHealthFellowship.org.</a></p>
      <p><b>How To Contact Us</b></p>
      <p>If you have any questions or comments regarding this statement or our privacy practices, or any concerns 
      regarding a possible violation of this statement, our practices or any applicable privacy law please contact 
      Universal Health Fellowship at
      <a href='mailto:info@UniversalHealthFellowship.org.' style="color: rgb(83, 50, 120);text-decoration: underline;
        font-weight: bold;text-decoration: none;">info@UniversalHealthFellowship.org</a>
      and we will promptly respond.</p>
      <p><b>Our mailing address is:</b></p>
      <p>Universal Health Fellowship, Inc.</p>
      <p>Attn: Privacy Administrator</p>
      <p>4555 Mansell Road, Suite 300,</p>
      <p>Alpharetta, GA 30022</p>
      <p>United States</p>
      <p><a target="_blank" href='https://www.universalhealthfellowship.org/' style="color: rgb(83, 50, 120);text-decoration: underline;
      font-weight: bold;text-decoration: none;">www.UniversalHealthFellowship.org</a></p>
      <p><b>Links To Other Websites</b></p>
      <p>As a convenience to our website visitors, we sometimes provide links to other websites. These linked sites 
      are not under the control of Universal Health Fellowship and we are not responsible for their content. You should 
      review the privacy statements of any linked website to understand their privacy practices before using the site.</p>
      <p><b>Children</b></p>
      <p>It is not our intent to collect personal data from children under the age of consent. Our websites are not 
      designed to attract children and we request that children under the age of consent not submit personal data to us 
      through our websites.</p>
      <p><b>Updates To Our Privacy Statement</b></p>
      <p>We may update this Privacy Statement from time to time. When we update this statement, we will also update the
       date at the top. Only the current statement is effective, so please review it periodically.</p>`,
    },
    MODAL_POPUP2: {
      TITLE: "Important Privacy Notice",
      Data: `<p><b>Authorization for Universal Health Fellowship & CarynHealth to Contact Medical Provider</b></p>
      <p>To Whom It May Concern:</p>
      <p>I, a covered individual (or the parent or legal guardian for my minor) under <b>Universal HealthShare</b>
      (the “Program”), hereby request, authorize and grant authority to Universal Health Fellowship (“UHF”), or its 
      appointed health plan manager CarynHealth Solutions, LLC (“CarynHealth”), to contact and act on my behalf in 
      dealing with my physician and medical providers (the “Facility”) and any other interested party in reference to 
      charges for treatment, care and services provided in connection with the medical need and medical billing (the 
        “Medical Bill”), including without limitation charges that the Program has determined exceed the Allowable 
        Claim Limits under the terms of the Program Document.</p>
      <p>Additionally, by my acceptance, I acknowledge that I have authorized the Facility to release any records and information related to the Medical Bill, including Protected Health Information (PHI), to UHF and CarynHealth. I am requesting that such Protected Health Information be disclosed under this authorization, as permitted by 164.508(1)(iv) of the privacy regulations issued pursuant to the Health Insurance Portability and Accountability Act (“HIPAA Privacy Rule”). I have retained a copy of this authorization for my records. I understand that I have the right to revoke this authorization in writing, at any time, by sending a written statement of the revocation to UHF and CarynHealth. Unless revoked, this release will remain in effect and valid for one year from the date of this authorization.</p>`,
    },
    PROGRAM_DESCRIPTION: {
      UHSStandardPrograms: `UHS 1-6 (UHS1, UHS2, UHS3, UHS4, UHS5, and UHS6) are health cost sharing programs where you and
    your fellow members of Universal Health Fellowship share eligible medical expenses with each other.
    The programs include varying monthly contributions, on-site per-visit consultation fees, non-sharable
    amounts, waiting periods before sharing can begin, annual sharing limits, and sharing amounts and
    percentages. Eligible medical costs are shared on a per person per medical incident basis for illnesses or
    injuries incurring medical expenses that are medically necessary and provided by or under the direction
    of licensed Physicians within the Multiplan/PHCS Practitioner and Ancillary Network.`,
      UHSSmartShare: `UHS SmartShare programs are health cost sharing programs where you and your fellow members of
    Universal Health Fellowship share eligible medical expenses with each other. The SmartShare program
    includes a monthly contribution, on-site per-visit consultation fees, waiting periods before sharing can
    begin, annual sharing limits, and sharing amounts and percentages. There are no annual non-sharable
    amounts with this program. Eligible medical costs are shared on a per person per medical incident basis
    for illnesses or injuries incurring medical expenses that are medically necessary and provided by or
    under the direction of licensed Physicians within the Multiplan/PHCS Practitioner and Ancillary Network.`,
      UHSHealthyLife: `UHS HealthyLife programs are health cost sharing programs where you and your fellow members of
    Universal Health Fellowship share eligible medical expenses with each other. The HealthyLife program
    includes a monthly contribution, on-site per-visit consultation fees, waiting periods before sharing can
    begin, annual sharing limits, and sharing amounts and percentages. There are a set of annual non-
    sharable amounts with this program. Eligible medical costs are shared on a per person per medical
    incident basis for illnesses or injuries incurring medical expenses that are medically necessary and
    provided by or under the direction of licensed Physicians within the Multiplan/PHCS Practitioner and
    Ancillary Network.`,
      UHSEssentialShare: `UHS EssentialShare programs are health cost sharing programs where you and your fellow members of
    Universal Health Fellowship share eligible medical expenses with each other. The EssentialShare
    programs include monthly contributions, waiting periods before sharing can begin, annual sharing limits,
    and sharing amounts and percentages. There are no annual non-sharable amounts with this program.
    UHS EssentialShare is a limited sharing program that shares only up to a pre-determined amount,
    regardless of total charges incurred. There are no consultation fees or co-pays with this program, but
    you are responsible for any remaining billed charges from the provider. EssentialShare allows you to
    either use a provider in the Multiplan/PHCS Practitioner and Ancillary Network to take advantage of the
    reduced network price – or you can go to any provider you choose.`,
      UHSCommunityHealthShare: `UHS Community HealthShare programs are health cost sharing programs where you and your fellow
    members of Universal Health Fellowship share eligible medical expenses with each other. The
    Community HealthShare programs include monthly contributions, on-site per-visit consultation fees,
    waiting periods before sharing can begin, annual sharing limits, and sharing amounts and percentages.
    There are a set of annual non-sharable amounts with this program. Eligible medical costs are shared on
    a per person per medical incident basis for illnesses or injuries incurring medical expenses that are
    medically necessary and provided by or under the direction of licensed Physicians within the
    Multiplan/PHCS Practitioner and Ancillary Network. Community HealthShare also includes the
    prescription program Rx SImpleShare and the Health Tools add-on featuring vision and dental discounts,
    as well as counseling from a telephonic health advocate service.`,
      UHSThriveHealthShare: `UHS Thrive HealthShare programs are health cost sharing programs where you and your fellow
    members of Universal Health Fellowship share eligible medical expenses with each other. The Thrive
    HealthShare programs include monthly contributions, on-site per-visit consultation fees, waiting periods
    before sharing can begin, annual sharing limits, and sharing amounts and percentages. There are a set of
    annual non-sharable amounts (NSAs) with this program. But once the NSAs have been met, 100% of
    eligible medical expenses can be shared. Eligible medical costs are shared on a per person per medical
    incident basis for illnesses or injuries incurring medical expenses that are medically necessary and
    provided by or under the direction of licensed Physicians within the Multiplan/PHCS Practitioner and
    Ancillary Network.`,
    },
    ADDON_DESCRIPTIOON: {
      RxSimpleShare: `Administered by Flipt, RxSimpleShare provides access to over 66,000 licensed pharmacies that 
      accept prescription orders from medical providers from the UHS RxSimpleShare Formulary. Cost is $25 monthly 
      and a $5 co-share per prescription.`,
      HealthTools: `Health Tools is an Add-On to a UHS sharing program that includes three programs – Coast to 
      Coast Vision, AETNA Dental, and Telephonic Health Advocate. All three are available to every member of the 
      UHF member’s immediate family, regardless of which family members are enrolled in the UHS Sharing program.`,
      AdvancedHealthTools: `Advanced Health Tools is an Add-On to a UHS sharing program that includes five programs – 
      Coast to Coast Vision, AETNA Dental, Telephonic Health Advocate, FlexCare Telemedicine, and CuramLife Concierge 
      Service. All five are available to every member of the member’s immediate family, regardless of which family
       members are enrolled in the UHS Sharing program.`,
    },
  },

  netwell: {
    hippaTitle: `HIPAA PRIVACY AUTHORIZATION`,
    hipaaText: `All family members are required by the Health Insurance Portability
    and Accountability Act, 45 C.F.R. Parts 160 and 164 to authorize
    netWell for use or disclosure of Protected Health Information. Enter
    the email address of each adult member to receive a link to the
    HIPAA Form to be duly filled and submitted. For all child
    dependents, select a parent who will act as the personal
    representative and submit a single combined form on their behalf.`,
    hipaaTerminated: `Member status is terminated.`,
    hipaaAlreadySubmitted: `Your HIPAA Authorization Form has already been submitted successfully on`,
    hipaaSend: `A link to the HIPAA Authorization Form has been sent to `,
    hipaaNotFound: `Could not send HIPAA Authorization Form.`,
    hipaaSubmited: `Your HIPAA Authorization Form has been submitted successfully.`,
    hipaaSavedAlready: `Your HIPAA Authorization Form has already been submitted.`,
    NEEDHELP_MSG: `Call our netWell Representative \n on (866) NETWELL (638-9355).`,
    PaymentMethod: `To change your payment method call Member Services at 855-739-6500,\nMonday through Friday 8.00am to 8.00pm CST`,
    UpdateHousehold: `To update your household call Member Services at 855-739-6500,\nMonday through Friday 8.00am to 8.00pm CST.`,
  },

  innovative: {
    NEEDHELP_MSG: `Chat with our Health Share Representative \n or call 866-949-3581`,
    PaymentMethod: `To change your payment method call Member Services at 866-949-3581,\nMonday through Friday 8.00am to 8.00pm CST`,
    UpdateHousehold: `To update your household call Member Services at 866-949-3581,\nMonday through Friday 8.00am to 8.00pm CST.`,
    ManageAddons: `To Manage your Addons call Member Services at 866-949-3581,\nMonday through Friday 8.00am to 8.00pm CST.`,
    PROGRAM_DESCRIPTION: {
      Optimum: `The MultiPlan Network is one of the largest and most comprehensive independent participating provider (PPO) network in the United States. Your plan includes participation in this network. When you go to a participating provider, MultiPlan will reprice the charges from your doctor and you will receive the applicable discount. Once any applicable discount is applied, and if your medical service is covered by your plan, the appropriate benefits will be paid toward your claim. Please remember that even after you exhaust your benefits under your plan, you can still receive the participating provider discounts. In order to find out which providers in your area are covered and defined details regarding your specific benefits, please contact 888.677.6074`,
      Elite: `The First Health Network is one of the largest and most comprehensive independent participating provider (PPO) network in the United States. Your plan includes participation in this network. When you go to a participating provider, First Health will reprice the charges from your doctor and you will receive the applicable discount. Once any applicable discount is applied, and if your medical service is covered by your plan, the appropriate benefits will be paid toward your claim. Please remember that even after you exhaust your benefits under your plan, you can still receive the participating provider discounts. In order to find out which providers in your area are covered and defined details regarding your specific benefits, please contact 888.677.6074`,
      Ranger:``,
      Value:``,
      Dental:``
    },
    ADDON_DESCRIPTIOON: {
      Guardian: `Our Guardian plans add coverage for accidental death and dismemberment. These plans are optional add-on benefits at an additional monthly cost for you and are available for additional family members.`,
      Essential: `Our Essential plans help members with their critical illness needs. These plans are optional add-on benefits at an additional monthly cost for you and are available for additional family members.`,
      Dental: `Our Dental plans help members with their dental needs. These plans are optional add-on benefits at an additional monthly cost for you and are available for additional family members.`,
    },
  },
  SOMETHING_WRONG: "Something went wrong",
  NO_NOTIFICATIONS: "You currently have no new notifications.",
  NO_ANNOUNCEMENTS: "You currently have no new announcements & notices.",
  NO_DOCUMENTS: `Documents not available`,
  PaymentInformationMessage:
    "The fellowship incurs additional fees related to processing credit cards that we do not incur when processing ACH payments. We therefore encourage our members to pay by ACH, yet offer credit card payments as an option as well. If you choose to pay by credit card, you will incur an additional 3.5% fee to cover the fellowship’s cost.",
  ChangeBillingDateMessage: `You can submit a request to change your billing date. Our Member Services team will get in touch with you at the earliest and guide you through the process.`,
  AccountNumberOnFile: `Account Number currently on file`,
  changePaymentMethod: `CHANGE PAYMENT METHOD`,
  NextPaymentDueDate: "Next Payment Due Date",
  CHANGEBILLINGDATE: "CHANGE BILLING DATE",
  NoPaymentInfoAvaible: " No Payment Information data Available.",
  SubmittedRequest: `Your request has been submitted.`,
  askCarynTextMessage:
    "The Ask Caryn tool and resulting search results are built on Artificial Intelligence algorithms, to search our cache of documents based on your expressed intent. The top search result may not be the answer you seek/need. If in doubt, please refer the Sharing Guidelines or contact Customer Service. Universal Health Fellowship or its partner CarynHealth is not responsible for the validity of the search results.",
  errMessageAiAssistant:
    "We're having some difficulties connecting to our knowledge base. Please try again after some time.",
});

export default messages;
