import React, { useEffect, useState } from "react";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import styled from "@mui/material/styles/styled";
import Grid from "@mui/material/Grid";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import {
  tableHeader,
  getDate,
  numberWithCommas,
  floatconversion,
  access,
} from "../data";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserTransactionDetails } from "../handlers";
import { ApiLoader } from "carynhealth-component-library";
import StyleWrapper from "../Style";

export default function AccordianTransactionTable(props: any) {
  const [panel, setPanel] = useState("");
  const [transactionData, setTransaction] = useState([]);
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [CardDeatils, setCardDetails] = useState("");
  const innovativeAccess = access.control.type == "INNOVATION";
  useEffect(() => {
    setLoader(true);
    getTransactionDetails();
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }, []);

  const getTransactionDetails = () => {
    getUserTransactionDetails()
      .then((response) => {
        if (response.data.response) {
          setTransaction(response.data.response);
          if (response.data.response.length == 0) {
            setMessage("My Transactions Data Not Available");
          }
        } else {
          setTransaction(response.data.pageList);
          if (response.data.pageList.length == 0) {
            setMessage("My Transactions Data Not Available");
          }
        }
        setLoader(false);
      })
      .catch((err) => {
        setMessage("My Transactions Data Not Available");
        setLoader(false);
      });
  };
  const changePannel = (selected: string, panel: string) => {
    if (selected === panel) {
      setPanel("");
    } else {
      setPanel(selected);
    }
  };
  return (
    <>
      <StyleWrapper>
        {loader && <ApiLoader />}
        <div style={{ paddingBottom: "30%" }}>
          {transactionData.length > 0 ? (
            transactionData.map((data: any, i: any) => (
              <Accordion
                style={{ zIndex: 2 }}
                expanded={panel === "panel" + i}
                onChange={() => changePannel("panel" + i, panel)}
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  style={{ backgroundColor: "#fff" }}
                  expandIcon={
                    <ArrowForwardIosSharpIcon
                      sx={{ fontSize: "1rem" }}
                      className="collapseIcon"
                    />
                  }
                >
                  <Grid container sx={{ paddingLeft: 1 }}>
                    <Grid item xs={8} className="itemWrap">
                      <div className="Expense-Number221">
                        {tableHeader.heading.parent[0]}
                      </div>
                      <div className="Expense-Number211">
                        {data.transactionId}
                      </div>
                    </Grid>

                    <Grid item xs={4} className="itemWrap">
                      <span
                        className={
                          "web_status_button " + data.authorizationMessage
                        }
                      >
                        {data.authorizationMessage}
                      </span>
                    </Grid>

                    <Grid item xs={6} className="itemWrap">
                      <div className="Expense-Number221">
                        {tableHeader.heading.parent[1]}
                      </div>
                      <div className="Expense-Number211">
                        {getDate(
                          data.createdDate
                            ? data.createdDate
                            : data.transaction_date
                        )}
                      </div>
                    </Grid>

                    <Grid item xs={6} className="itemWrap">
                      <div className="Expense-Number221">
                        {tableHeader.heading.parent[3]}
                      </div>
                      <div className="Expense-Number211">
                        $
                        {data.transactionAmount
                          ? data.transactionAmount
                          : data.transaction_amount}
                      </div>
                    </Grid>

                    <Grid item xs={6} className="itemWrap">
                      <div className="Expense-Number221">
                        {tableHeader.heading.parent[2]}
                      </div>
                      <div className="Expense-Number211">
                        {" "}
                        {data.payment_method ? data.payment_method : data.type}
                      </div>
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#F6F6F6" }}>
                  {innovativeAccess ? (
                    <Grid container>
                      <Grid item xs={5} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[0]}
                        </div>
                        <div className="Expense-Number211">
                          {data.essentialAmount ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.essentialAmount)
                              )}
                            </>
                          ) : data.essentialAmount ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.essentialAmount)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={7} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[1]}
                        </div>
                        <div className="Expense-Number211">
                          {data.guardianAmount ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.guardianAmount)
                              )}
                            </>
                          ) : data.guardianAmount ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.guardianAmount)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={7} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[1]}
                        </div>
                        <div className="Expense-Number211">
                          {data.dentalAmount ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.dentalAmount)
                              )}
                            </>
                          ) : data.dentalAmount ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.dentalAmount)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={5} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[2]}
                        </div>
                        <div className="Expense-Number211">
                          {data.monthlyShare ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.monthlyShare)
                              )}
                            </>
                          ) : data.monthlyShare ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.monthlyShare)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={5} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[3]}
                        </div>
                        <div className="Expense-Number211">
                          {data.applicationFee ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.applicationFee)
                              )}
                            </>
                          ) : data.applicationFee ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.applicationFee)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={12} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[0]}
                        </div>
                        <div className="Expense-Number211">
                          {data.monthlyShare ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.monthlyShare)
                              )}
                            </>
                          ) : data.monthly_share ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.monthly_share)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={7} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[1]}
                        </div>
                        <div className="Expense-Number211">
                          {data.uhfMonthlyFee ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.uhfMonthlyFee)
                              )}
                            </>
                          ) : data.ufh_monthly_fee ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.ufh_monthly_fee)
                              )}
                            </>
                          ) : (
                            "$0"
                          )}
                        </div>
                      </Grid>

                      <Grid item xs={5} className="itemWrap">
                        <div className="Expense-Number221">
                          {tableHeader.heading.child[2]}
                        </div>
                        <div className="Expense-Number211">
                          {data.applicationFee ? (
                            <>
                              $
                              {numberWithCommas(
                                floatconversion(data.applicationFee)
                              )}
                            </>
                          ) : data.application_fee ? (
                            <>
                              {numberWithCommas(
                                floatconversion(data.application_fee)
                              )}
                            </>
                          ) : (
                            "0"
                          )}
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </AccordionDetails>
              </Accordion>
            ))
          ) : (
            <div className="data_not_found" style={{ width: "100%" }}>
              <h5 className="noneeds_msg_display">{message}</h5>
            </div>
          )}
        </div>
      </StyleWrapper>
    </>
  );
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "1px solid rgba(0, 0, 0, .125)",
  boxShadow: "none",
  padding: "0px",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  "&$expanded": {
    margin: "auto",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "1rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "#ffffff",
  borderBottom: "1px solid rgba(0, 0, 0, .125)",
  marginBottom: -1,
  minHeight: 120,
  paddingLeft: "5px",
  paddingRight: "5px",
  flexDirection: "row-reverse",
  "&$expanded": {
    minHeight: 125,
  },
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    mmargin: "12px 0",
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
}));
