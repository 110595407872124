import { styled } from "@mui/system";

export default styled("div")(({ theme }) => {
  return {
    ".progInfoContainer": {
      margin: "0 5.041vw",
      paddingTop: 30,
      paddingBottom: 46,
      ".Back_page_uhf": {
        fontSize: 24,
        color: "#543379",
        width: "6.449vw",
        height: 24,
        margin: "0.222vw 0 0 0.371vw",
        fontFamily: "Oswald",
        fontWeight: 550,
        fontStretch: "normal",
        fontStyle: "normal",
        lineHeight: 1,
        letterSpacing: 1.44,
        cursor: "pointer",
        display: "flex",
        justifyContent: "center",
        ".MuiTypography-root": {
          textDecoration: "none",
          display: "flex",
          justifyContent: "center",
          color: "#543379",
        },
        ".MuiSvgIcon-root": {
          color: "#543379 !important",
        },
      },
      ".commonWrap": {
        marginTop: "4.32vh",
        backgroundColor: "#ffff",
        padding: "1.4vw",
        borderRadius: "4px 4px 0 0",
        ".progInfoTitle": {
          color: "#543379",
          marginBottom: "2.9vw",
          fontFamily: "Roboto",
          fontSize: 42,
          fontWeight: 300,
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1,
          letterSpacing: "normal",
          textAlign: "left",
          textTransform: "uppercase",
          marginTop: 0,
        },
        text: {
          fontFamily: "Roboto",
          fontSize: 16,
          fontWeight: "normal",
          fontStretch: "normal",
          fontStyle: "normal",
          lineHeight: 1.5,
          letterSpacing: "normal",
          textAlign: "left",
          color: "rgba(0, 0, 0, 0.87)",
        },
        ".enrolledTableClass": {
          paddingTop: "3%",
          marginRight: "6%",
          minHeight: "50vh",
          marginLeft: 5,
          ".tableContainer": { boxShadow: "none!important" },
          ".MuiPaper-rounded ": {
            borderRadius: 4,
          },
        },
        ".MuiPaper_root": {
          color: "rgba(0, 0, 0, 0.87)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundColor: "#fff",
        },
        ".MuiTableContainer-root": {
          width: "100%",
          overflowX: "auto",
          minHeight: "32vh",
          marginBottom: 20,
          overflowY: "auto !important",
        },
        ".MuiTable-stickyHeader": {
          borderCollapse: "separate",
        },
        ".MuiTableRow-root": {
          borderCollapse: "collapse",
        },
        ".MuiTableCell-root": {
          padding: "2px !important",
          borderBottom: "none",
          fontFamily: "Oswald,SemiBold",
          fontSize: "0.75em",
          fontWeight: 700,
          color: "#4f4f4f",
          letterSpacing: 1.2,
          zIndex: 0,
        },
        ".TableCell": {
          color: "#000000e3",
          fontSize: "0.875em",
          textAlign: "left",
          fontFamily: "Roboto",
          fontWeight: 400,
          borderBottom: "none",
          letterSpacing: "normal",
        },
      },
      ".ProgressBar": {
        maxWidth: "100%",
        flexGrow: 1,
        position: "unset",
        padding: 0,
        ".MuiLinearProgress-root": {
          height: "0.6rem",
          backgroundColor: "#f2f2f2",
          width: "100%",
          ".css-5xe99f-MuiLinearProgress-bar1": {
            // backgroundColor:'#27ae60', // sucess
            //  backgroundColor: '#420045'
          },
        },
      },
      ".Status_StartBtn": {
        backgroundColor: "#ffff !important",
        marginLeft: "2rem",
        borderRadius: 24,
        border: "2px solid #41b5c2",
        color: "#41b5c2",
        width: "33%",
        padding: "0.1rem 3.4rem",
        letterSpacing: 1.2,
        fontFamily: "Oswald,SemiBold",
        marginBottom: 10,
        fontSize: "1.25em",
      },
      ".viewBtn": {
        marginLeft: ".5rem",
      },
      ".dpFlex": {
        display: "flex",
      },
      ".progInfoFooter": {
        borderTop: "none",
        ".leftSectionbuttons": {
          button: {
            backgroundColor: "#41b5c2",
            borderRadius: 20,
            color: "#fff",
            fontWeight: 700,
            letterSpacing: 0.75,
            width: 120,
            fontFamily: "Oswald,SemiBold",
            minWidth: "15%",
            marginRight: 8,
            fontSize: ".94em",
          },
          ".Mui-disabled": {
            opacity: ".6!important",
            cursor: "not-allowed",
          },
        },
      },
    },
    ".product_footer-chat": {
      marginLeft: 12,
      ".MuiFab-root": {
        zIndex: 0,
      },
    },
    [theme.breakpoints.down(475)]: {
      ".progInfoContainer": {
        margin: 0,
        padding: 0,
        ".Back_page_uhf": {
          display: "none",
        },
        ".progInfoTitle": {
          display: "none",
        },
        ".viewBtn": {
          marginLeft: 32,
        },
      },
    },
  };
});
