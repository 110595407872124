import moment from "moment";
import { getRoutePaths } from "../../config/constants/routePaths";
import { getsharingguidlineslink } from "./handlers";

export const getDate = (date: any) => {
  const isDateValid = date ? date : false;
  if (isDateValid) {
    return moment(date).format("MMMM D, YYYY");
  }
  return date;
};

export const numberWithCommas = (x: any) => {
  return Number(x)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const floatconversion = (x: any) => {
  if (x.toString().startsWith("$")) {
    let substr = x.substr(1, x.length);
    return "$" + parseFloat(substr).toFixed(2).toString();
  } else {
    return x.toFixed(2);
  }
};
export const getPharmaText = (
  isPharma: any,
  messageText: any,
  message: any
) => {
  if (message.subintentrasa === "pharma/signup") {
    if (!isPharma) {
      return messageText;
    } else {
      return "You already have RxSimpleShare added to your membership.";
    }
  } else if (message.subintentrasa === "pharma/cancel") {
    if (isPharma) {
      return messageText;
    } else {
      return `You have not subscribed to the RxSimpleShare Program. With RxSimpleShare, you can share prescription drug costs for 90 of the top 100 most commonly prescribed medications. To add RxSimpleShare to your membership, go to Program Information and click Change Add-ons. You will be automatically signed into the Enrollment Portal, where you can review available add-ons and choose what's best for you.`;
    }
  } else if (message.subintentrasa === "pharma/medicines") {
    if (isPharma) {
      return messageText;
    } else {
      return `You have not subscribed to the RxSimpleShare Program. With RxSimpleShare, you can share prescription drug costs for 90 of the top 100 most commonly prescribed medications. To add RxSimpleShare to your membership, go to Program Information and click Change Add-ons. You will be automatically signed into the Enrollment Portal, where you can review available add-ons and choose what's best for you.`;
    }
  } else return messageText;
};
export const findIfAgentIsPresent = () => {
  const agentDetails = localStorage.getItem("AgentDetails");
  if (agentDetails !== "{}") {
    return true;
  }
  return false;
};
export const getIdCardType = (slot: any, pharmaShow: any) => {
  const idCardSlots = ["id card", "idcards", "id cards", "idcard"];
  const membershipCardSlots = [
    "membership",
    "membership card",
    "membership cards",
    "membership card",
  ];
  const healthtoolCaldSlots = [
    "health tool card",
    "healthtool card",
    "health tool cards",
    "healthtool cards",
    "health tool card",
    "health tool card",
    "healthtool",
    "healthtools",
  ];
  if (!slot) return "";
  if (
    idCardSlots.includes(slot.toLowerCase()) ||
    membershipCardSlots.includes(slot.toLowerCase())
  ) {
    return "Here is your ID Cards";
  } else if (
    healthtoolCaldSlots.includes(slot.toLowerCase()) &&
    localStorage.getItem("HealthTool") == "true"
  ) {
    return "Here is your HealthTool Card";
  } else if (localStorage.getItem("HealthTool") == "true") {
    return "";
  } else {
    return `You have not subscribed to the Health Tools add-on.
    To add Health Tools to your membership, go to Program Information and click Change Add-ons. 
    You will be automatically signed into the Enrollment Portal, 
    where you can review available add-ons and choose what's best for you.`;
  }
};

export const navigation = (intent: any, navigateRoutes: any) => {
  switch (intent) {
    case "Membership ID": {
      return intent;
    }
    case "Health Tool": {
      return intent;
    }
    case "find_provider": {
      window.open(getRoutePaths.findAProvider);
      break;
    }
    case "faq": {
      window.open(getRoutePaths.faq);
      break;
    }
    case "Payment Card": {
      navigateRoutes(getRoutePaths.dashboard);
      break;
    }
    case "Transactions": {
      navigateRoutes(getRoutePaths.myTransaction);
      break;
    }
    case "PaymentMethod": {
      navigateRoutes(getRoutePaths.dashboard);
      break;
    }
    case "BillingDate": {
      navigateRoutes(getRoutePaths.myTransaction);
      break;
    }

    case "notifications": {
      navigateRoutes(getRoutePaths.myNotifications);
      break;
    }
    case "program information": {
      navigateRoutes(getRoutePaths.programInformation);
      break;
    }
    case "changeDependants": {
      navigateRoutes(getRoutePaths.programInformation);
      break;
    }

    case "Email_Agent": {
      let call = JSON.stringify(localStorage.getItem("AgentDetails"));
      window.open(`mailto:${JSON.parse(call)?.email}`, "_self");
      break;
    }
    case "Email": {
      window.open(
        `mailto:customerservice@universalhealthfellowship.org`,
        "_self"
      );
      break;
    }
    case "memberPortal": {
      navigateRoutes(getRoutePaths.dashboard);
      break;
    }
    case "telemed": {
      window.open(getRoutePaths.telemed);
      break;
    }
    case "needs": {
      navigateRoutes("/" + getRoutePaths.MyNeeds);
      break;
    }
    case "health questionary": {
      navigateRoutes(getRoutePaths.healthQuestionary);
      break;
    }
    case "ContactCardScreen": {
      return intent;
      break;
    }
    case "Call_Customer": {
      return intent;
      break;
    }
    case "Announcements": {
      navigateRoutes(getRoutePaths.announcements);
      break;
    }
    case "change_addons": {
      navigateRoutes(getRoutePaths.programInformation);
      break;
    }
    case "change_dependants": {
      navigateRoutes(getRoutePaths.programInformation);
      break;
    }
    case "TransactionsPayment": {
      navigateRoutes(getRoutePaths.myTransaction);
      break;
    }
    case "UHF": {
      navigateRoutes(getRoutePaths.dashboard);
      break;
    }
    case "healthshareVSinsurance": {
      window.open(
        `https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Infographics/UHS+-+5+Questions+about+Health+Sharing.pdf`
      );
      break;
    }
    case "sharingGuideline": {
      getsharingguidlineslink().then((res) => {
        if (res.data && res.data.length > 0) {
          let providerLink = res.data[0].fieldValue;
          window.open(providerLink);
        }
      });
      break;
    }
    case "pharma": {
      return intent;
      break;
    }
    case "Search Sharing Guidelines": {
      getsharingguidlineslink().then((res) => {
        if (res.data && res.data.length > 0) {
          let providerLink = res.data[0].fieldValue;
          window.open(providerLink);
        }
      });
      break;
    }
    case "Search KnowledgeBase": {
      return intent;
      break;
    }
    case "Read More": {
      return intent;
      break;
    }
    case "ContactInformation": {
      return intent;
      break;
    }
    case "Call": {
      return intent;

      break;
    }
    case "documents": {
      return intent;
      break;
    }
    case "RxSimpleShareFormuary": {
      window.open(
        `https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/Important+Documents/UHS-RxSimpleShareFormuary.pdf`
      );
      break;
    }
    case "RxSimpleShareProgram": {
      window.open(
        `https://carynhealth-memberportal-prod-documents.s3.us-east-2.amazonaws.com/UHF-Agent/UHS-RxSimpleShare-Flyer.pdf`
      );
      break;
    }
    default: {
      return intent;
    }
  }
};
