import React, { useContext, useEffect, useState } from "react";
import {
  Footer as FooterLibrary,
  ProgramInfoFooter,
} from "carynhealth-component-library";
import { footerProps, AiChatIcon } from "./data";
import { AuthContext } from "../../context/AuthenticateContext";
import { useNavigate, useLocation } from "react-router-dom";
import routePaths, { getRoutePaths } from "../../config/constants/routePaths";
import CarynIcon from "../../assests/images/uhf/carynIcon.jpg";
import { isMobile } from "react-device-detect";
import { Fab, useMediaQuery, useTheme } from "@mui/material";
import ForumIcon from "@mui/icons-material/Forum";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import { styled } from "@mui/system";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

const StyleWrapper = styled("div")(({ theme }) => {
  return {
    [theme.breakpoints.down(426)]: {
      footer: {
        padding: "15px 0 0 !important",
      },
    },
  };
});

const Footer = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [myFooterProps, setMyFooterProps] = useState<any>({});
  const [AiIcon] = useState<any>(AiChatIcon);
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setFooterData();
  }, [auth]);

  const setFooterData = () => {
    let footerData: any = footerProps(
      auth.authenticatedInfo.isAuthenticated,
      auth.authenticatedInfo.throughEmail
    );
    if (auth.authenticatedInfo.isAuthenticated) {
      footerData.bottomNavgation.bottomNavCallBack = myCallBack;
    }
    setMyFooterProps(footerData);
    if (location.pathname === routes.dashboard) {
      setMyFooterProps(footerProps(true, true));
    }
  };

  const myCallBack = (data: any) => {
    switch (data) {
      case 0:
        navigate(getRoutePaths.dashboard);
        break;
      case 1:
        navigate(getRoutePaths.documents);
        break;
      case 2:
        navigate(getRoutePaths.myNeeds);
        break;
      default:
        navigate(getRoutePaths.dashboard);
        break;
    }
  };
  if (
    (location.pathname === getRoutePaths.aiChat ||
      !auth.authenticatedInfo.isAuthenticated) &&
    showText
  )
    return <></>;

  return (
    <>
      {AiIcon && location.pathname !== getRoutePaths.login && showText && (
        <div
          style={{
            position: "fixed",
            right: 0,
            left: "80%",
            bottom: 60,
            zIndex: 1030,
          }}
        >
          <img
            src={CarynIcon}
            width={"56px"}
            onClick={() => navigate(getRoutePaths.aiChat)}
          />
        </div>
      )}
      {/* {showText && (
        <ProgramInfoFooter
          label={""}
          rightSectionText={
            config.APP_NAME === config.BRAND_NETWELL
              ? messages.netwell.NEEDHELP_MSG
              : messages.uhf.NEEDHELP_MSG
          }
          showChatIcon={() =>
            config.APP_NAME === config.BRAND_NETWELL ? (
              <></>
            ) : (
              <div style={{ marginLeft: 12 }}>
                <Fab
                  aria-label="add"
                  style={{ zIndex: 0 }}
                  sx={{ background: "#fb6647", color: "#fff" }}
                >
                  <ForumIcon sx={{ color: "#fff" }} />
                </Fab>
              </div>
            )
          }
        />
      )} */}
      <StyleWrapper>
        <FooterLibrary {...myFooterProps} />
      </StyleWrapper>
    </>
  );
};

export default Footer;
