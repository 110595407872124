export const HipaaAuthQues = {
  questionList: [
    {
      title: "Authorization",
      descipation:
        "I authorize <b> netWell </b> to use and disclose the protected health information described below.",
      subDescipation: [],
    },
    {
      title: "Effective Period",
      descipation:
        "This authorization for release of information covers the entirety of my membership with netWell from the effective start date.",
      subDescipation: [],
    },
    {
      title: "Extent of Authorization",
      descipation:
        "This authorization for release of information covers the period of healthcare",
      subDescipation: [
        {
          describe:
            "I authorize the release of my complete health record (including records relating to mental healthcare, communicable diseases, HIV or AIDS, and treatment of alcohol or drug abuse.",
          options: [],
          id: 1,
        },
        {
          describe:
            "I authorize the release of my complete health record with the exception of the following information:",
          options: [
            "Mental health records",
            "Communicable diseases (including HIV and AIDS)",
            "Alcohol/drug abuse treatment",
            "Other (please specify):",
          ],
          id: 2,
        },
      ],
    },
    {
      title: "",
      descipation:
        "This medical information may be used by my health share provider and/or netWell representative, who I authorize to receive this information for medical treatment or consultation, billing or payments of medical request, or other purposes as I may direct.",
      subDescipation: [],
    },
    {
      title: "",
      descipation:
        "This authorization shall be in force for the entirety of my membership with netWell.",
      subDescipation: [],
    },
    {
      title: "",
      descipation:
        "I understand that my treatment, payment, enrollment, or eligibility will not be conditioned on whether I sign this authorization.",
      subDescipation: [],
    },
    {
      title: "e-signature",
      descipation: "",
      subDescipation: [
        {
          describe: "",
          options: ["Use stylized script", "Let me draw"],
          id: 1,
        },
      ],
    },
  ],
};
