import React, { useContext, useEffect, useState } from "react";
import { CircularProgress, useMediaQuery, useTheme } from "@mui/material";
import { Amplify, Auth } from "aws-amplify";
import RoutesComponent from "./config/routes";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { awsConfig, parseJwt } from "./data";
import config from "./config/constants/config";
import { AuthContext } from "./context/AuthenticateContext";
import "./App.css";
import "carynhealth-component-library/dist/cjs/assests/css/common.css";
import "carynhealth-component-library/dist/cjs/assests/css/uhf.css";
import "carynhealth-component-library/dist/cjs/assests/css/netwell.css";
import "carynhealth-component-library/dist/cjs/assests/css/innovative.css";
import { RightTabs } from "./components/RightTopMemberTabs/RightTabs";
import { HeaderTab } from "./components/RightTopMemberTabs/HeaderTab";
import { useNavigate, useLocation } from "react-router-dom";
import routeConfig from "./config/routes/routeConfig";
import {
  getDecryptIdAPI,
  getGateWayToken,
  getIdCardDetails,
} from "../src/config/routes/handlers";
import { getEnrolledMemberDataAPI } from "./UI/HippaCard/handlers";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

Amplify.configure(awsConfig);
const brand = config.APP_NAME;

function App() {
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);
  const auth = useContext(AuthContext);
  const theme = useTheme();
  const isMoblie = useMediaQuery(theme.breakpoints.down("md"));
  const location = useLocation();
  const [reRender, ForceReRender] = useState(0);
  const navigate = useNavigate();
  const forceRerender = () => {
    ForceReRender(prevCount => prevCount + 1);
  };
  useEffect(() => {
    const handleStorageChange = (event: any) => {
      if (event.key === 'userMail' && sessionStorage.getItem("launch")) {
        window.location.reload();
      } else if(event?.url?.split("=")?.length > 1){
        window.location.href = event.url;
      }
    };
    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);

  useEffect(() => {
    let url = window.location.href;
    let email: any = localStorage.getItem("userMail");
    if ( url.split("=").length > 1 ) {
      localStorage.clear();
      sessionStorage.clear();
      setLoader(true);
      StateParam();
    }
    else 
    Auth.currentSession()
      .then((session: any) => {
        const authInfo = parseJwt(session.idToken.jwtToken);
        auth.updateAuthenticateInfo(authInfo);
        idcardData(authInfo.email,true);
        setLoading(false);
      })
      .catch((error: any) => {
        let url = window.location.href;
        let email: any = localStorage.getItem("userMail");
        const authNotReq = routeConfig.find(
          ({ authRequired, path }) =>
            path === location.pathname && !authRequired
        );
        if (
          url.split("HipaaAuthorization?").length > 1 ||
          url.split("=").length > 1
        ) {
          setLoader(true);
          StateParam();
        } else if (sessionStorage.getItem("USER_ACTIVE_SESSION")) {
          idcardData(email, false);
        } else if (!authNotReq) auth.resetAuthenticateInfo();
        else{
          localStorage.clear();
          sessionStorage.clear();
        }
        setLoading(false);
      });
  }, []);

  const StateParam = () => {
    let urlValues = window.location.hash ? window.location.hash.split("=") : [];
    if (urlValues && urlValues.length > 0) {
      if (urlValues[1]) {
        getGateWayToken().then(() => {
          getDecryptIdAPI(urlValues[1]).then((res: any) => {
            idcardData(res.data, true);
          });
        });
      }
    }
  };
  const idcardData = (email: string, reload: any) => {
    getIdCardDetails(email).then(async (data: any) => {
      setLoader(false);
      let authInfo = {
        ...auth.authenticatedInfo,
        email: email,
        isAuthenticated: true,
        throughEmail: true,
      };
      localStorage.setItem("planId", data.apiResponse.memberIdCardList[0].planId)
      await getEnrolledMemberDataAPI(data.apiResponse.memberIdCardList[0].memberId)
      .then((res: any) => {
        authInfo = {
          ...authInfo,
          name:`${res.apiResponse[0]?.prefix || ''} ${res.apiResponse[0].firstName || ''} ${res.apiResponse[0]?.middleName || ''} ${res.apiResponse[0].lastName || ''} ${res.apiResponse[0]?.suffix || ''}`.trim().replace(/\s+/g, ' ')
        }
      })
      .catch((error) => {
        console.log(error);
      });
      
      auth.updateAuthenticateInfo(authInfo || auth.authenticatedInfo);

      localStorage.setItem("userMail", email);
      localStorage.setItem(
        "sourceid",
        data.apiResponse.memberIdCardList[0].memberId
      );
      localStorage.setItem(
        "CLIENT_ID",
        data.apiResponse.memberIdCardList[0].clientId
      );
      sessionStorage.setItem("USER_ACTIVE_SESSION", "true");
      localStorage.setItem(
        "subscriberName",
        authInfo.name
      );
      navigate({
        pathname: "/dashboard",
      });
      if(!sessionStorage.getItem("launch")){
        sessionStorage.setItem("launch", "true");
        window.location.reload();
      }
    });
  };

  const GetHeaderTabs = () => {
    if (isMoblie) {
      return <HeaderTab />;
    }
    return <></>;
  };
  if (loader)
    return (
      <div className="loader">
        <CircularProgress />
      </div>
    );
  else
    return (
      <>
        <div className={`${brand}`}>
          <Header />
          <GetHeaderTabs />
          <RightTabs />
          {loading ? null : <RoutesComponent />}
          <Footer />
        </div>
      </>
    );
}

export default App;
