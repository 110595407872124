import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  AccordianTransaction,
  ApiLoader,
  CardWrapper,
  Dialog,
} from "carynhealth-component-library";
import { ProductTileInfo, TransactionAccordian } from "../../data";
import { storeTransaction } from "../../handlers";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styled } from "@mui/material";
import messages from "../../../../config/constants/messages";
import { Tab, Tabs, TextField, Button, Grid } from "@mui/material";
import RadioButtonActive from "../../../../assests/images/radioButtonChecked.png";
import RadioButtonInActive from "../../../../assests/images/radioButtonUnchecked.png";
import CustomTextField from "../../Desktop/View/sampleTextField";
import CommonDropDwn from "../../Desktop/View/CommonDropDwn";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";
import { access } from "../../data";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import StyleWrapper from "../../Style";
import config from "../../../../config/constants/config";

const StyleBackWrapper = styled(Link)(() => {
  return {
    textDecoration: "none",
    fontFamily: "Tungsten",
    fontWeight: 600,
    fontSize: 24,
    letterSpacing: 1.14,
    display: "flex",
    alignItems: "center",
    margin: "15px 0",
    color: "#458586",
  };
});
const TransactionStyle = styled("section")(({ theme }) => {
  return {
    "&#program_information": {
      maxWidth: "90%",
      margin: "0px auto",
      paddingTop: 10,
      paddingBottom: 50,
      ".MuiTabs-flexContainer": {
        justifyContent: "space-around",
      },
      ".MuiCardContent-root .MuiButtonBase-root": {
        minWidth: "13%",
        background: "#fff !important",
        color: "#162242",
      },
      ".MuiTabs-indicator": {
        backgroundColor: "#162242",
      },
      ".MuiTabs-scroller": {
        borderBottom: "1px solid #999999",
      },
    },
  };
});

const currentYear = new Date().getFullYear();
const next10Years = Array.from({ length: 10 }, (_, index) => currentYear + index);

const ChangePaymentScreen = (props: any) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState(0);
  const [firstTab, setFirstTab] = useState(RadioButtonActive);
  const [secondTab, setSencondTab] = useState(RadioButtonInActive);
  const [loader, setLoader] = useState(false);
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const years = next10Years;
  const accountTypes = ["CHECKING", "SAVINGS"];

  const [bankName, setBankName] = useState("");
  const [nameonAccount, setNameonAccount] = useState(
    localStorage.getItem("subscriberName")
  );
  const [accountType, setAccountType] = useState("");
  const [routingNumber, setRoutingNumber] = useState("");
  const [accountNumber, setAccountNumber] = useState("");

  const [cardNumber, setCardNumber] = useState("");
  const [cardHolderName, setCardHolderName] = useState(
    localStorage.getItem("subscriberName")
  );
  const [activeOneDisable, setActiveOneDisable] = useState(true);
  const [activeTwoDisable, setActiveTwoDisable] = useState(true);
  const [expirationMonth, setExpirationMonth] = useState("");
  const [expirationYear, setExpirationYear] = useState("");
  const [cvv, setCvv] = useState("");

  const [errCodeACH, seterrCodeACH] = useState(false);
  const [MsgModalerror, setMsgModalerror] = useState("");
  const [errCodeCC, seterrCodeCC] = useState(false);

  const [MsgModalerrorFooterACH, setMsgModalerrorFooterACH] = useState("");
  const [MsgModalerrorFooterCC, setMsgModalerrorFooterCC] = useState("");

  const handleChange = (event: any, newValue: any) => {
    setActiveTab(newValue);
    if (newValue == 0) {
      setFirstTab(RadioButtonActive);
      setSencondTab(RadioButtonInActive);
    } else {
      setFirstTab(RadioButtonInActive);
      setSencondTab(RadioButtonActive);
    }
  };
  const changeText = (val: any, isValid: any, parentObj: any) => {
    if (parentObj.label === "bank_name") {
      if (isValid) {
        setBankName(val);
        validateForm();
      } else {
        setBankName("");
        validateForm();
      }
    } else if (parentObj.label === "Account_Name") {
      if (isValid) {
        setNameonAccount(val);
        validateForm();
      } else {
        setNameonAccount("");
        validateForm();
      }
    } else if (parentObj.label === "Routing_Number") {
      if (isValid) {
        setRoutingNumber(val);
        seterrCodeACH(false);
        validateForm();
      } else {
        setRoutingNumber("");
        validateForm();
      }
    } else if (parentObj.label === "Account_Type") {
      if (isValid) {
        setAccountType(val);
        validateForm();
      } else {
        setAccountType("");
        validateForm();
      }
    } else if (parentObj.label === "Account_Number") {
      if (isValid) {
        setAccountNumber(val);
        validateForm();
      } else {
        setAccountNumber("");
        validateForm();
      }
    } else if (parentObj.label === "Card_Number") {
      if (isValid) {
        setCardNumber(val);
        setMsgModalerror("");
        seterrCodeCC(false);
        validateForm();
      } else {
        setCardNumber("");
        validateForm();
      }
    } else if (parentObj.label === "Holder_Name") {
      if (isValid) {
        setCardHolderName(val);
        validateForm();
      } else {
        setCardHolderName("");
        validateForm();
      }
    } else if (parentObj.label === "expiryMonth") {
      if (isValid) {
        setExpirationMonth(val);
        validateForm();
      } else {
        setExpirationMonth("");
        validateForm();
      }
    } else if (parentObj.label === "expiryYear") {
      if (isValid) {
        setExpirationYear(val);
        validateForm();
      } else {
        setExpirationYear("");
        validateForm();
      }
    } else if (parentObj.label === "cvv") {
      if (isValid) {
        setCvv(val);
        validateForm();
      } else {
        setCvv("");
        validateForm();
      }
    }
  };
  const validateForm = () => {
    if (activeTab === 0) {
      if (
        bankName !== "" &&
        nameonAccount !== "" &&
        accountNumber !== "" &&
        accountType !== "" &&
        routingNumber !== ""
      ) {
        setActiveOneDisable(false);
      } else {
        setActiveOneDisable(true);
      }
    } else if (activeTab === 1) {
      if (
        cardNumber !== "" &&
        cardHolderName !== "" &&
        expirationMonth !== "" &&
        expirationYear !== "" &&
        cvv !== ""
      ) {
        setActiveTwoDisable(false);
      } else {
        setActiveTwoDisable(true);
      }
    }
  };
  const refreshState = () => {
    setBankName("");
    setAccountType("");
    setAccountNumber("");
    setCardNumber("");
    setRoutingNumber("");
    setActiveOneDisable(true);
    setActiveTwoDisable(true);
    setExpirationMonth("");
    setExpirationYear("");
    setCvv("");
    seterrCodeACH(false);
    setMsgModalerror("");
    seterrCodeCC(false);
    setMsgModalerrorFooterACH("");
    setMsgModalerrorFooterCC("");
  };
  const changePaymentMethod = () => {
    setLoader(true);
    var payType = "";
    if (activeTab == 0) {
      payType = "ACH";
    } else {
      payType = "CC";
    }
    let obj = {
      source: getLocalStorage("sourceid"),
      bankName: bankName,
      accountName: nameonAccount,
      accountNumber: accountNumber,
      accountType: accountType,
      routingNo: routingNumber,

      cardNumber: cardNumber,
      holderName: cardHolderName,
      expiryMonth: expirationMonth
        ? moment().month(expirationMonth).format("M")
        : "",
      expiryYear: expirationYear,
      cvv: cvv,
      paymentType: payType,
    };
    storeTransaction(obj)
      .then((res) => {
        setLoader(false);
        if (res.data.code == 200) {
          setMsgModalerror("Updated payment details successfully!");
        } else if (res.data.code == 202) {
          let x = JSON.parse(res.data.response).error_message;
          let errMsg = "";
          if (x.includes("-")) {
            let cds = x.split(" - ");
            errMsg = cds[1];
          } else {
            errMsg = x;
          }
          setMsgModalerror(errMsg);
          seterrCodeACH(true);
          if (activeTab == 0 && MsgModalerror != "") {
            seterrCodeACH(true);
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
            let evt = new CustomEvent("errorCode", { detail: { flag: true } });
            window.dispatchEvent(evt);
          }

          if (activeTab == 1) {
            seterrCodeCC(true);
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
            seterrCodeACH(false);
            let evt = new CustomEvent("errorCode", { detail: { flag: true } });
            window.dispatchEvent(evt);
          }
        } else if (res.data.code == 204) {
          if (activeTab == 0) {
            setMsgModalerrorFooterACH("Source is not registered");
            setMsgModalerrorFooterCC("");
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
          } else {
            setMsgModalerrorFooterCC("Source is not registered");
            setMsgModalerrorFooterACH("");
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
          }
        } else if (res.data.code == 500) {
          if (activeTab == 0) {
            setMsgModalerrorFooterACH("Internal server error");
            setMsgModalerrorFooterCC("");
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
          } else {
            setMsgModalerrorFooterCC("Internal server error");
            setMsgModalerrorFooterACH("");
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
          }
        } else {
          let x = JSON.parse(res.data.response).error_message;
          let cds = x.split(" - ");
          if (activeTab == 0) {
            setMsgModalerrorFooterACH(cds[1]);
            setMsgModalerrorFooterCC("");
            setCvv("");
            setCardNumber("");
            seterrCodeCC(false);
            setExpirationMonth("");
            setExpirationYear("");
          } else {
            setMsgModalerrorFooterCC(cds[1]);
            setMsgModalerrorFooterACH("");
            setBankName("");
            setAccountNumber("");
            setAccountType("");
            setRoutingNumber("");
          }
        }
      })
      .catch((err) => {
        setLoader(false);
      });
  };

  return (
    <>
      <StyleWrapper>
        {loader && <ApiLoader />}
        <div className="webDisplay">
          <TransactionStyle id="program_information">
            <StyleBackWrapper to="/dashboard" className="new_back_page">
              <ArrowBackIcon />
              BACK
            </StyleBackWrapper>
            <CardWrapper
              {...ProductTileInfo}
              openChatMethod={() => window.pureChat()}
            />
          </TransactionStyle>
        </div>
        {access.control.changePaymentAccess ? (
          <div className="mobileDisplay">
            <div className="mobileCommonBody">
              <p className="description-text">
                {messages.PaymentInformationMessage}{" "}
              </p>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <table className="Last_4_digt_mobile">
                    <tr>
                      <td className="td_style1_mobile">
                        {messages.AccountNumberOnFile}
                      </td>
                      <td
                        className="td_style_mobile"
                        style={{ verticalAlign: "middle" }}
                      >
                        {location.state}
                      </td>
                    </tr>
                  </table>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={12} sm={12}>
                  <AntTabs
                    value={activeTab}
                    onChange={(event, newValue) =>
                      handleChange(event, newValue)
                    }
                    indicatorColor="primary"
                    textColor="primary"
                  >
                    <AntTab
                      label="ACH DEBIT"
                      className="ant-col-15 tabBtn tabFirst"
                      iconPosition="start"
                      icon={<img className="raidoBtn" src={firstTab} />}
                    />
                    <AntTab
                      label="CREDIT CARD"
                      className="ant-col-15 tabBtn tabSecond"
                      iconPosition="start"
                      icon={<img className="raidoBtn" src={secondTab} />}
                    />
                  </AntTabs>
                </Grid>
              </Grid>
              {activeTab == 0 ? (
                <>
                  <Grid
                    container
                    style={{
                      marginTop: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: 50,
                    }}
                  >
                    <Grid item xs={10} sm={12}>
                      <Grid container spacing={2}>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sm={4}
                          style={{ marginBottom: "-1%" }}
                        >
                          <CustomTextField
                            label={"Bank Name"}
                            value={bankName}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"Bank_Name"}
                            reqFlag={true}
                            disable={false}
                            length={120}
                            fieldType={"bank_name"}
                            errMsg={"Enter valid bank name"}
                            helperMsg={"Bank name required"}
                            parentDetails={{ label: "bank_name" }}
                            key={0}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          sm={4}
                          style={{ marginBottom: "-1%" }}
                        >
                          <CustomTextField
                            value={nameonAccount}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"Account_Name"}
                            reqFlag={true}
                            label={"Name on Account"}
                            disable={false}
                            length={120}
                            fieldType={"accountName"}
                            errMsg={"Enter valid account name"}
                            helperMsg={"Name on account required"}
                            parentDetails={{ label: "Account_Name" }}
                            key={0}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={12}
                          md={4}
                          sm={3}
                          style={{ marginBottom: "-1%" }}
                        >
                          <CommonDropDwn
                            value={accountType}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"AccountType"}
                            label={"Account Type"}
                            disable={false}
                            fieldType={"dropDwn"}
                            helperMsg={"Select account type"}
                            List={accountTypes}
                            parentDetails={{ label: "Account_Type" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3} style={{ marginTop: "1.5%" }}>
                        <Grid
                          item
                          xs={12}
                          md={6}
                          sm={5}
                          style={{ marginBottom: "-3%" }}
                        >
                          <CustomTextField
                            value={routingNumber}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"Routing_Number"}
                            MsgModalerror={MsgModalerror}
                            ErrCode={errCodeACH}
                            reqFlag={true}
                            label={"Routing Number"}
                            disable={false}
                            length={9}
                            fieldType={"routingNo"}
                            errMsg={"Enter valid routing number"}
                            helperMsg={MsgModalerror}
                            parentDetails={{ label: "Routing_Number" }}
                            key={0}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={6}>
                          <CustomTextField
                            value={accountNumber}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"Account_Number"}
                            reqFlag={true}
                            label={"Account Number"}
                            disable={false}
                            length={26}
                            fieldType={"accountNumber"}
                            errMsg={
                              "Account number must be 4 and up to 26 digits"
                            }
                            helperMsg={"Account number required"}
                            parentDetails={{ label: "Account_Number" }}
                            key={0}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>{" "}
                </>
              ) : (
                <>
                  <Grid
                    container
                    style={{
                      marginTop: "20px",
                      justifyContent: "center",
                      alignItems: "center",
                      paddingBottom: 50,
                    }}
                  >
                    <Grid item xs={10} sm={12}>
                      <Grid container spacing={3}>
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          style={{ marginBottom: "-3%" }}
                        >
                          <CustomTextField
                            value={cardNumber}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"Card_Number"}
                            MsgModalerror={MsgModalerror}
                            ErrCode={errCodeCC}
                            reqFlag={true}
                            label={"Card Number"}
                            disable={false}
                            length={16}
                            fieldType={"cardNumber"}
                            errMsg={"Card number up to 16 digits"}
                            helperMsg={"Card number required"}
                            parentDetails={{ label: "Card_Number" }}
                          />
                        </Grid>
                        <Grid item xs={12} md={6} sm={5}>
                          <CustomTextField
                            value={cardHolderName}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"Holder_Name"}
                            reqFlag={true}
                            label={"Card Holder Name"}
                            disable={false}
                            length={25}
                            fieldType={"holderName"}
                            errMsg={"Enter valid card holder name"}
                            helperMsg={"Card holder name required"}
                            parentDetails={{ label: "Holder_Name" }}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={2} style={{ marginTop: "2%" }}>
                        <Grid item xs={12} sm={4}>
                          <CommonDropDwn
                            value={expirationMonth}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"expiryMonth"}
                            label={"Expiration Month"}
                            disable={false}
                            fieldType={"dropDwn"}
                            helperMsg={"Select expiration month"}
                            errMsg={
                              "The expiration date is before today's date. Enter valid expiration month"
                            }
                            List={months}
                            parentDetails={{ label: "expiryMonth" }}
                            key={1}
                          ></CommonDropDwn>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <CommonDropDwn
                            value={expirationYear}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"expiryYear"}
                            label={"Expiration Year"}
                            disable={false}
                            fieldType={"dropDwn"}
                            helperMsg={"Select expiration year"}
                            errMsg={
                              "The expiration date is before today's date. Enter valid expiration year"
                            }
                            List={years}
                            parentDetails={{ label: "expiryYear" }}
                            key={1}
                          />
                        </Grid>
                        <Grid item xs={12} md={4} sm={3}>
                          <CustomTextField
                            value={cvv}
                            setChild={(
                              val: any,
                              isValid: any,
                              parentObj: any
                            ) => changeText(val, isValid, parentObj)}
                            name={"cvv"}
                            label={"CVV"}
                            reqFlag={true}
                            disable={false}
                            length={4}
                            fieldType={"cvv"}
                            errMsg={"Enter valid CVV"}
                            helperMsg={"CVV required"}
                            parentDetails={{ label: "cvv" }}
                            key={1}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              )}
              <div className="Bottom_Blue">
                <Grid container>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <button
                      className="ButtonBGMobile"
                      onClick={() => navigate("/Transaction")}
                    >
                      CANCEL
                    </button>
                  </Grid>
                  <Grid item xs={6} style={{ textAlign: "center" }}>
                    <button
                      className={
                        (activeTab == 0 ? activeOneDisable : activeTwoDisable)
                          ? "ButtonBG_Disable"
                          : "ButtonBGMobile"
                      }
                      disabled={
                        activeTab == 0 ? activeOneDisable : activeTwoDisable
                      }
                      onClick={() => changePaymentMethod()}
                    >
                      DONE
                    </button>
                  </Grid>
                </Grid>
              </div>
            </div>
            {(MsgModalerrorFooterACH ||
              MsgModalerrorFooterCC ||
              MsgModalerror) && (
              <Dialog
                brand={
                  config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"
                }
                show={true}
                title={"Message"}
                body={() => (
                  <p>
                    {MsgModalerrorFooterACH +
                      "" +
                      MsgModalerrorFooterCC +
                      "" +
                      MsgModalerror}
                  </p>
                )}
                CloseIcon={true}
                buttons={[
                  {
                    label: "OK",
                    size: "large",
                    variant: "text",
                    type: "button",
                    className: "OK",
                  } as CustomButtonProps,
                ]}
                handleCallback={(data: any) => {
                  setMsgModalerrorFooterACH("");
                  setMsgModalerrorFooterCC("");
                  if (
                    MsgModalerror == "Updated payment details successfully!"
                  ) {
                    navigate("/Transaction");
                    refreshState();
                  } else {
                    setMsgModalerror("");
                  }
                }}
              />
            )}
          </div>
        ) : (
          <p style={{ padding: "180px 20px", textAlign: "center" }}>
            To change your payment method, call Member Services team at :{" "}
            {getLocalStorage("CONTACT_NUMBER")} , Monday through Friday 8:00 am
            to 8:00 pm CST.{" "}
          </p>
        )}
      </StyleWrapper>
    </>
  );
};

export default ChangePaymentScreen;

const AntTab = styled(Tab)(() => ({
  textTransform: "none",
  width: "180px",
  fontSize: "14px",
  fontWeight: 500,
  letterSpacing: "0.75px",
  borderBottom: "1px solid #999999",
  backgroundColor: "#ffffff",
  "&:hover": {
    color: "#533278",
  },
  "&$selected": {
    color: "#533278",
    backgroundColor: "#533278 !important",
  },
  "&:focus": {
    color: "#533278",
    outline: "none",
  },
}));
const AntTabs = styled(Tabs)(() => ({
  borderBottom: "1px solid #533278",
  "&.MuiTabs-indicator": {
    color: "#533278",
    backgroundColor: "#533278 !important",
  },
}));
