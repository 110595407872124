import React, { useCallback, useContext } from "react";
import { RightTabs as Tabs } from "carynhealth-component-library";
import HealthActive from "../../../assests/images/uhf/my_health_icon.png";
import MyHealth from "../../../assests/images/uhf/my_health.svg";
import { AuthContext } from "../../../context/AuthenticateContext";
import Style from "../style";
import Title from "../../../config/constants/titles";
import config from "../../../config/constants/config";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getPlainIData, getRxSimpleShareData } from "../handler";
import { RightDrawer } from "../RightDrawer";
import { PopupContant } from "../PopupContant";
import Text from "../../../config/constants/messages";
import { useLocation } from "react-router-dom";
import routePaths, {
  getRoutePaths,
} from "../../../config/constants/routePaths";
import { useNavigate } from "react-router-dom";
import { getIdCardDetails } from "../../../config/routes/handlers";
import { StorageHelpers } from "carynhealth-component-library";

const routes = routePaths[config.APP_NAME as keyof typeof routePaths];

export const RightTabs = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [yellowPopup, setYellowPopup] = React.useState(true);
  const [planId, setPlanId] = React.useState("");
  const [pharmaShow, setPharmaShow] = React.useState(false);

  React.useEffect(() => {
    setPopupValue();
  }, [auth.authenticatedInfo.isAuthenticated, location.pathname]);
  React.useEffect(() => {
    console.log("===", location.pathname, getRoutePaths.aiChat);
  }, []);

  const setPopupValue = async () => {
    const email: string | any = getLocalStorage("userMail");
    let planId = StorageHelpers.getLocalStorage("planId") || "";
    let memberSSN = StorageHelpers.getLocalStorage("memberSSN") || "";
    if ((!planId || !memberSSN) && auth.authenticatedInfo.isAuthenticated) {
      await getIdCardDetails(email).then((res) => {
        planId = res.apiResponse.memberIdCardList[0].planId;
        memberSSN = res.MEMBER_SSN;
        setLocalStorage("planId", planId);
        setLocalStorage("memberSSN", memberSSN);
        setPlanId(planId);
      });
    }
    setPlanId(planId);
      if (getPlainIData(planId)) {
        setYellowPopup(false);
      } else {
        setYellowPopup(true);
      }
    
    // getSimpleShareData(memberSSN);
  };

  const getSimpleShareData = (memberSSN: any) => {
    let data = {
      memberSSN: memberSSN,
      type: "family",
    };
    if(memberSSN)
    getRxSimpleShareData(data).then((res) => {
      if (res.apiResponse && Array.isArray(res.apiResponse)) {
        res.apiResponse.map((value) => {
          if (value.addon === "UHS RxSimpleShare" && value.status === "AC") {
            setPharmaShow(true);
          }
        });
      } else {
        setPharmaShow(false);
      }
    });
  };

  const toggleMemberServices = (data: any) => {
    if (location.pathname == getRoutePaths.aiChat)
      navigate(getRoutePaths.dashboard);
    else navigate(getRoutePaths.aiChat);
  };

  if (
    (!auth.authenticatedInfo.isAuthenticated &&
      sessionStorage.getItem("USER_ACTIVE_SESSION") !== "true") ||
    config.APP_NAME == config.BRAND_NETWELL
  )
    return null;

  return (
    <Style>
      <Tabs
        data={
          config.APP_NAME === config.BRAND_INNOVATION
            ? [
                {
                  image: HealthActive,
                  text: Title.memberApps,
                  Component: (
                    <RightDrawer pharmaShow={pharmaShow} planID={planId} />
                  ),
                  style: {
                    backgroundColor:
                      yellowPopup ? "#458586" : "#989897",
                    pointerEvents: yellowPopup ? "auto" : "none",
                  },
                },
              ]
            : [
                {
                  image: HealthActive,
                  text: Title.memberApps,
                  Component: (
                    <RightDrawer pharmaShow={pharmaShow} planID={planId} />
                  ),
                  PopupComponent:
                    location.pathname === routes.dashboard && yellowPopup
                      ? PopupContant
                      : null,
                  style: {
                    backgroundColor:
                      yellowPopup || pharmaShow ? "#ff5417" : "#989897",
                    pointerEvents: yellowPopup || pharmaShow ? "auto" : "none",
                  },
                },
                {
                  image: MyHealth,
                  text:
                    location.pathname == getRoutePaths.aiChat
                      ? Title.memberServices
                      : Title.aiAssistant,
                  style: {
                    backgroundColor: "#eb5757",
                    fontSize:
                      location.pathname == getRoutePaths.aiChat
                        ? "45px"
                        : "1.3vw",
                    height: "15vw",
                  },
                },
              ]
        }
        callBack={(data: any) => toggleMemberServices(data)}
      />
    </Style>
  );
};
