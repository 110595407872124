import React, { useEffect, useState, useRef } from "react";
import SendIcon from "@mui/icons-material/Send";
import { useNavigate } from "react-router-dom";
import CryptoJS from "crypto-js";
import { encryptMemberId, getRasaAIresponse } from "../handlers";
import ChatInput from "./ConstantChat";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {
  getPharmaText,
  findIfAgentIsPresent,
  getIdCardType,
  navigation,
} from "../data";
import { Dialog } from "carynhealth-component-library";
import titles from "../../../config/constants/titles";
import messages from "../../../config/constants/messages";
import config from "../../../config/constants/config";

const MobileViewAI = () => {
  const navigateRoutes = useNavigate();
  const [dismissable, setDismissable] = useState(true);
  const [message, setMessage] = useState("");
  const divRef = useRef(null);
  const [modalIdUrl, setModalIdUrl] = useState("");
  const [loader, setLoader] = useState(false);
  const [AikbURL, setAikbURL] = useState("");
  const [errPopUp, seterrPopUp] = useState(false);
  const [chats, setChats] = useState([
    {
      answer: "Hi, I’m Caryn",
      context: `How can I help you today, ${localStorage.getItem(
        "subscriberName"
      )}?`,
      type: true,
      from: "incoming",
    },
  ]);
  useEffect(() => {
    if (sessionStorage.getItem("chathistory")) {
      let messageHistory = JSON.parse(sessionStorage.getItem("chathistory"));
      messageHistory = messageHistory.filter((i) => !i.component);
      setChats(messageHistory);
    }
  }, []);
  const sendMessage = (e) => {
    if (e !== true) e.preventDefault();
    if (message !== "") {
      let textmessages = {
        text: message,
        userID: localStorage.getItem("memberSSN"),
        ChannelUId: "memberportal",
      };
      let textmessage = {
        message: message,
        from: "outgoing",
      };
      let chat = chats.concat(textmessage);
      setChats(chat);
      setMessage("");
      setLoader(true);
      // this.fullScreen(this.state.chats.length)
      // this.scrollToBottom()
      getRasaAIresponse(textmessages)
        .then((res) => {
          recieveMessage(
            res.data.AIKBtext,
            res.data.intentRecognized,
            res.data.query,
            res.data,
            chat
          );
        })
        .catch((error) => {
          seterrPopUp(true);
          setLoader(false);
          setMessage("");
        });
      sessionStorage.setItem("chathistory", JSON.stringify(chats));
    }
  };
  const recieveMessage = (message, intent, query, response, chat) => {
    let messageDisplay = response.textfromrasa;
    let urlData = {
      memberId: localStorage.getItem("memberSSN"),
      query: query,
      ChannelUId: "memberportal",
    };

    let key = CryptoJS.enc.Utf8.parse("000102030405060708090a0b0c0d0e0f");
    let iv = CryptoJS.enc.Utf8.parse("4t7w9z$C&F)J@NcR");
    let input = CryptoJS.enc.Utf8.parse(JSON.stringify(urlData));
    let dataEncrypt = CryptoJS.AES.encrypt(input, key, {
      keySize: 256 / 32,
      iv: iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();
    let messageAskCarynJumpto = [
      {
        context: messageDisplay,
        meta: {
          url:
            process.env.REACT_APP_AIKB +
            `/?isnav=true&data=${encodeURIComponent(dataEncrypt)}`,
        },
        intent: intent,
        intentrasa: response.intentrasa,
        subintentrasa: response.subintentfromrasa,
        slots: response?.slots?.cardtype,
      },
    ];

    let chatMessages = chat.concat(messageAskCarynJumpto);

    setLoader(false);
    let chatsMessage = chatMessages;
    ChatInput.map((res) => {
      if (res.query === response.intentrasa) {
        if (
          res?.subSection?.some(
            (i) => i.subquery === response.subintentfromrasa
          )
        ) {
          chatsMessage[chatsMessage.length - 1].options = res.subSection.filter(
            (i) => i.subquery === response.subintentfromrasa
          )[0].subOption;
        } else {
          chatsMessage[chatsMessage.length - 1].options = res.options;
        }
        setChats(chatsMessage);
        sessionStorage.setItem("chathistory", JSON.stringify(chatsMessage));
      }
    });
  };
  const scrollToBottom = () => {
    divRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chats]);
  const getNavigate = (navigate, messageURL) => {
    const value = navigation(navigate, navigateRoutes);
    if (value === "Membership ID") {
      navigateRoutes("/MembershipCard");
    }
    if (value === "Health Tool") {
      navigateRoutes("/HealthToolCard");
    }
    if (value === "Search KnowledgeBase") {
      window.open(messageURL);
    }
  };
  return (
    <>
      <div className="background-full-Chat">
        <div id="chatbody" class="chatbodyMobile">
          <Message
            chat={chats}
            pharmaShow={true}
            navigate={(navigate, messageURL) =>
              getNavigate(navigate, messageURL)
            }
          />
          {loader && (
            <div
              class="spinner fixed-bottom"
              style={{ paddingBottom: 10, paddingLeft: 6 }}
            >
              <div class="bounce1"></div>
              <div class="bounce2"></div>
              <div class="bounce3"></div>
            </div>
          )}
          <div ref={divRef} />
        </div>
        <form onSubmit={sendMessage}>
          <div className="Bottom-Controls-BG">
            <div style={{ width: "85%" }}>
              <input
                placeholder="Type in your question to Caryn..."
                value={message}
                className="inputBoxChat"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
            <div className="search-icon-mobile">
              <SendIcon
                style={{ color: message ? "blue" : "black" }}
                onClick={() => sendMessage(true)}
              />
            </div>
          </div>
        </form>
      </div>
      {errPopUp && (
        <Dialog
          brand={config.APP_NAME === config.BRAND_NETWELL ? "NETWELL" : "UHF"}
          show={errPopUp}
          title={titles.someThingWentWrong}
          body={() => <p>{messages.errMessageAiAssistant}</p>}
          CloseIcon={true}
          buttons={[
            {
              label: "OK",
              size: "large",
              variant: "text",
              type: "button",
              className: "OK",
            },
          ]}
          handleCallback={(data) => {
            seterrPopUp(false);
          }}
        />
      )}
    </>
  );
};

export default MobileViewAI;

const Message = ({ chat, contactNo, pharmaShow, navigate }) => {
  return chat.map((message, i) => (
    <>
      <MessageText
        message={message}
        contactNo={contactNo}
        pharmaShow={pharmaShow}
      />

      <div style={_.flexWrap} id="2">
        <div id="2.5" className="scrolling-wrapper">
          {message.options && (
            <div style={_.flexWrap}>
              {message.options.map((intent) => {
                if (message.intentrasa === "pharma") {
                  if (message.subintentrasa === "pharma/signup") {
                    if (pharmaShow) {
                      if (
                        intent.intent === "RxSimpleShareFormuary" ||
                        intent.intent === "pharma"
                      ) {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  } else if (message.subintentrasa === "pharma/cancel") {
                    if (pharmaShow) {
                      if (intent.intent === "Email") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (
                        intent.intent === "program information" ||
                        intent.intent === "RxSimpleShareFormuary" ||
                        intent.intent === "RxSimpleShareProgram"
                      ) {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  } else if (message.subintentrasa === "pharma/medicines") {
                    if (pharmaShow) {
                      if (
                        intent.intent === "RxSimpleShareFormuary" ||
                        intent.intent === "pharma"
                      ) {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (
                        intent.intent === "program information" ||
                        intent.intent === "RxSimpleShareFormuary" ||
                        intent.intent === "RxSimpleShareProgram"
                      ) {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  }
                } else if (message.intentrasa === "healthtool") {
                  if (message.subintentrasa === "healthtool/signup") {
                    if (JSON.parse(localStorage.getItem("HealthTool"))) {
                      if (intent.intent === "Health Tool") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  } else if (message.subintentrasa === "healthtool/cancel") {
                    if (JSON.parse(localStorage.getItem("HealthTool"))) {
                      if (intent.intent === "Email") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  } else {
                    if (JSON.parse(localStorage.getItem("HealthTool"))) {
                      if (intent.intent === "Health Tool") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  }
                } else if (message.intentrasa === "rxsimpleshare") {
                  if (message.subintentrasa === "rxsimpleshare/signup") {
                    if (pharmaShow) {
                      if (
                        intent.intent === "RxSimpleShareFormuary" ||
                        intent.intent === "pharma"
                      ) {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  } else if (message.subintentrasa === "rxsimpleshare/cancel") {
                    if (pharmaShow) {
                      if (intent.intent === "Email") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  } else {
                    if (pharmaShow) {
                      if (
                        intent.intent === "RxSimpleShareFormuary" ||
                        intent.intent === "pharma"
                      ) {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    } else {
                      if (intent.intent === "program information") {
                        return (
                          <Addon
                            message={message}
                            intent={intent}
                            navigate={navigate}
                          />
                        );
                      }
                    }
                  }
                }
                if (
                  // message.intentrasa !== "idcard" &&
                  message.intentrasa !== "healthtool" &&
                  message.intentrasa !== "rxsimpleshare" &&
                  message.intentrasa !== "pharma"
                ) {
                  return (
                    <>
                      <Addon
                        message={message}
                        intent={intent}
                        navigate={navigate}
                      />
                    </>
                  );
                }
              })}
            </div>
          )}
        </div>
      </div>
    </>
  ));
};
const Addon = ({ intent, navigate, message }) => {
  if (
    (intent.intent === "telemed" ||
      intent.intent === "provider_find_telemed" ||
      intent.intent === "find_provider") &&
    (localStorage.getItem("planId") == "10051" ||
      localStorage.getItem("planId") == "10052" ||
      localStorage.getItem("planId") == "10053")
  )
    return <></>;
  else
    return (
      <div
        class="intentbubble"
        onClick={() => navigate(intent.intent, message.meta.url)}
        style={_.pharmaStyle}
      >
        <p>{intent.message}</p> <ChevronRightIcon style={_.f35} />
      </div>
    );
};
const MessageText = ({ message, pharmaShow }) => {
  let messageText = message.message ? message.message : message.context;

  if (message?.intentrasa === "pharma") {
    messageText = getPharmaText(pharmaShow, messageText, message);
  }

  if (message?.intentrasa === "healthtool") {
    if (message.subintentrasa === "healthtool/signup") {
      if (JSON.parse(localStorage.getItem("HealthTool")) === true) {
        messageText =
          "You already have UHS Health Tools added to your membership.";
      } else {
        messageText =
          "With UHS Health Tools, you can get telephonic counseling for a wide variety of needs, and dental and vision discount programs. To add Health Tools to your membership, go to Program Information and click Change Add-ons. You will be automatically signed into the Enrollment Portal, where you can review available add-ons and choose what's best for you.";
      }
    } else if (message.subintentrasa === "healthtool/cancel") {
      if (JSON.parse(localStorage.getItem("HealthTool")) === true) {
        messageText =
          "To remove Health Tools from your membership, please contact our Member Services team at <Member Services Phone>, Monday through Friday 7:00 am to 6:00 pm CST or email at <Member Services Email>.";
      } else {
        messageText =
          "You do not have UHS Health Tools added to your membership.";
      }
    } else if (JSON.parse(localStorage.getItem("HealthTool")) === true) {
      messageText =
        "You already have UHS Health Tools added to your membership.";
    } else {
      messageText =
        "With UHS Health Tools, you can get telephonic counseling for a wide variety of needs, and dental and vision discount programs. To add Health Tools to your membership, go to Program Information and click Change Add-ons. You will be automatically signed into the Enrollment Portal, where you can review available add-ons and choose what's best for you.";
    }
  }

  if (message?.intentrasa === "rxsimpleshare") {
    if (message.subintentrasa === "rxsimpleshare/signup") {
      if (pharmaShow) {
        messageText =
          "You already have RxSimpleShare added to your membership.";
      } else {
        messageText =
          "To add RxSimpleShare to your membership, go to Program Information and click Change Add-ons. You will be automatically signed into the Enrollment Portal, where you can review available add-ons and choose what's best for you.";
      }
    } else if (message.subintentrasa === "rxsimpleshare/cancel") {
      if (pharmaShow) {
        messageText =
          "To remove RxSimpleShare from your membership, please contact our Member Services team at <Member Services Phone>, Monday through Friday 7:00 am to 6:00 pm CST or email at <Member Services Email>.";
      } else {
        messageText = "You do not have RxSimpleShare added to your membership.";
      }
    } else if (pharmaShow) {
      messageText = "You already have RxSimpleShare added to your membership.";
    } else {
      messageText =
        "To add RxSimpleShare to your membership, go to Program Information and click Change Add-ons. You will be automatically signed into the Enrollment Portal, where you can review available add-ons and choose what's best for you.";
    }
  }

  messageText = messageText.replaceAll(
    "My Transactions",
    "<b>My Transactions</b>"
  );
  messageText = messageText.replaceAll(
    "Change Payment Method",
    "<b>Change Payment Method</b>"
  );
  messageText = messageText.replaceAll(
    "Change Billing Date",
    "<b>Change Billing Date</b>"
  );
  messageText = messageText.replaceAll(
    "Program Information",
    "<b>Program Information</b>"
  );
  messageText = messageText.replaceAll(
    "Sharing Guidelines",
    "<b>Sharing Guidelines</b>"
  );
  messageText = messageText.replaceAll(
    "Change Add-ons",
    "<b>Change Add-ons</b>"
  );
  messageText = messageText.replaceAll(
    "Change Dependents",
    "<b>Change Dependents</b>"
  );
  messageText = messageText.replaceAll("NSA/ACSM", "<b>NSA/ACSM</b>");
  messageText = messageText.replaceAll(
    "UHS Health Tools",
    "<b>UHS Health Tools</b>"
  );
  messageText = messageText.replaceAll(
    "RxSimpleShareFormuary",
    "<b>RxSimpleShareFormuary</b>"
  );
  messageText = messageText.replaceAll(
    "Health Questionnaire",
    "<b>Health Questionnaire</b>"
  );
  messageText = messageText.replaceAll("My Needs", "<b>My Needs</b>");
  messageText = messageText.replaceAll(
    "Documents Card",
    "<b>Documents Card</b>"
  );
  messageText = messageText.replaceAll(
    "Read More",
    "<b>Search Knowledge Base</b>"
  );
  messageText = messageText.replaceAll(
    "<Member Services Phone>",
    "1 (888) 366 6243"
  );
  messageText = messageText.replaceAll(
    "Here is your <ID Card Type>",
    getIdCardType(message?.slots || "", pharmaShow)
  );
  messageText = messageText.replaceAll(
    "<Member Services Email>",
    "customerservice@universalhealthfellowship.org"
  );
  messageText = messageText.replaceAll(
    "<Member First Name>",
    `${localStorage.getItem("subscriberName").split(" ")[0]}`
  );

  if (
    localStorage.getItem("planId") == "10051" ||
    localStorage.getItem("planId") == "10052" ||
    localStorage.getItem("planId") == "10053"
  ) {
    messageText = messageText.replaceAll(
      "You can talk to a doctor 24/7 for $0 using our Telemedicine App.",
      `Telemedicine App is not available for this plan.`
    );
    messageText = messageText.replaceAll(
      "Use the Find a Provider feature on the Member App to find an In-Network doctor closest to you. Alternatively, you can talk to a doctor 24/7 for $0 using our Telemedicine App.",
      `Find a Provider is not available for this plan.`
    );
  }

  if (findIfAgentIsPresent()) {
    messageText = messageText.replaceAll(
      "<Agent Phone>",
      localStorage.getItem("AgentDetails")
        ? `${JSON.parse(localStorage.getItem("AgentDetails")).phone}`
        : ""
    );
    messageText = messageText.replaceAll(
      "<Agent Email>",
      localStorage.getItem("AgentDetails")
        ? `${JSON.parse(localStorage.getItem("AgentDetails")).email}`
        : ""
    );
    messageText = messageText.replaceAll(
      "<Agent Name>",
      localStorage.getItem("AgentDetails")
        ? `${JSON.parse(localStorage.getItem("AgentDetails")).name}`
        : ""
    );
  } else if (
    !findIfAgentIsPresent() &&
    message.context &&
    messageText.includes("<Agent Phone>")
  ) {
    messageText = "Agent is not available";
  }

  return (
    <div class={message.context ? "incoming" : "outgoing"}>
      <div
        class={messageText.trim() !== "" ? "bubble" : ""}
        style={_.whiteSpace}
      >
        <div style={_.flexRow}>
          <p className="searchfont">
            <p dangerouslySetInnerHTML={{ __html: messageText }}></p>
            <p className="component"> {message?.component || null}</p>
          </p>
        </div>
        {/* <div class="bubble">{ContactInformationWidget()}</div> */}
      </div>
    </div>
  );
};

const _ = {
  cursorStyle: { cursor: "context-menu" },
  whiteSpace: { whiteSpace: "pre-line" },
  flexRow: { display: "flex", flexDirection: "row" },
  flexWrap: { display: "flex" },
  pharmaStyle: {
    backgroundColor: "#2da5c8",
    color: "#fff",
    marginRight: 5,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    maxWidth: "fit-content",
  },

  f35: { fontSize: "35px" },
};
