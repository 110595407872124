import { styled } from "@mui/system";
import { getLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";
import { getPlainIData } from "../../../components/RightTopMemberTabs/handler";
import config from "../../../config/constants/config";

export const DashboardStyleWrapper = styled("section")(({ theme }) => {
  return {
    padding:
      config.APP_NAME === config.BRAND_INNOVATION
        ? "30px 40px 30px"
        : "0 15px 30px",
    "#dashboard-notifications": {
      display: "flex",
      flexWrap: "wrap",
      gap: "1.2vw",
      position: "relative",
    },
    ".notifications-UHF .MuiCardContent-root": {
      background: "rgba(234,232,219,.4)",
    },
    "#product-tiles": {
      flexBasis: "100%",
      gap: 14,
      ".MuiCardContent-root": {
        background: "white",
      },
    },

    // Responsive
    [theme.breakpoints.up(1440)]: {
      "#dashboard-notifications": {
        justifyContent: "flex-start",
        gap: "3.3vw",
      },
    },
    [theme.breakpoints.down(1350)]: {
      "#product-tiles": {
        flexBasis: "100%",
      },
    },
    [theme.breakpoints.down(1024)]: {
      paddingTop: 20,
      "#product-tiles": {
        flexBasis: "100%",
        justifyContent: "center",
      },
      "#dashboard-notifications": {
        flexDirection: "column",
        justifyContent: "center",
        ">.MuiPaper-root": {
          maxWidth: "100%",
        },
      },
    },

    [theme.breakpoints.down(763)]: {
      "#dashboard-notifications": {
        justifyContent: "center",
      },
      ".notifications-UHF": {
        paddingTop: 70,
      },
      ".notifications-INNOVATION": {
        paddingTop: 50,
      },
      ".notifications-NETWELL": {
        // paddingTop: 30,
      },
      "#product-tiles": {
        justifyContent: "center",
      },
    },
  };
});
