import { apiService, StorageHelpers } from "carynhealth-component-library";
import { useContext } from "react";
import config from "../../config/constants/config";
import messages from "../../config/constants/messages";
import { AuthContext } from "../../context/AuthenticateContext";
import axios from "axios";
import { setLocalStorage } from "carynhealth-component-library/dist/cjs/helpers/storage";

export const getGateWayToken: any = async () => {
  try {
    let request = {
      username: process.env.REACT_APP_GETWAY_TOKEN_USERNAME,
      password: process.env.REACT_APP_GETWAY_TOKEN_PASS,
    };
    let payload = {
      data: request,
      URL: config.URLS.baseAPI + config.URLS.loginForhipaa,
      brand: config.BRAND_IP_AEC,
      disableConfig: true,
    };
    const data = await apiService.default.post(payload);
    let bearer = data["headers"].authorization as string;
    var array = bearer.split("Bearer ");
    setLocalStorage("bearerToken", array[1]);
    return array;
  } catch (error: any) {
    console.log("error", error);

    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
export const getDecryptIdAPI: any = async (val: any) => {
  let payload = {
    data: {
      memberId: decodeURIComponent(val),
    },
    URL: config.URLS.baseAPI + "v1/csrportal/iddecryption",
    brand: config.BRAND_IP_AEC,
  };
  const data = await apiService.default.post(payload);
  return data;
};
export const getIdCardDetails = async (email?: string) => {
  const emailId = email || StorageHelpers.getLocalStorage("userMail");
  try {
    const request = {
      URL: config.URLS.baseAPI + config.URLS.getMemberIdCard,
      brand: config.BRAND_IP_AEC,
      data: { email: emailId },
    };
    const { data: apiResponse } = await apiService.default.post(request);
    if (localStorage.getItem("SOURCE") === "NEO") {
      localStorage.setItem(
        "Member_EMPID",
        apiResponse?.memberIdCardList?.[0]?.memberId || ""
      );
    }
    const MEMBER_SSN = apiResponse?.memberIdCardList[0]?.memberSSN || "";
    StorageHelpers.setLocalStorage("memberSSN", MEMBER_SSN);
    return {
      apiResponse,
      MEMBER_SSN,
      errorMessage: messages.SOMETHING_WRONG,
    };
  } catch (error: any) {
    console.log("error", error);
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};
