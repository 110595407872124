import { apiService, StorageHelpers } from "carynhealth-component-library";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";
import config from "../../../config/constants/config";
import messages from "../../../config/constants/messages";
import { tilesInfo } from "./data";
import { Auth } from "aws-amplify";
import { getPlainIData } from "../../RightTopMemberTabs/handler";
import { getIdCardDetails } from "../../../config/routes/handlers";

export const getProductTiles = async () => {
  if (config.APP_NAME === config.BRAND_NETWELL)
    return {
      tiles: Object.values(tilesInfo.NETWELL),
      errorMessage: "",
    };
  if (config.APP_NAME === config.BRAND_INNOVATION)
    return {
      tiles: Object.values(tilesInfo.INNOVATION),
      errorMessage: "",
    };
  try {
    const isNetwell = config.APP_NAME === config.BRAND_NETWELL;
    const id = isNetwell
      ? config.DEFAULT_VALUES.NETWELL_TILES_ID
      : getLocalStorage("CLIENT_ID");
    const request = {
      URL: config.URLS.baseV7API + config.URLS.memberPortal + id,
      brand: config.BRAND_IP_AEC,
    };
    const { data: apiResponse } = await apiService.default.get(request);

    /** Define tiles array if tile name exists in API Response */
    const getTileNames = apiResponse?.response?.map(
      (tile: any) => tile.cardtitle
    );
    const brandRelatedTiles: any | [] = [];

    getTileNames.forEach((tile: string) => {
      const data = (
        tilesInfo[config.APP_NAME as keyof typeof tilesInfo] as any
      )[tile];
      if (data) {
        brandRelatedTiles.push(data);
      }
    });

    return {
      tiles: brandRelatedTiles,
      errorMessage: "",
    };
  } catch (error: any) {
    console.log(error);
    return Promise.reject({
      errorMessage: messages.SOMETHING_WRONG,
    });
  }
};

export const getUserEmail = async () => {
  const user = await Auth.currentUserInfo();
  if(user && localStorage.getItem("userMail") === null)
  setLocalStorage("userMail", user.attributes["email"]);
};

export const encryptMemberId = async (encodeString: any) => {
  let obj = {
    memberId: `memberid=${localStorage.getItem(
      "sourceid"
    )}&type=&email=${localStorage.getItem("userMail")}`,
  };
  let request = {
    URL: config.URLS.baseAPI + "v1/csrportal/idencryption",
    data: obj,
    brand: config.BRAND_IP_AEC,
  };
  try {
    var response = await apiService.default.post(request);
    return response.data;
  } catch (err) {
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    console.log("Internal server error.");
  }
};

export const getRxSimpleShareData = async () => {
  let memberSSN = StorageHelpers.getLocalStorage("memberSSN");
  if (!memberSSN) {
    memberSSN = (await getIdCardDetails()).MEMBER_SSN;
  }
  let data = {
    type: "family",
    memberSSN,
  };
  try {
    const URL = `${config.URLS.baseAPI}${config.URLS.getCSRAddOns}`;
    const { data: apiResponse } = await apiService.default.post({
      URL,
      brand: config.BRAND_IP_AEC,
      data,
    });

    let counterHealthTool = 0;
    let counterRxSimpleShare = 0;
    let isAddonToolExists: any = [
      "UHS Health Tools",
      "Advanced Health Tools",
      "Health Tools",
    ].map((tool: string) => tool?.toLowerCase());
    let isShareExists: any = [];
    let BoolisAddonToolExists = false;
    setLocalStorage("addOnsData", JSON.stringify(apiResponse));
    apiResponse.forEach((item: any) => {
      isShareExists = ["UHS RxSimpleShare", "RxSimpleShare"].map(
        (tool: string) => tool?.toLowerCase()
      );
      BoolisAddonToolExists = false;
      if (isAddonToolExists.includes(item?.addon?.toLowerCase())) {
        BoolisAddonToolExists = true;
      }
      isShareExists = isShareExists.includes(item?.addon?.toLowerCase());
      isAddonToolExists && counterHealthTool++;
      isShareExists && counterRxSimpleShare++;
    });

    const findRxSimpleData = apiResponse.find(
      (obj: any) =>
        ["UHS RxSimpleShare", "RxSimpleShare"].includes(obj.addon) &&
        obj.status === "AC"
    );
    const findHealToolData = apiResponse.find(
      (obj: any) =>
        ["UHS Health Tools", "Advanced Health Tools", "Health Tools"].includes(
          obj.addon
        ) && obj.status === "AC"
    );
    localStorage.setItem(
      "HealthTool",
      JSON.stringify(Boolean(findHealToolData))
    );
    return {
      rxSimpleShareActive: Boolean(findRxSimpleData),
      counterRxSimpleShare,
      healthToolAddonActive: Boolean(findHealToolData),
      counterHealthTool,
      addOns: apiResponse,
    };
  } catch (error: any) {
    console.log("Error: getRxSimpleShareData ", error);
    return Promise.reject({
      message: error?.message || messages.SOMETHING_WRONG,
    });
  }
};
