import React from "react";
import Box from "@mui/system/Box";
import { InteractiveList } from "carynhealth-component-library";
import AlertNotification from "../../assests/images/notification/notification_alert_icon.svg";
import AnnouncementNotification from "../../assests/images/notification/notification_announcement_icon.svg";
import PromoNotification from "../../assests/images/notification/notification_promo_icon.svg";
import ReminderNotification from "../../assests/images/notification/notification_reminder_icon.svg";
import SurveyNotification from "../../assests/images/notification/notification_survey_icon.svg";
import UpdateNotification from "../../assests/images/notification/notification_update_icon.svg";

const icons = {
  ANNOUNCEMENT: AnnouncementNotification,
  PROMOTIONAL: PromoNotification,
  SURVEY: SurveyNotification,
  UPDATE: UpdateNotification,
  ALERT: AlertNotification,
  REMINDER: ReminderNotification,
};

const data = [
  {
    notificationID: "22",
    urlPath: "/notification",
    type: "ANNOUNCEMENT",
    title: "ANNOUNCEMENT",
    created_date: "06-03-2023",
    message: "Your documents method has been changed.",
    image: "",
  },
  {
    notificationID: "22",
    urlPath: "/notification",
    type: "PROMOTIONAL",
    title: "PROMOTIONAL",
    created_date: "06-03-2023",
    message: "Your documents method has been changed.",
    image: "",
  },
  {
    notificationID: "22",
    urlPath: "/notification",
    type: "SURVEY",
    title: "SURVEY",
    created_date: "06-03-2023",
    message: "Your documents method has been changed.",
    image: "",
  },
  {
    notificationID: "22",
    urlPath: "/notification",
    type: "UPDATE",
    title: "UPDATE",
    created_date: "06-03-2023",
    message: "Your documents method has been changed.",
    image: "",
  },
  {
    notificationID: "22",
    urlPath: "/notification",
    type: "ALERT",
    title: "ALERT",
    created_date: "06-03-2023",
    message: "Your documents method has been changed.",
    image: "",
  },
  {
    notificationID: "22",
    urlPath: "/notification",
    type: "REMINDER",
    title: "REMINDER",
    created_date: "06-03-2023",
    message: "Your documents method has been changed.",
    image: "",
  },
];

const Notifications: React.FC<any> = (props) => {
  const notifications =
    data.map((notification: any) => {
      return {
        label: notification.title,
        labelRight: notification.created_date,
        description: notification.message,
        image: icons[notification.type as keyof typeof icons],
        disable: false,
      };
    }) || [];

  return (
    <Box sx={{ maxHeight: "30vh", overflow: "auto" }}>
      <InteractiveList list={notifications} onClick={console.log} />
    </Box>
  );
};

export default Notifications;
