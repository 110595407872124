import { apiService } from "carynhealth-component-library";
import config from "../../config/constants/config";
import {
  getLocalStorage,
  setLocalStorage,
} from "carynhealth-component-library/dist/cjs/helpers/storage";

export const getUserTransactionDetails = async () => {
  var sourceid = getLocalStorage("sourceid");
  var emailID = getLocalStorage("userMail");
  try {
    if (getLocalStorage("SOURCE") === "NEO") {
      const request = {
        URL:
          process.env.REACT_APP_API_V7_BASE_URL +
          config.URLS.getMytransactionReport +
          emailID,
      };
      const response = await apiService.default.get(request);
      return response;
    } else {
      const request = {
        URL:
          process.env.REACT_APP_MY_TRANSACTION +
          config.URLS.getMytransactionHistory +
          `?searchKey=source&searchValue=${sourceid}&orderKey=createdDate&order=desc`,
        brand: config.BRAND_IP_AEC,
        disableConfig: true,
      };
      const response = await apiService.default.get(request);
      return response;
    }
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const getPaymentTpye = async () => {
  var sourceid = getLocalStorage("sourceid");
  try {
    const request = {
      URL:
        process.env.REACT_APP_MY_TRANSACTION +
        config.URLS.getMemberAmount +
        `${sourceid}`,
    };
    const response = await apiService.default.get(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const getLastDigitAccountNumber = async () => {
  var sourceid = getLocalStorage("sourceid");
  try {
    const request = {
      URL:
        process.env.REACT_APP_MY_TRANSACTION +
        config.URLS.getLast4AccountNumber +
        `${sourceid}`,
    };
    const response = await apiService.default.get(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};
export const getEmployerAndHouseholdDetails = async () => {
  var clientId = getLocalStorage("CLIENT_ID");
  let obj = {
    clientId: clientId,
  };
  try {
    const request = {
      URL: process.env.REACT_APP_API_BASE_URL + "v14/enrollment/getClient",
      data: obj,
    };
    const response = await apiService.default.post(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const getReccuringDate = async () => {
  var sourceid = getLocalStorage("sourceid");
  try {
    const request = {
      URL:
        process.env.REACT_APP_MY_TRANSACTION +
        config.URLS.getRecurringDate +
        `${sourceid}`,
    };
    const response = await apiService.default.get(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const storeTransaction = async (obj: Object) => {
  try {
    const request = {
      URL:
        process.env.REACT_APP_MY_TRANSACTION + `transaction/storeTransaction`,
      data: obj,
    };
    const response = await apiService.default.post(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};

export const changeMyBillingDate = async () => {
  let obj = {
    Description: "Request to change billing date",
    Subject: getLocalStorage("sourceid"),
    Type: "Account Update",
  };
  try {
    const request = {
      URL: process.env.REACT_APP_API_BASE_URL + `v1/memberportal/caseCreation`,
      data: obj,
    };
    const response = await apiService.default.post(request);
    return response;
  } catch (error: any) {
    console.log(error);
    return error;
  }
};
