import React from "react";
import StyleWrapper from "./style";
import { useNavigate } from "react-router-dom";
import { getRoutePaths } from "../../config/constants/routePaths";
import {
  Box,
  Button,
  Link,
  MobileStepper,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
  useMediaQuery,
  Fab,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Text from "../../config/theme/uhf";
import {
  ProgramInfoFooter,
  useCommonLoader,
} from "carynhealth-component-library";
import { CustomButtonProps } from "carynhealth-component-library/dist/cjs/components/Button";
import { PersonalQues } from "../../components/HealthQuesPages/PersonalQues";
import { getHealthQuestionData } from "./handlers";
import config from "../../config/constants/config";
import moment from "moment";
import { buttonProps } from "./data";
import ForumIcon from "@mui/icons-material/Forum";
import messages from "../../config/constants/messages";

const HealthQuestionnaire = () => {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const { setLoading, unSetLoading } = useCommonLoader();
  const [count, setCount] = React.useState(-1);
  const [questionList, setquestionList] = React.useState([]);
  const [userList, setUserList] = React.useState([]);
  const [instructionSet, setInstructionSet] = React.useState([]);
  const [show, setShow] = React.useState(true);
  const [familyData, setFamilyDate] = React.useState({
    id: 1670840691994,
    subscriberUniqueId: "1667274017651",
    memberUniqueId: "1668408528942",
    firstName: "Spouse",
    lastName: "Thrive",
    dob: 406060200000,
    gender: "F",
    completionStatus: 4,
    lastUpdatedDate: "2023-01-26T14:14:13.000+0000",
    authorize: true,
    privacyPolicy: true,
    email: "",
    fullName: "Spouse Thrive",
  });
  const [quesSaveArray, setQuesSaveArray] = React.useState<any>([]);
  const [quseBtn, setQuesBtn] = React.useState(buttonProps.QuesStep);
  const [isView, setIsView] = React.useState(false);
  const theme = useTheme();
  const showText = useMediaQuery(theme.breakpoints.down("md"));

  React.useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    await getQuestionList();
  };

  const getQuestionList = async () => {
    setLoading();
    try {
      let { apiResponse } = await getHealthQuestionData(
        config.URLS.getQuestion,
        "get"
      );
      if (apiResponse?.response?.questionList?.length > 0) {
        setquestionList(apiResponse.response.questionList);
      }
      if (apiResponse?.response?.instructionSet?.length > 0) {
        setInstructionSet(apiResponse.response.instructionSet);
      }
      getUserState(apiResponse.response.questionList);
    } catch (e) {
      console.log(e);
      unSetLoading();
    }
  };

  const getUserState = async (queslist: any) => {
    let payLoad = {
      subscriberIdSource: localStorage.getItem("sourceid") || "1667274017651",
    };
    try {
      let { apiResponse } = await getHealthQuestionData(
        config.URLS.getHeathInfoPopup,
        "post",
        payLoad
      );
      if (apiResponse?.response?.length > 0) {
        setUserList(apiResponse.response);
      }
      unSetLoading();
    } catch (e) {
      console.log(e);
      unSetLoading();
    }
  };

  const mapQuestionResponse = async (data: any, queslist: any, index: any) => {
    let userQuest = data[index].healthQuestions;
    let temp: any[] = [];

    queslist.forEach((element: any) => {
      element?.question?.reltdQstnID.forEach((v: any) => {
        v.id = null;
        v.response = "";
        v.questionTypeCode = element.question.questionTypeCode;
        // removeObjectProperties(v, [
        //   "activeFlag",
        //   "type",
        //   "subType",
        //   "createDate",
        //   "displayIndex",
        // ]);
      });
      let obj: any = {
        options: element.options,
        id: null,
        questionID: element.question.questionID,
        responseTypCode: element.question.responseTypCode,
        response: "",
        questionTypCode: element.question.questionTypeCode,
        questionDesc: element.question.questionDesc,
        reltdQstnID: element.question.reltdQstnID,
      };
      if (userQuest.length > 0) {
        let savedQues = userQuest.find(
          (x: any) => x.questionID == element.question.questionID
        );
        if (savedQues) {
          obj = {
            options: element.options,
            id: savedQues.id,
            questionID: savedQues.questionID,
            responseTypCode: savedQues.responseTypCode,
            response: savedQues.response,
            questionTypCode: element.question.questionTypeCode,
            questionDesc: savedQues.questionDesc,
            reltdQstnID: savedQues.reltdQstnID,
          };
        }
      }
      temp.push(obj);
      console.log(temp);
    });
    setQuesSaveArray([...temp]);
    setFamilyDate({
      id: data[index].id,
      subscriberUniqueId: data[index].subscriberUniqueId,
      memberUniqueId: data[index].memberUniqueId,
      firstName: data[index].firstName,
      lastName: data[index].lastName,
      dob: data[index].dob,
      gender: data[index].gender,
      completionStatus: data[index].completionStatus,
      lastUpdatedDate: data[index].lastUpdatedDate,
      authorize: data[index].authorize,
      privacyPolicy: data[index].privacyPolicy,
      email: data[index].email,
      fullName: data[index].fullName,
    });
    console.log(quesSaveArray);
  };

  const getButton = (item: any, index: number) => {
    switch (item.completionStatus) {
      case 0:
        return (
          <>
            <Button
              variant="outlined"
              className="Status_StartBtn"
              onClick={() => openStateView(item.completionStatus, index)}
            >
              START
            </Button>
          </>
        );
        break;
      case 4:
        return item.authorize ? (
          <>
            <Button
              variant="outlined"
              className="Status_StartBtn"
              onClick={() => openStateView(0, index)}
            >
              EDIT
            </Button>

            <Button
              variant="outlined"
              className="Status_StartBtn viewBtn"
              onClick={() => openStateView(0, index, true)}
            >
              VIEW
            </Button>
          </>
        ) : (
          <>
            <Button
              variant="outlined"
              className="Status_StartBtn"
              onClick={() => openStateView(item.completionStatus, index)}
            >
              RESUME
            </Button>
          </>
        );
        break;
      default:
        return (
          <>
            <Button
              variant="outlined"
              className="Status_StartBtn"
              onClick={() => openStateView(item.completionStatus, index)}
            >
              RESUME
            </Button>
          </>
        );
        break;
    }
  };

  const goNext = () => {
    if (count >= 4) {
      setCount(-1);
      setIsView(false);
      let btn = quseBtn;
      btn[1].label = "NEXT";
      setQuesBtn(buttonProps.QuesStep);
    } else {
      setCount((current) => {
        return current + 1;
      });
    }
  };

  const openStateView = (state: number, index: number, isview?: boolean) => {
    setLoading();
    if (isview) {
      setIsView(isview);
      setQuesBtn(buttonProps.ViewStep);
    } else {
      setIsView(false);
      setQuesBtn(buttonProps.QuesStep);
    }
    mapQuestionResponse(userList, questionList, index);
    setCount(state);
    unSetLoading();
  };

  const setQuestionData = async () => {
    quesSaveArray.forEach(function (key: any) {
      delete key.options;
    });
    quesSaveArray[8].response = quesSaveArray[8].response.toString();
    console.log(quesSaveArray);
    familyData.completionStatus = count;
    let obj = [
      {
        ...familyData,
        healthQuestions: quesSaveArray,
      },
    ];
    console.log("savearr", obj);
    try {
      setLoading();
      let { apiResponse } = await getHealthQuestionData(
        config.URLS.getSaveHealthQues,
        "post",
        obj
      );
      if (apiResponse?.response) {
        console.log(apiResponse.response);
        setCount(-1);
        disableNextBtn(true);
        getData();
      }
      unSetLoading();
    } catch (e) {
      console.log(e);
      unSetLoading();
    }
  };

  const footerButtonHandler = (data: any) => {
    switch (data) {
      case "NEXT":
        goNext();
        break;
      case "BACK":
        onClickBack();
        break;
      case "FinishLater":
        setQuestionData();
        break;
      case "BackToDashboard":
        navigate(getRoutePaths.dashboard);
        break;
      default:
        return "";
    }
  };

  const onClickBack = () => {
    let i = count - 1;
    setCount(i);
    disableNextBtn(false);
    disableFinishBtn(false, i);
  };
  const disableNextBtn = (value: any) => {
    let btn = quseBtn;
    btn[1].disabled = value;
    setQuesBtn([...btn]);
  };

  const disableFinishBtn = (value: any, num: number | 0) => {
    let btn = quseBtn;
    if (!isView) {
      btn[2].disabled = value;
      if (num >= 4) {
        btn[2].label = "FINISH";
      } else {
        btn[2].label = "Finish Later";
      }
    } else {
      btn[1].disabled = value;
      if (num >= 4) {
        btn[1].label = "FINISH";
      } else {
        btn[1].label = "NEXT";
      }
    }
    setQuesBtn([...btn]);
  };

  function removeObjectProperties(v: any, arg1: string[]) {
    for (var i = 0; i < arg1.length; i++) {
      if (v.hasOwnProperty(arg1[i])) {
        delete v[arg1[i]];
      }
    }
  }

  return (
    <StyleWrapper>
      <div className="progInfoContainer">
        <span className="Back_page_uhf">
          <Link href="/dashboard">
            <ArrowBackIcon
              style={{
                width: 24,
                height: 24,
                color: "#4782c4",
                marginRight: 5,
              }}
            />
            BACK
          </Link>
        </span>
        <div className="commonWrap">
          <h2 className="progInfoTitle">{Text.uhf.healthQueTitle}</h2>
          {count < 0 ? (
            <>
              <text>{Text.uhf.healthQueText}</text>
              <Box sx={{ width: "100%" }}>
                <div className="enrolledTableClass">
                  <Paper className="tableContainer">
                    <TableContainer>
                      <Table
                        className="mainTable "
                        aria-labelledby="tableTitle"
                        size="medium"
                        aria-label="enhanced table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{ width: "310px" }}
                              className="innerTh"
                            >
                              NAME
                            </TableCell>
                            <TableCell
                              style={{ width: "310px" }}
                              className="innerTh"
                            >
                              COMPLETION STATUS
                            </TableCell>
                            <TableCell></TableCell>
                            {userList?.some(
                              (x: any) => x.lastUpdatedDate !== null
                            ) ? (
                              <TableCell className="innerTh">
                                LAST UPDATED
                              </TableCell>
                            ) : (
                              <></>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {userList?.map((item: any, index: number) => (
                            <TableRow key={item.id}>
                              <TableCell className="TableCell">
                                {`${item.firstName} ${item.lastName}`}
                              </TableCell>
                              <TableCell>
                                <MobileStepper
                                  variant="progress"
                                  steps={5}
                                  activeStep={item.completionStatus}
                                  className="ProgressBar"
                                  sx={{
                                    maxWidth: 400,
                                    flexGrow: 1,
                                    ".MuiLinearProgress-bar": {
                                      backgroundColor:
                                        item.completionStatus === 4
                                          ? "#27ae60"
                                          : "#420045",
                                    },
                                  }}
                                  backButton={undefined}
                                  nextButton={undefined}
                                />
                              </TableCell>
                              <TableCell>{getButton(item, index)}</TableCell>
                              <TableCell className="TableCell">
                                {item?.lastUpdatedDate ? (
                                  moment(item?.lastUpdatedDate).format(
                                    "MMMM DD,yyyy"
                                  )
                                ) : (
                                  <></>
                                )}
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </div>
              </Box>
            </>
          ) : (
            <>
              <PersonalQues
                onClick={(data: any) => {
                  console.log(data);
                }}
                disableNext={disableNextBtn}
                disableFinsh={disableFinishBtn}
                Index={count}
                familyData={familyData}
                questionList={quesSaveArray}
                instData={instructionSet}
                fullName={familyData.fullName}
                ViewMode={isView}
              />
            </>
          )}
        </div>

        <ProgramInfoFooter
          label={""}
          leftSectionbuttons={count > -1 ? quseBtn : buttonProps.DataStep}
          rightSectionText={showText ? "" : messages.uhf.NEEDHELP_MSG}
          handleCallback={(data: any) => {
            footerButtonHandler(data.className);
          }}
          showChatIcon={() => (
            <div className="product_footer-chat">
              <Fab
                aria-label="add"
                sx={{ background: "#fb6647", color: "#fff" }}
              >
                <ForumIcon sx={{ color: "#fff" }} />
              </Fab>
            </div>
          )}
        />
      </div>
    </StyleWrapper>
  );
};

export default HealthQuestionnaire;
