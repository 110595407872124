import { useCallback, useContext, useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { StorageHelpers } from "carynhealth-component-library";
import { styled } from "@mui/system";
import routeConfig from "./routeConfig";
import { AuthContext } from "../../context/AuthenticateContext";
import config from "../constants/config";
import { getRoutePaths } from "../constants/routePaths";
import { IdleSessionHandle } from "../../components/IdleSession";
import { WebSideBar } from "../../pages/WebSideBar";
import { getGateWayToken } from "./handlers";

const RouteStyle = styled("section")(({ theme }) => {
  return {
    minHeight: "80vh",
    "&.ROUTE-UHF": {
      background: "rgb(234, 232, 219)",
    },
    "&.ROUTE-NETWELL": {
      background: "#f4f4f4",
    },
    "&.ROUTE-INNOVATION": {
      background: "#dfeacd",
      display: "flex",
      ".routesMainDiv": {
        width: "100%",
      },
    },
    [theme.breakpoints.down(768)]: {
      minHeight: "56vh",
      "&.ROUTE-INNOVATION": {
        minHeight: "69vh",
      },
    },
    ".login-UHF ": {
      ".banner_brand-logo": {
        [theme.breakpoints.up("md")]: {
          marginTop: 10,
        },
      },
      [theme.breakpoints.only("sm")]: {
        paddingTop: 65,
      },
      [theme.breakpoints.down("sm")]: {
        paddingTop: 25,
      },
    },
    ".login-NETWELL": {
      [theme.breakpoints.only("sm")]: {
        paddingTop: 65,
        ".banner_brand-logo": {
          margin: "10px auto !important",
          textAlign: "center",
        },
      },
    },
  };
});

const RoutesComponent = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log(location.pathname, "pathname");
    if (StorageHelpers.getLocalStorage("userMail")) return;
    const routeInfo = routeConfig.filter(
      ({ path }) => path === location.pathname
    );
    const { isAuthenticated } = auth.authenticatedInfo;
    console.log(routeInfo?.[0]?.authRequired);

    routeInfo?.[0]?.authRequired &&
      !isAuthenticated &&
      navigate({
        pathname: "/",
      });
    if (sessionStorage.getItem("USER_ACTIVE_SESSION")) {
      navigate({
        pathname: "/dashboard",
      });
    }
  }, [location]);

  useEffect(() => {
    const { isAuthenticated } = auth.authenticatedInfo;
    if (location.pathname === "/" && isAuthenticated)
      navigate(getRoutePaths.dashboard);
    else if (sessionStorage.getItem("USER_ACTIVE_SESSION")) {
      navigate(getRoutePaths.dashboard);
    }
  }, [auth.authenticatedInfo]);

  const logoutHandler = useCallback((status: string) => {
    if (status === "SignOut") {
      auth.resetAuthenticateInfo();
    }
  }, []);

  return (
    <RouteStyle className={`ROUTE-${config.APP_NAME}`}>
      {auth.authenticatedInfo.isAuthenticated ? (
        <IdleSessionHandle onCallback={logoutHandler} />
      ) : null}
      {config.APP_NAME === config.BRAND_INNOVATION && <WebSideBar />}
      <div className="routesMainDiv">
        <Routes>
          {routeConfig.map((data) => {
            return (
              <Route
                caseSensitive={true}
                key={data.path}
                path={data.path}
                element={<data.component />}
              />
            );
          })}
        </Routes>
      </div>
    </RouteStyle>
  );
};

export default RoutesComponent;
