import { apiService } from "carynhealth-component-library";
import config from "../../../config/constants/config";
import { documentsInfo } from "./data";
import UHFDocuments from "../../../assests/images/uhf/documents.svg";
import NetwellDocuments from "../../../assests/images/netwell/documents.svg";
import InnovativeDocuments from "../../../assests/images/innovative/documents-icon.svg";
import { DetailsType } from "./interface";
import titles from "../../../config/constants/titles";
import messages from "../../../config/constants/messages";

const brand = config.APP_NAME;

export const getWelcomeBookletLink = async (type: string) => {
  try {
    const mail = localStorage.getItem("userMail");
    const request = {
      URL: `${config.URLS.baseAPI}${config.URLS.dashboardPlanInfo}`,
      data: { email: mail, type },
    };
    const response = await apiService.default.post(request);
    const providerLink = response.data[0].fieldValue;
    return providerLink;
  } catch (error: any) {
    return error;
  }
};

export const getDocuments = (docRequest: DetailsType) => {
  const { clientId, ...rest } = docRequest;
  const details: any = documentsInfo[brand as keyof typeof documentsInfo];
  if (brand === config.BRAND_UHF) {
    return details.documents.map((data: any) => {
      if (data.show) {
        return data.show() && data;
      } else if (data.disable) {
        return { ...data, disable: rest[data.disable as keyof typeof rest] };
      }
      return data;
    });
  } else if (brand === config.BRAND_NETWELL) {
    const documentData = details.documents.find(({ id }: any) =>
      id.includes(clientId)
    );
    return documentData?.list?.length ? documentData.list : [];
  }
};

export const getDocumentTileDetails = ({
  clientId,
  disableUHFWelcome,
  disableUHFSharing,
}: DetailsType) => {
  const notifications =
    getDocuments({ clientId, disableUHFWelcome, disableUHFSharing }) || [];
  return {
    title: titles.Documents,
    image:
      brand === config.BRAND_NETWELL
        ? NetwellDocuments
        : brand === config.BRAND_UHF
        ? UHFDocuments
        : InnovativeDocuments,
    isUHF: brand === config.BRAND_UHF,
    notifications,
    errorMessage: notifications.length
      ? ""
      : documentsInfo[brand as keyof typeof documentsInfo]["not-found"],
  };
};

export const getDocumentsDataByID = async (planId: any, memberSSN: any) => {
  let URL: string =
    config.URLS.baseAPI + config.URLS.getDocumentByPlanId + planId;
  if (config.APP_NAME === config.BRAND_INNOVATION)
    URL =
      config.URLS.baseAPI +
      config.URLS.getDocumentByPlanId +
      planId +
      "/" +
      memberSSN;
  try {
    const request = {
      URL: URL,
      brand: config.BRAND_IP_AEC,
    };
    const { data: apiResponse } = await apiService.default.get(request);
    return {
      title: titles.Documents,
      image:
        brand === config.BRAND_NETWELL
          ? NetwellDocuments
          : brand === config.BRAND_UHF
          ? UHFDocuments
          : InnovativeDocuments,
      isUHF: brand === config.BRAND_UHF,
      apiResponse,
      errorMessage: messages.NO_DOCUMENTS,
    };
  } catch (error: any) {
    console.log("error", error);
    if(localStorage.getItem("userMail") === null){
      sessionStorage.clear();
      window.location.reload();
    }
    return Promise.reject({
      errorMessage: messages.NO_DOCUMENTS,
    });
  }
};
